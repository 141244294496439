import _ from 'lodash';
import type { DecodingItemType, HttpHeadersItemType, HttpMethodItemType, HttpParamsType } from './httpSlice.types';

export const tlsKeys = ['caCert', 'clientKey', 'clientCert'] as const;

export const initialHttpMethodItem: HttpMethodItemType = 'POST';

export const initialHttpHeadersItem: HttpHeadersItemType = [
  {
    checked: true,
    key: 'Content-Type',
    value: 'application/x-google-protobuf',
  },
  {
    checked: true,
    key: 'Accept',
    value: '*/*',
  },
];

export const initialHttpSendParams: HttpParamsType = {
  method: 'POST',
  url: 'http://localhost:3000/test',
  headers: _.cloneDeep(initialHttpHeadersItem),
  timeout: 0,
  decodeObj: {},
  loading: false,
  instanceMetadata: {
    name: '0',
  },
};

export const initialDecodingItem: DecodingItemType = {};
