import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SplitState } from './splitSlice.types';

const initialState: SplitState = {
  value: 'v',
};

const splitSlice = createSlice({
  name: 'split',
  initialState,
  reducers: {
    addSplit: (_state, { payload }: PayloadAction<SplitState>) => payload,
    toggleSplit: (state) => {
      state.value = state.value === 'v' ? 'h' : 'v';
    },
  },
});

export const { addSplit, toggleSplit } = splitSlice.actions;

export default splitSlice.reducer;
