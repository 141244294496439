import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-unresolved
import type { WritableDraft } from 'immer/dist/internal';
import _ from 'lodash';
import { objectToMap } from '../../utils/common';
import { importGithubWorkspace } from '../thunks/githubThunk';
import { ExtendedWorkspace } from '../thunks/types';
import { createWorkspace, deleteWorkspace, getWorkspaces, upsertProtos } from '../thunks/workspacesThunk';
import { getValueByContext, setFieldDataValue } from './dataSlice';
import type { CutOutFieldPayloadType, FieldPayloadType, ResolveAnyFieldPayloadType } from './dataSlice.types';
import type { TempDataState } from './tempDataSlice.types';
import { TempDataItemType } from './tempDataSlice.types';
import type { CreateWorkspacesResponse } from './workspacesSlice.types';

export const initialTempDataItem: TempDataItemType = new Map();

const initialState: TempDataState = {};

const hydratedTempDataWithWorkspaces = (state: WritableDraft<TempDataState>, workspaces: ExtendedWorkspace[]) => {
  workspaces.forEach((w) => {
    const { workspace_id, user_data } = w;
    const currUserTempData = objectToMap(user_data.temp_data ?? {});
    // No protos in the workspace
    if (!w.hydrated) {
      state[workspace_id] = currUserTempData;
    } else {
      const {
        mainObjects: { tempDataMap: defaultTempDataMap },
      } = w;

      // User data exists, just make sure we add any new stuff from the generated defaults
      // TODO: I'm not sure this merges properly, maybe just use _.merge?
      defaultTempDataMap.forEach((v, k) => {
        if (!currUserTempData.has(k)) {
          currUserTempData.set(k, v);
        }
      });

      state[workspace_id] = currUserTempData;
    }
  });
};

const tempDataSlice = createSlice({
  name: 'tempData',
  initialState,
  reducers: {
    setTempData: (_state, { payload }: PayloadAction<TempDataState>) => payload,
    setTempDataFieldValue: (state, { payload }: PayloadAction<FieldPayloadType>) => setFieldDataValue(payload, state),
    // resolveWsAnyFieldTemp: (state, { payload }: PayloadAction<ResolveAnyFieldPayloadType>) => {
    //   const { wsId, outerPath, innerPath, defaultData, packed } = payload;
    //   const [innerPackage] = innerPath;
    //   setFieldDataValue({ wsId, value: [[...outerPath, innerPackage], defaultData.get(innerPackage)] }, state);
    //   setFieldDataValue({ wsId, value: [[...outerPath, 'packed'], packed] }, state);
    //   return state;
    // },
    cutOutTempDataFieldValue: (state, { payload }: PayloadAction<CutOutFieldPayloadType>) => {
      const { wsId, value } = payload;
      const newContext = [...value];
      const messageName = newContext.pop();
      const v = getValueByContext(newContext, state[wsId]);
      if (v) v.delete(messageName);
    },
    resolveAnyField: (state, { payload }: PayloadAction<ResolveAnyFieldPayloadType>) => {
      const { wsId, outerPath, innerPath, defaultData, packed } = payload;
      const [innerPackage] = innerPath;
      setFieldDataValue({ wsId, value: [[...outerPath, innerPackage], defaultData.get(innerPackage)] }, state);
      setFieldDataValue({ wsId, value: [[...outerPath, 'packed'], packed] }, state);
    },
    clearTempData: () => initialState,
  },
  extraReducers: (builder) => {
    // upsertProtos ********************************************************************************
    builder.addCase(upsertProtos.fulfilled, (state, { payload }) => {
      const { workspace_id } = payload;
      state[workspace_id] = payload.hydrated ? payload.mainObjects.tempDataMap : new Map();
    });
    // createWorkspace *****************************************************************************
    builder.addCase(createWorkspace.fulfilled, (state, { payload }: PayloadAction<CreateWorkspacesResponse>) => {
      state[payload.workspace_id] = _.cloneDeep(initialTempDataItem);
    });
    // getWorkspaces *******************************************************************************
    builder.addCase(getWorkspaces.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      hydratedTempDataWithWorkspaces(state, workspaces);
    });
    builder.addCase(importGithubWorkspace.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      hydratedTempDataWithWorkspaces(state, workspaces);
    });
    // deleteWorkspace *****************************************************************************
    builder.addCase(deleteWorkspace.fulfilled, (state, { meta }) => {
      delete state[meta.arg];
    });
  },
});

export const {
  setTempData,
  setTempDataFieldValue,
  cutOutTempDataFieldValue,
  resolveAnyField,
  clearTempData,
} = tempDataSlice.actions;

export default tempDataSlice.reducer;
