import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import type { CancelTokenSource } from 'axios';
import type { TlsStorageType } from '../../components/MainContent/MiddleColumn/OperationsTabPanels/OperationsGrpc/UrlGrpcEditor/types';
import * as requestProto from '../../pb-node/request_pb';
import {
  CancelRequest,
  CertWrapper,
  GrpcRequestOptions,
  GrpcRequestWrapper,
  GrpcResponseWrapper,
  OpenChannel,
} from '../../pb-node/request_pb';
import { rawAxios } from '../axiosConfig/axiosConfig';
import { getGrpcInstance } from '../../utils/common';
import type { GrpcMethodType, GrpcPushMethodType } from '../slices/grpcSlice.types';
import type { AppThunk, Store } from '../store';
import type {
  GrpcDataRequestOptionsType,
  GrpcNoDataRequestOptionsType,
  GrpcRequestOptionsExt,
  RejectWithValue,
  Responses,
} from './grpcThunk.types';

const POLLING_DELAY = 100;
const pollingEnabled = true;
let pollingId: NodeJS.Timer | undefined;

const { CancelToken } = rawAxios;
let source: CancelTokenSource;

const getSendMessage = (grpcMethodType: GrpcMethodType, grpcRequestOptions: GrpcRequestOptions): GrpcRequestWrapper => {
  const requestMessage = new requestProto.GrpcRequestWrapper();
  let requestWrapper;
  switch (grpcMethodType) {
    case 'unary': {
      requestWrapper = new requestProto.UnaryRequestWrapper();
      requestMessage.setUnary(requestWrapper);
      break;
    }
    case 'client': {
      requestWrapper = new requestProto.ClientStreamRequestWrapper();
      requestMessage.setClientStream(requestWrapper);
      break;
    }
    case 'server': {
      requestWrapper = new requestProto.ServerStreamRequestWrapper();
      requestMessage.setServerStream(requestWrapper);
      break;
    }
    case 'bidirectional': {
      requestWrapper = new requestProto.BidiStreamRequestWrapper();
      requestMessage.setBidiStream(requestWrapper);
      break;
    }
  }
  requestWrapper.setStart(grpcRequestOptions);
  return requestMessage;
};

const getPushMessage = (grpcMethodType: GrpcPushMethodType, grpcRequestOptions: GrpcRequestOptions) => {
  const requestMessage = new requestProto.GrpcRequestWrapper();
  let requestWrapper;
  switch (grpcMethodType) {
    case 'client': {
      requestWrapper = new requestProto.ClientStreamRequestWrapper();
      requestMessage.setClientStream(requestWrapper);
      break;
    }
    case 'bidirectional': {
      requestWrapper = new requestProto.BidiStreamRequestWrapper();
      requestMessage.setBidiStream(requestWrapper);
      break;
    }
  }
  requestWrapper.setPush(grpcRequestOptions);
  return requestMessage;
};

const getEndMessage = (grpcMethodType: GrpcPushMethodType, grpcRequestOptions: CancelRequest): GrpcRequestWrapper => {
  const requestMessage = new requestProto.GrpcRequestWrapper();
  let requestWrapper;
  switch (grpcMethodType) {
    case 'client': {
      requestWrapper = new requestProto.ClientStreamRequestWrapper();
      requestMessage.setClientStream(requestWrapper);
      break;
    }
    case 'bidirectional': {
      requestWrapper = new requestProto.BidiStreamRequestWrapper();
      requestMessage.setBidiStream(requestWrapper);
      break;
    }
  }
  requestWrapper.setEnd(grpcRequestOptions);
  return requestMessage;
};

const getCancelMessage = (grpcMethodType: GrpcMethodType, grpcRequestOptions: CancelRequest): GrpcRequestWrapper => {
  const requestMessage = new requestProto.GrpcRequestWrapper();
  let requestWrapper;
  switch (grpcMethodType) {
    case 'unary': {
      requestWrapper = new requestProto.UnaryRequestWrapper();
      requestMessage.setUnary(requestWrapper);
      break;
    }
    case 'client': {
      requestWrapper = new requestProto.ClientStreamRequestWrapper();
      requestMessage.setClientStream(requestWrapper);
      break;
    }
    case 'server': {
      requestWrapper = new requestProto.ServerStreamRequestWrapper();
      requestMessage.setServerStream(requestWrapper);
      break;
    }
    case 'bidirectional': {
      requestWrapper = new requestProto.BidiStreamRequestWrapper();
      requestMessage.setBidiStream(requestWrapper);
      break;
    }
  }
  requestWrapper.setCancel(grpcRequestOptions);
  return requestMessage;
};

const getEncodedMessage = (
  args: GrpcDataRequestOptionsType<GrpcMethodType> | GrpcDataRequestOptionsType<GrpcPushMethodType>,
): Uint8Array => {
  const sendType = args.currentRoot.lookupType(args.requestMessage.join('.'));
  const message = sendType.create(args.dataToSend);
  return sendType.encode(message).finish();
};

const getCertificates = (tlsId: string, certificates: TlsStorageType): CertWrapper | undefined => {
  const certWrapper = new CertWrapper();
  const currentCert = certificates.find((p) => p.id === tlsId);
  if (currentCert) {
    certWrapper.setCaCert(currentCert.certs.caCert.content);
    certWrapper.setClientCert(currentCert.certs.clientCert.content);
    certWrapper.setClientKey(currentCert.certs.clientKey.content);
    return certWrapper;
  }
  return undefined;
};

export const makeRequest = async (
  args: GrpcRequestOptionsExt,
): Promise<[Array<Responses>, Array<OpenChannel.AsObject>] | RejectWithValue<string>> => {
  let requestMessage: GrpcRequestWrapper;
  switch (args.operationType) {
    case 'send':
      {
        const grpcSendOptions = new requestProto.GrpcRequestOptions();
        grpcSendOptions.setWorkspaceId(args.wsId);
        grpcSendOptions.setMethodName(args.currentSelectedRpc.rpc);
        grpcSendOptions.setServiceName(args.currentSelectedRpc.service);
        grpcSendOptions.setInstanceName(args.currentSelectedRpc.instance);
        grpcSendOptions.setDeadline(args.deadline as number);
        grpcSendOptions.setPayload(getEncodedMessage(args));
        grpcSendOptions.setUri(args.url);
        // filter checked metadata
        args.metadata.forEach((mItem) => {
          if (mItem.checked && mItem.key.length) {
            grpcSendOptions.getMetadataMap().set(mItem.key, mItem.value);
          }
        });
        requestMessage = getSendMessage(args.grpcMethodType, grpcSendOptions);
      }
      break;
    case 'push':
      {
        const grpcPushOptions = new requestProto.GrpcRequestOptions();
        grpcPushOptions.setWorkspaceId(args.wsId);
        grpcPushOptions.setMethodName(args.currentSelectedRpc.rpc);
        grpcPushOptions.setServiceName(args.currentSelectedRpc.service);
        grpcPushOptions.setInstanceName(args.currentSelectedRpc.instance);
        grpcPushOptions.setDeadline(args.deadline as number);
        grpcPushOptions.setPayload(getEncodedMessage(args));
        grpcPushOptions.setUri(args.url);
        // filter checked metadata
        args.metadata.forEach((mItem) => {
          if (mItem.checked && mItem.key.length) {
            grpcPushOptions.getMetadataMap().set(mItem.key, mItem.value);
          }
        });
        requestMessage = getPushMessage(args.grpcMethodType, grpcPushOptions);
      }
      break;
    case 'end':
      {
        const grpcCancelOptions = new requestProto.CancelRequest();
        grpcCancelOptions.setWorkspaceId(args.wsId);
        grpcCancelOptions.setServiceName(args.currentSelectedRpc.service);
        grpcCancelOptions.setMethodName(args.currentSelectedRpc.rpc);
        grpcCancelOptions.setInstanceName(args.currentSelectedRpc.instance);
        requestMessage = getEndMessage(args.grpcMethodType, grpcCancelOptions);
      }
      break;
    case 'cancel':
      {
        const grpcCancelOptions = new requestProto.CancelRequest();
        grpcCancelOptions.setWorkspaceId(args.wsId);
        grpcCancelOptions.setServiceName(args.currentSelectedRpc.service);
        grpcCancelOptions.setMethodName(args.currentSelectedRpc.rpc);
        grpcCancelOptions.setInstanceName(args.currentSelectedRpc.instance);
        requestMessage = getCancelMessage(args.grpcMethodType, grpcCancelOptions);
      }
      break;
    case 'poll':
      {
        const grpcPollingOptions = new requestProto.PollingRequestWrapper();
        requestMessage = new GrpcRequestWrapper();
        requestMessage.setPoll(grpcPollingOptions);
      }
      break;
  }

  const { workspaces, grpc, auth, agent } = args.getState();
  requestMessage.setSessionToken(auth.sessionToken);

  if ((args.operationType === 'send' || args.operationType === 'push') && args.tlsId.length) {
    const wrappedCerts = getCertificates(args.tlsId, args.certificates);
    if (wrappedCerts) {
      requestMessage.setCerts(wrappedCerts);
    } else {
      return args.rejectWithValue(`Could not find certificates by [id]: ${args.tlsId}`);
    }
  }

  const serializedRequestMessage = requestMessage.serializeBinary();
  try {
    source = CancelToken.source();
    const agentUrl = new URL(process.env.REACT_APP_AGENT_GRPC_URL || 'http://localhost:5000/api/grpc');
    agentUrl.port = agent.port.toString();

    const response = await rawAxios({
      method: 'POST',
      url: agentUrl.href,
      data: serializedRequestMessage,
      responseType: 'arraybuffer',
      timeout: 0,
      cancelToken: source.token,
    });
    const deserializedResponseData = GrpcResponseWrapper.deserializeBinary(response.data);
    const responses: Array<Responses> = [];
    deserializedResponseData.getResponsesList().forEach((item) => {
      switch (item.getResponseCase()) {
        case requestProto.GrpcResponse.ResponseCase.SUCCESS: {
          const payload = item.getSuccess()?.getPayload_asU8() ?? new Uint8Array();
          const { workspaceId, serviceName, methodName, instanceName } = item.toObject();
          const { root } = workspaces.workspaces[workspaceId];
          if (root) {
            const selectedRpc = { service: serviceName, rpc: methodName, instance: instanceName };
            // TODO: shouldn't ever be missing, but do we want to handle that with an explicit error case anyways?
            const respMes = getGrpcInstance(grpc, workspaceId, selectedRpc)?.responseMessage ?? [];
            const respType = root.lookupType(respMes.join('.'));

            responses.push({
              workspaceId,
              serviceName,
              methodName,
              instanceName,
              payload: respType.toObject(respType.decode(payload), {
                defaults: true,
              }),
              type: 'success',
            });
          }
          break;
        }
        case requestProto.GrpcResponse.ResponseCase.ERROR: {
          const { workspaceId, serviceName, methodName, instanceName, error } = item.toObject();
          responses.push({
            workspaceId,
            serviceName,
            methodName,
            instanceName,
            payload: error,
            type: 'error',
          });
          break;
        }
        case requestProto.GrpcResponse.ResponseCase.RESPONSE_NOT_SET:
          // TODO: error
          break;
      }
    });

    const openChannels = deserializedResponseData.toObject().openChannelsList; // second array
    const foundPollingChannel = openChannels.find(({ workspaceId, serviceName, methodName, instanceName }) => {
      const selectedRpc = { service: serviceName, rpc: methodName, instance: instanceName };
      const method = getGrpcInstance(grpc, workspaceId, selectedRpc)?.grpcMethodType;
      return method === 'server' || method === 'bidirectional';
    });
    if (pollingEnabled && foundPollingChannel) {
      // run polling
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      args.dispatch(polling());
    } else if (pollingId) {
      // stop polling
      clearInterval(pollingId);
      pollingId = undefined;
    }
    return [responses, openChannels];
  } catch (err) {
    if (err.response) return args.rejectWithValue(err.response.data.message);
    return args.rejectWithValue(err.message);
  }
};

export const grpcSend = createAsyncThunk<
  [Responses[], OpenChannel.AsObject[]],
  GrpcDataRequestOptionsType<GrpcMethodType>,
  {
    rejectValue: string;
    state: Store;
  }
>('grpcSend', async (args: GrpcDataRequestOptionsType<GrpcMethodType>, { rejectWithValue, getState, dispatch }) => {
  return makeRequest({ ...args, rejectWithValue, getState, dispatch, operationType: 'send' });
});

export const grpcPush = createAsyncThunk<
  [Responses[], OpenChannel.AsObject[]],
  GrpcDataRequestOptionsType<GrpcPushMethodType>,
  {
    rejectValue: string;
    state: Store;
  }
>('grpcPush', async (args: GrpcDataRequestOptionsType<GrpcPushMethodType>, { rejectWithValue, getState, dispatch }) => {
  return makeRequest({ ...args, rejectWithValue, getState, dispatch, operationType: 'push' });
});

export const grpcEnd = createAsyncThunk<
  [Responses[], OpenChannel.AsObject[]],
  GrpcNoDataRequestOptionsType<GrpcPushMethodType>,
  {
    rejectValue: string;
    state: Store;
  }
>('grpcEnd', async (args, { rejectWithValue, getState, dispatch }) => {
  return makeRequest({ ...args, rejectWithValue, getState, dispatch, operationType: 'end' });
});

export const grpcCancel = createAsyncThunk<
  [Responses[], OpenChannel.AsObject[]],
  GrpcNoDataRequestOptionsType<GrpcMethodType>,
  {
    rejectValue: string;
    state: Store;
  }
>('grpcCancel', async (args, { rejectWithValue, getState, dispatch }) => {
  return makeRequest({ ...args, rejectWithValue, getState, dispatch, operationType: 'cancel' });
});

export const makePoll = createAsyncThunk<
  [Responses[], OpenChannel.AsObject[]],
  void,
  {
    rejectValue: string;
    state: Store;
  }
>('makePoll', async (_args, { rejectWithValue, getState, dispatch }) => {
  return makeRequest({ dispatch, rejectWithValue, getState, operationType: 'poll' });
});

export const polling = (): AppThunk => (dispatch: Dispatch<any>) => {
  if (!pollingId) {
    pollingId = setInterval(async () => {
      await dispatch(makePoll());
    }, POLLING_DELAY);
  }
};
