export type MessageInputItemType = {
  grpc: 0 | 1; // 0 - Structured, 1 - Raw (JSON-like)
  proto: 0 | 1;
};

export type MessageInputTypePayload = {
  wsId: string;
  value: 0 | 1;
};

export const initialMessageInputItem: MessageInputItemType = {
  grpc: 0,
  proto: 0,
};

export type MessageInputState = {
  [id: string]: MessageInputItemType;
};
