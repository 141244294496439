import { createAsyncThunk } from '@reduxjs/toolkit';
import { PostResponse } from '../axiosConfig/axios.types';
import axios from '../axiosConfig/axiosConfig';

export interface Credentials {
  username: string;
  password: string;
}

export const signIn = createAsyncThunk(
  'auth/signIn',
  async (credentials: Credentials, { rejectWithValue, dispatch }) => {
    try {
      const loginResponse = await axios.post('/login', credentials);
      const {
        data: { sessionToken },
      } = loginResponse;
      const { data: user } = await axios.post('/get-profile', { sessionToken });
      return { ...loginResponse.data, user };
    } catch (err) {
      if (err.response) return rejectWithValue(err.response.data.message);
      return rejectWithValue(err.message);
    }
  },
);

export const checkAuth = createAsyncThunk('auth/checkAuth', async (_arg, { rejectWithValue }) => {
  try {
    const {
      data: { sessionToken },
    } = await axios.get('/check-auth');
    const { data: user } = await axios.post('/get-profile', { sessionToken });

    return { sessionToken, user };
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

export const logout = createAsyncThunk<
  PostResponse<'/logout'>,
  void,
  {
    rejectValue: string;
  }
>('auth/logout', async (_arg, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post('/logout', {});
    if (response.status === 200) {
      // dispatch(clearAuth());
      // dispatch(push('/'));
      window.location.href = `${process.env.REACT_APP_CLIENT_URL}/login`;
    }
    return response.data;
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});
