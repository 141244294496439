import { Decimal } from 'decimal.js';
import {
  numberKeyProps,
  numberValueProps,
} from '../components/MainContent/MiddleColumn/FieldValueEditor/FieldValueEditorTypes';

Decimal.set({ precision: 38 });

const intRegExp = new RegExp('^(-?[1-9]+\\d*)$|^0$');
const floatRegExp = new RegExp('^-?([\\d+]{1,10})\\.?([\\d+]{1,10})?$');
const doubleRegExp = new RegExp('^-?([\\d+]{1,19})\\.?([\\d+]{1,19})?$');

const nonFloatFieldType = (fieldType: string): boolean => {
  return fieldType.includes('int') || fieldType.includes('fixed');
};

const validIntString = (value: string): boolean => {
  return intRegExp.test(value);
};

export const safeNumberRepresentation = (value: string, fieldType: string): string | number => {
  if (!nonFloatFieldType(fieldType)) {
    return value;
  }

  if (value.length === 0) {
    return 0;
  }

  if (!validIntString(value)) {
    return value;
  }

  const bigValue = BigInt(value);

  if (bigValue > 0) {
    return bigValue <= Number.MAX_SAFE_INTEGER ? Number(value) : value;
  }

  return bigValue >= Number.MIN_SAFE_INTEGER ? Number(value) : value;
};

export default function validateNum(value: string, fieldType: string): boolean {
  // for Int
  if (fieldType.includes('int') || fieldType.includes('fixed')) {
    // Check not '' and not any string
    if (value.length === 0 || !intRegExp.test(value)) return false;
    // For all 64 bit numbers
    if (fieldType.endsWith('64')) {
      const num = BigInt(value);
      if (typeof BigInt(num) === 'bigint') {
        return (
          num >= BigInt(<string>numberKeyProps[fieldType].min) && num <= BigInt(<string>numberKeyProps[fieldType].max)
        );
      }
    }
    // For all 32 bit numbers
    const num = Number.parseInt(value, 10);
    return (
      num >= Number.parseInt(<string>numberKeyProps[fieldType].min, 10) &&
      num <= Number.parseInt(<string>numberKeyProps[fieldType].max, 10)
    );
  }
  // for Float & Double
  if (fieldType === 'float') {
    if (value.length === 0 || !floatRegExp.test(value)) return false;
    const valueDec = new Decimal(value);
    return (
      valueDec.greaterThanOrEqualTo(new Decimal(<string>numberValueProps[fieldType].props.min)) &&
      valueDec.lessThanOrEqualTo(new Decimal(<string>numberValueProps[fieldType].props.max))
    );
  }
  if (value.length === 0 || !doubleRegExp.test(value)) return false;
  const valueDec = new Decimal(value);
  return (
    valueDec.greaterThanOrEqualTo(new Decimal(<string>numberValueProps[fieldType].props.min)) &&
    valueDec.lessThanOrEqualTo(new Decimal(<string>numberValueProps[fieldType].props.max))
  );
}
