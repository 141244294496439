import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-unresolved
import type { WritableDraft } from 'immer/dist/internal';
import _ from 'lodash';
import { importGithubWorkspace } from '../thunks/githubThunk';
import type { ExtendedWorkspace } from '../thunks/types';
import { createWorkspace, deleteWorkspace, getWorkspaces, upsertProtos } from '../thunks/workspacesThunk';
import type {
  DeleteAndShiftKeysInSelectedMessagesItemPayload,
  DeleteKeysSelectedInMessagesItemPayload,
  DeleteSelectedMessageItemPayload,
  SelectedMessagesState,
  SelectedMessagesItemType,
  ReplaceKeysInSelectedMessagesItemPayload,
  SetSelectedMessageItemPayload,
} from './selectedMessagesSlice.types';
import type { CreateWorkspacesResponse } from './workspacesSlice.types';

export const initialMessageItem: SelectedMessagesItemType = {};

const initialState: SelectedMessagesState = {};

const hydratedSelectedMessagesWithWorkspaces = (
  state: WritableDraft<SelectedMessagesState>,
  workspaces: ExtendedWorkspace[],
) => {
  workspaces.forEach((w) => {
    const { workspace_id, user_data } = w;
    if (w.hydrated) {
      if (user_data?.selected_messages) {
        state[workspace_id] = user_data.selected_messages;
      } else {
        state[workspace_id] = w.mainObjects.messagesObj;
      }
    } else {
      state[workspace_id] = {};
    }
  });
};

const selectedMessagesSlice = createSlice({
  name: 'selectedMessages',
  initialState,
  reducers: {
    setMessages: (state, { payload }: PayloadAction<SelectedMessagesState>) => payload,
    setMessageItem: (state, { payload }: PayloadAction<SetSelectedMessageItemPayload>) => {
      const { wsId, value } = payload;
      state[wsId] = { ...state[wsId], ...value };
    },
    deleteMessageItem: (state, { payload }: PayloadAction<DeleteSelectedMessageItemPayload>) => {
      const { wsId, value } = payload;
      if (state[wsId][value] !== undefined) {
        delete state[wsId][value];
      }
    },
    replaceKeysInMessageItem: (state, { payload }: PayloadAction<ReplaceKeysInSelectedMessagesItemPayload>) => {
      const { wsId, pathString, currentKey, key } = payload;
      const item = state[wsId];
      Object.entries(item).forEach(([k, v]) => {
        if (k.startsWith(key)) {
          const remainder = k.substr(key.length);
          const newKey = `${pathString}:${currentKey}${remainder.length ? `${remainder}` : ''}`;
          item[newKey] = v;
          delete item[k];
        }
      });
    },
    deleteKeysInMessageItem: (state, { payload }: PayloadAction<DeleteKeysSelectedInMessagesItemPayload>) => {
      const { wsId, key } = payload;
      const item = state[wsId];
      Object.keys(item).forEach((k) => {
        if (k.startsWith(key)) {
          delete item[k];
        }
      });
    },
    deleteAndShiftKeysInMessageItem: (
      state,
      { payload }: PayloadAction<DeleteAndShiftKeysInSelectedMessagesItemPayload>,
    ) => {
      const { wsId, pathString, index, lastIndex } = payload;
      const item = state[wsId];
      const key4delete = `${pathString}:${index}:`;
      const firstPart = `${pathString}:`;
      Object.entries(item).forEach(([k, v]) => {
        if (k.startsWith(firstPart)) {
          if (k.startsWith(key4delete)) {
            delete item[k];
          } else {
            const tmpStr = k.slice(pathString.length + 1);
            const separatorInd = tmpStr.indexOf(':');
            const tail = tmpStr.slice(separatorInd);
            const i = parseInt(tmpStr.slice(0, separatorInd), 10);
            if (i > index || i < lastIndex) {
              const newKey = `${firstPart}${i - 1}${tail}`;
              item[newKey] = v;
              delete item[k];
            }
          }
        }
      });
    },
    clearMessages: () => initialState,
  },
  extraReducers: (builder) => {
    // upsertProtos ********************************************************************************
    builder.addCase(upsertProtos.fulfilled, (state, { payload }) => {
      const { workspace_id } = payload;
      state[workspace_id] = payload.hydrated ? payload.mainObjects.messagesObj : {};
    });
    // createWorkspace *****************************************************************************
    builder.addCase(createWorkspace.fulfilled, (state, { payload }: PayloadAction<CreateWorkspacesResponse>) => {
      state[payload.workspace_id] = _.cloneDeep(initialMessageItem);
    });
    // getWorkspaces *******************************************************************************
    builder.addCase(getWorkspaces.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      hydratedSelectedMessagesWithWorkspaces(state, workspaces);
    });
    builder.addCase(importGithubWorkspace.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      hydratedSelectedMessagesWithWorkspaces(state, workspaces);
    });
    // deleteWorkspace *****************************************************************************
    builder.addCase(deleteWorkspace.fulfilled, (state, { meta }) => {
      delete state[meta.arg];
    });
  },
});

export const {
  setMessages,
  setMessageItem,
  deleteMessageItem,
  replaceKeysInMessageItem,
  deleteKeysInMessageItem,
  deleteAndShiftKeysInMessageItem,
  clearMessages,
} = selectedMessagesSlice.actions;

export default selectedMessagesSlice.reducer;
