import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as pb from 'protobufjs';
import type { GetWorkspacesResponse, IProtoItem, IWorkspacesState } from './workspacesSlice.types';
import { importGithubWorkspace } from '../thunks/githubThunk';
import { addProtos, createWorkspace, deleteWorkspace, getWorkspaces, upsertProtos } from '../thunks/workspacesThunk';

const initialItem: IProtoItem = {
  workspaceName: '',
  root: undefined,
  // packageDefinition: undefined,
  // grpcObject: undefined,
};

const initialState: IWorkspacesState = {
  loading: false,
  errorMessage: '',
  showAlert: false,
  workspaces: {},
};

const workspacesSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    // addProtoFile: (state, { payload }: PayloadAction<Array<string>>) => {
    //   state.files = payload;
    // },
    // removeProtoFile: (state, { payload }: PayloadAction<Set<string>>) => {
    //   state.files = state.files.filter((file) => !payload.has(file));
    // },
    // setRoot: (state, { payload }: PayloadAction<pb.Root>) => {
    //   state.root = payload;
    // },
    // setPackageDefinition: (state, { payload }: PayloadAction<PackageDefinition>) => {
    //   state.packageDefinition = payload;
    // },
    // setGrpcObject: (state, { payload }: PayloadAction<GrpcObject>) => {
    //   state.grpcObject = payload;
    // },
    clearWsProtoFile: () => initialState,
  },
  extraReducers: (builder) => {
    // builder.addCase(parseProtos.pending, (state, { payload }) => {
    //   // state.loading = true;
    //   // state.errorMessage = '';
    //   // state.showAlert = false;
    //   // state.isValid = false;
    // });
    // builder.addCase(parseProtos.fulfilled, (state, { payload }) => {
    //   console.log('############', payload);
    //   // state.loading = false;
    //   // state.errorMessage = '';
    //   // try {
    //   //   const root = new pb.Root();
    //   //   root.addJSON(payload.nested);
    //   //   root.resolveAll();
    //   //   state.root = root;
    //   //   state.isValid = true;
    //   // } catch (e) {
    //   //   throw Error(e);
    //   // }
    // });
    // builder.addCase(parseProtos.rejected, (state, { payload }) => {
    //   // state.loading = false;
    //   // state.showAlert = true;
    //   // state.errorMessage = payload as string;
    //   // state.isValid = false;
    // });
    // addProtos ***********************************************************************************
    builder.addCase(addProtos.pending, (state) => {
      state.loading = true;
      state.errorMessage = '';
      state.showAlert = false;
    });
    builder.addCase(addProtos.fulfilled, (state, { payload, meta }) => {
      const { workspace_id } = payload;
      state.loading = false;
      state.workspaces[workspace_id].root = payload.hydrated ? payload.root : new pb.Root();
    });
    builder.addCase(addProtos.rejected, (state, { payload }) => {
      state.loading = false;
      state.showAlert = true;
      state.errorMessage = payload as string;
    });
    // upsertProtos ********************************************************************************
    builder.addCase(upsertProtos.pending, (state) => {
      state.loading = true;
      state.errorMessage = '';
      state.showAlert = false;
    });
    builder.addCase(upsertProtos.fulfilled, (state, { payload }) => {
      const { workspace_id } = payload;
      state.loading = false;
      state.workspaces[workspace_id].root = payload.hydrated ? payload.root : new pb.Root();
    });
    builder.addCase(upsertProtos.rejected, (state, { payload }) => {
      state.loading = false;
      state.showAlert = true;
      state.errorMessage = payload as string;
    });
    // createWorkspace *****************************************************************************
    builder.addCase(createWorkspace.pending, (state) => {
      state.loading = true;
      state.errorMessage = '';
      state.showAlert = false;
    });
    builder.addCase(createWorkspace.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.workspaces[payload.workspace_id] = { ...initialItem };
      state.workspaces[payload.workspace_id].workspaceName = payload.workspace_name;
    });
    builder.addCase(createWorkspace.rejected, (state, { payload }) => {
      state.loading = false;
      state.showAlert = true;
      state.errorMessage = payload as string;
    });
    // getWorkspace ********************************************************************************
    builder.addCase(getWorkspaces.pending, (state) => {
      state.loading = true;
      state.errorMessage = '';
      state.showAlert = false;
      state.workspaces = {};
    });
    builder.addCase(getWorkspaces.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.errorMessage = '';
      state.showAlert = false;
      const { workspaces } = payload;
      if (workspaces.length) {
        workspaces.forEach((w) => {
          state.workspaces[w.workspace_id] = { ...initialItem };
          state.workspaces[w.workspace_id].workspaceName = w.workspace_name;
          if (w.hydrated) {
            state.workspaces[w.workspace_id].root = w.root;
          }
        });
      }
    });
    builder.addCase(getWorkspaces.rejected, (state, { payload }) => {
      state.loading = false;
      state.showAlert = true;
      state.errorMessage = payload as string;
    });
    // deleteWorkspace *****************************************************************************
    builder.addCase(deleteWorkspace.pending, (state) => {
      state.loading = true;
      state.errorMessage = '';
      state.showAlert = false;
    });
    builder.addCase(deleteWorkspace.fulfilled, (state, { meta }) => {
      state.loading = false;
      state.errorMessage = '';
      state.showAlert = false;
      delete state.workspaces[meta.arg];
    });
    builder.addCase(deleteWorkspace.rejected, (state, { payload }) => {
      state.loading = false;
      state.showAlert = true;
      state.errorMessage = payload as string;
    });

    builder.addCase(importGithubWorkspace.pending, (state) => {
      state.loading = true;
      state.errorMessage = '';
      state.showAlert = false;
      // state.workspaces = {};
    });
    builder.addCase(importGithubWorkspace.fulfilled, (state, { payload }: PayloadAction<GetWorkspacesResponse>) => {
      state.loading = false;
      state.errorMessage = '';
      state.showAlert = false;
      const { workspaces }: GetWorkspacesResponse = payload;
      if (workspaces.length) {
        workspaces.forEach((w) => {
          state.workspaces[w.workspace_id] = { ...initialItem };
          try {
            state.workspaces[w.workspace_id].workspaceName = w.workspace_name;
            const root = new pb.Root();
            if (w.protos && w.protos.nested) {
              root.addJSON(w.protos.nested);
              root.resolveAll();
              state.workspaces[w.workspace_id].root = root;
            }
          } catch (e) {
            throw Error(e);
          }
        });
      }
    });

    // TODO - add new reducer(?) to hold state of autosave actions
    // builder.addCase(updateWorkspaceUserData.fulfilled, (state, { payload }) => {

    // })
  },
});

export const { clearWsProtoFile } = workspacesSlice.actions;

export default workspacesSlice.reducer;
