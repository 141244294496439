import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { createWorkspace, deleteWorkspace, getWorkspaces, upsertProtos } from '../thunks/workspacesThunk';
import type { CreateWorkspacesResponse } from './workspacesSlice.types';
import type { PackagesItemType, PackagesState } from './packagesSlice.types';
import { importGithubWorkspace } from '../thunks/githubThunk';

export const initialPackagesItem: PackagesItemType = new Map();

const initialState: PackagesState = {};

const packagesSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    setPackages: (_state, { payload }: PayloadAction<PackagesState>) => payload,
    clearPackages: () => initialState,
  },
  extraReducers: (builder) => {
    // upsertProtos ********************************************************************************
    builder.addCase(upsertProtos.fulfilled, (state, { payload }) => {
      const { workspace_id } = payload;
      state[workspace_id] = payload.hydrated ? payload.mainObjects.packagesMap : new Map();
    });
    // createWorkspace *****************************************************************************
    builder.addCase(createWorkspace.fulfilled, (state, { payload }: PayloadAction<CreateWorkspacesResponse>) => {
      state[payload.workspace_id] = _.cloneDeep(initialPackagesItem);
    });
    // getWorkspaces *******************************************************************************
    builder.addCase(getWorkspaces.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      workspaces.forEach((w) => {
        const { workspace_id } = w;
        state[workspace_id] = w.hydrated ? w.mainObjects.packagesMap : new Map();
      });
    });
    // importGithubWorkspace ***********************************************************************
    builder.addCase(importGithubWorkspace.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      workspaces.forEach((w) => {
        const { workspace_id } = w;
        state[workspace_id] = w.hydrated ? w.mainObjects.packagesMap : new Map();
      });
    });
    // deleteWorkspace *****************************************************************************
    builder.addCase(deleteWorkspace.fulfilled, (state, { meta }) => {
      delete state[meta.arg];
    });
  },
});

export const { setPackages, clearPackages } = packagesSlice.actions;

export default packagesSlice.reducer;
