import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { initialMessageInputItem, MessageInputState, MessageInputTypePayload } from './messageInputSlice.types';
import type { CreateWorkspacesResponse } from './workspacesSlice.types';
import { createWorkspace, deleteWorkspace, getWorkspaces, upsertProtos } from '../thunks/workspacesThunk';
import { importGithubWorkspace } from '../thunks/githubThunk';

const initialState: MessageInputState = {};

const messageInputSlice = createSlice({
  name: 'messageInput',
  initialState,
  reducers: {
    setMessageInput: (_state, { payload }: PayloadAction<MessageInputState>) => payload,
    setGrpcMessageInput: (state, { payload }: PayloadAction<MessageInputTypePayload>) => {
      const { wsId, value } = payload;
      state[wsId].grpc = value;
    },
    setProtoMessageInput: (state, { payload }: PayloadAction<MessageInputTypePayload>) => {
      const { wsId, value } = payload;
      state[wsId].proto = value;
    },
    clearMessageInput: () => initialState,
  },
  extraReducers: (builder) => {
    // upsertProtos ********************************************************************************
    builder.addCase(upsertProtos.fulfilled, (state, { payload }) => {
      const { workspace_id } = payload;
      state[workspace_id] = _.cloneDeep(initialMessageInputItem);
    });
    // createWorkspace *****************************************************************************
    builder.addCase(createWorkspace.fulfilled, (state, { payload }: PayloadAction<CreateWorkspacesResponse>) => {
      state[payload.workspace_id] = _.cloneDeep(initialMessageInputItem);
    });
    // getWorkspaces *******************************************************************************
    builder.addCase(getWorkspaces.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      workspaces.forEach((w) => {
        // There can be existing state here from redux-persist, so default to that
        state[w.workspace_id] = state[w.workspace_id] ?? _.cloneDeep(initialMessageInputItem);
      });
    });

    builder.addCase(importGithubWorkspace.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      workspaces.forEach((w) => {
        // Same as with getWorkspaces, except we only care about the new one
        state[w.workspace_id] = state[w.workspace_id] ?? _.cloneDeep(initialMessageInputItem);
      });
    });
    // deleteWorkspace *****************************************************************************
    builder.addCase(deleteWorkspace.fulfilled, (state, { meta }) => {
      delete state[meta.arg];
    });
  },
});

export const {
  setMessageInput,
  setGrpcMessageInput,
  setProtoMessageInput,
  clearMessageInput,
} = messageInputSlice.actions;

export default messageInputSlice.reducer;
