export interface InputError {
  [key: number]: boolean;
}

export interface NumberKeyProps {
  [key: string]: {
    min: number | string;
    max: number | string;
    title: string;
  };
}

export interface NumberValueProps {
  [key: string]: {
    props: {
      min?: number | string;
      max?: number | string;
      step?: number;
      title: string;
    };
    pattern: RegExp;
  };
}

export const numberKeyProps: NumberKeyProps = {
  int32: {
    min: '-2147483648',
    max: '2147483647',
    title: 'Value must be between -2147483648 and 2147483647',
  },
  int64: {
    min: '-9223372036854775808',
    max: '9223372036854775807',
    title: 'Value must be between -9223372036854775808 and 9223372036854775807',
  },
  uint32: {
    min: '0',
    max: '4294967295',
    title: 'Value must be between 0 and 4294967295',
  },
  uint64: {
    min: '0',
    max: '18446744073709551615',
    title: 'Value must be between 0 and 18446744073709551615',
  },
  sint32: {
    min: '-2147483648',
    max: '2147483647',
    title: 'Value must be between -2147483648 and 2147483647',
  },
  sint64: {
    min: '-9223372036854775808',
    max: '9223372036854775807',
    title: 'Value must be between -9223372036854775808 and 9223372036854775807',
  },
  fixed32: {
    min: '0',
    max: '4294967295',
    title: 'Value must be between 0 and 4294967295',
  },
  fixed64: {
    min: '0',
    max: '18446744073709551615',
    title: 'Value must be between 0 and 18446744073709551615',
  },
  sfixed32: {
    min: '-2147483648',
    max: '2147483647',
    title: 'Value must be between -2147483648 and 2147483647',
  },
  sfixed64: {
    min: '-9223372036854775808',
    max: '9223372036854775807',
    title: 'Value must be between -9223372036854775808 and 9223372036854775807',
  },
};

export const numberValueProps: NumberValueProps = {
  int32: {
    props: {
      min: -2147483648,
      max: 2147483647,
      title: 'Value must be between -2147483648 and 2147483647',
    },
    pattern: /^-?\d+$/,
  },
  int64: {
    props: {
      min: -9223372036854775808,
      max: 9223372036854775807,
      title: 'Value must be between -9223372036854775808 and 9223372036854775807',
    },
    pattern: /^-?\d+$/,
  },
  uint32: {
    props: {
      min: 0,
      max: 4294967295,
      title: 'Value must be between 0 and 4294967295',
    },
    pattern: /^\d+$/,
  },
  uint64: {
    props: {
      min: 0,
      max: 18446744073709551615,
      title: 'Value must be between 0 and 18446744073709551615',
    },
    pattern: /^\d+$/,
  },
  sint32: {
    props: {
      min: -2147483648,
      max: 2147483647,
      title: 'Value must be between -2147483648 and 2147483647',
    },
    pattern: /^-?\d+$/,
  },
  sint64: {
    props: {
      min: -9223372036854775808,
      max: 9223372036854775807,
      title: 'Value must be between -9223372036854775808 and 9223372036854775807',
    },
    pattern: /^-?\d+$/,
  },
  fixed32: {
    props: {
      min: 0,
      max: 4294967295,
      title: 'Value must be between 0 and 4294967295',
    },
    pattern: /^\d+$/,
  },
  fixed64: {
    props: {
      min: 0,
      max: 184467440737095516151,
      title: 'Value must be between 0 and 18446744073709551615',
    },
    pattern: /^\d+$/,
  },
  sfixed32: {
    props: {
      min: -2147483648,
      max: 2147483647,
      title: 'Value must be between -2147483648 and 2147483647',
    },
    pattern: /^-?\d+$/,
  },
  sfixed64: {
    props: {
      min: -9223372036854775808,
      max: 9223372036854775807,
      title: 'Value must be between -9223372036854775808 and 9223372036854775807',
    },
    pattern: /^-?\d+$/,
  },
  float: {
    props: {
      min: '-2147483648.2147483647',
      max: '2147483647.2147483647',
      step: 0.0000001,
      title: 'Value must be between -2147483648.2147483647 and 2147483647.2147483647',
    },
    pattern: /^-?\d+\.?([\d+]{1,7})?$/,
  },
  double: {
    props: {
      min: '-9223372036854775808.9223372036854775807',
      max: '9223372036854775807.9223372036854775807',
      step: 0.000000000000001,
      title:
        'Value must be between -9223372036854775808.9223372036854775807 and 9223372036854775807.9223372036854775807',
    },
    pattern: /^-?\d+\.?([\d+]{1,15})?$/,
  },
};

export type MapKeyType =
  | 'int32'
  | 'uint32'
  | 'sint32'
  | 'fixed32'
  | 'sfixed32'
  | 'int64'
  | 'uint64'
  | 'sint64'
  | 'fixed64'
  | 'sfixed64'
  | 'bool'
  | 'string';
