import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-unresolved
import type { WritableDraft } from 'immer/dist/internal';
import _ from 'lodash';
import { objectToMap } from '../../utils/common';
import { importGithubWorkspace } from '../thunks/githubThunk';
import type { ExtendedWorkspace } from '../thunks/types';
import { createWorkspace, deleteWorkspace, getWorkspaces, upsertProtos } from '../thunks/workspacesThunk';
import type {
  ContextValueTypes,
  CutOutFieldPayloadType,
  DataItemType,
  DataState,
  FieldPayloadType,
  ResolveAnyFieldPayloadType,
} from './dataSlice.types';
import type { CreateWorkspacesResponse } from './workspacesSlice.types';

export const initialDataItem: DataItemType = new Map();

const initialState: DataState = {};

const hydratedDataWithWorkspaces = (state: WritableDraft<DataState>, workspaces: ExtendedWorkspace[]) => {
  workspaces.forEach((w) => {
    const { workspace_id, user_data } = w;
    const currUserData = objectToMap(user_data.data ?? {});
    // No protos in the workspace
    if (!w.hydrated) {
      state[workspace_id] = currUserData;
    } else {
      const {
        mainObjects: { dataMap: defaultDataMap },
      } = w;
      // User data exists, just make sure we add any new stuff from the generated defaults
      // TODO: I'm not sure this merges properly, maybe just use _.merge?
      defaultDataMap.forEach((v, k) => {
        if (!currUserData.has(k)) {
          currUserData.set(k, v);
        }
      });

      state[workspace_id] = currUserData;
    }
  });
};

export const getValueByContext = (c: ContextValueTypes, d: DataItemType): any => {
  return c.reduce((a: any, v) => {
    if (typeof v === 'number') {
      return a[v];
    }
    return a?.get(v);
  }, d);
};

export const setFieldDataValue = (payload: FieldPayloadType, state: DataState): void => {
  const { wsId, value } = payload;
  const [context, val] = value;
  const newContext = [...context];
  const messageName = newContext.pop();
  let parentMessage = getValueByContext(newContext, state[wsId]);

  // This can be missing when the user creates a new message instance
  // In that case, the new "message" won't have been created as a child of the rpc yet
  if (!parentMessage) {
    const defaultValue = typeof messageName === 'number' ? [] : new Map();
    setFieldDataValue({ wsId, value: [newContext, defaultValue] }, state);
    parentMessage = defaultValue;
  }

  if (typeof messageName === 'number') {
    if (val === undefined) {
      parentMessage.splice(messageName, 1);
    } else {
      parentMessage.splice(messageName, 1, val);
    }
  } else {
    if (val === undefined) {
      parentMessage.delete(messageName);
    } else {
      parentMessage.set(messageName, val);
    }
  }
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setData: (_state, { payload }: PayloadAction<DataState>) => payload,
    setFieldValue: (state, { payload }: PayloadAction<FieldPayloadType>) => {
      setFieldDataValue(payload, state);
    },
    cutOutFieldValue: (state, { payload }: PayloadAction<CutOutFieldPayloadType>) => {
      const { wsId, value } = payload;
      const newContext = [...value];
      const messageName = newContext.pop();
      const v = getValueByContext(newContext, state[wsId]);
      if (v) v.delete(messageName);
    },
    resolveAnyField: (state, { payload }: PayloadAction<ResolveAnyFieldPayloadType>) => {
      const { wsId, outerPath, innerPath, defaultData, packed } = payload;
      const [innerPackage] = innerPath;
      setFieldDataValue({ wsId, value: [[...outerPath, innerPackage], defaultData.get(innerPackage)] }, state);
      setFieldDataValue({ wsId, value: [[...outerPath, 'packed'], packed] }, state);
    },
    clearData: () => initialState,
  },
  extraReducers: (builder) => {
    // upsertProtos ********************************************************************************
    builder.addCase(upsertProtos.fulfilled, (state, { payload }) => {
      const { workspace_id } = payload;

      state[workspace_id] = payload.hydrated ? payload.mainObjects.dataMap : new Map();
    });
    // createWorkspace *****************************************************************************
    builder.addCase(createWorkspace.fulfilled, (state, { payload }: PayloadAction<CreateWorkspacesResponse>) => {
      state[payload.workspace_id] = _.cloneDeep(initialDataItem);
    });
    // deleteWorkspace *****************************************************************************
    builder.addCase(deleteWorkspace.fulfilled, (state, { meta }) => {
      delete state[meta.arg];
    });
    // getWorkspaces *******************************************************************************
    builder.addCase(getWorkspaces.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      hydratedDataWithWorkspaces(state, workspaces);
    });
    builder.addCase(importGithubWorkspace.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      hydratedDataWithWorkspaces(state, workspaces);
    });
  },
});

export const { setData, setFieldValue, cutOutFieldValue, resolveAnyField, clearData } = dataSlice.actions;

export default dataSlice.reducer;
