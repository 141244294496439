import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-unresolved
import type { WritableDraft } from 'immer/dist/internal';
import _ from 'lodash';
import { importGithubWorkspace } from '../thunks/githubThunk';
import type { ExtendedWorkspace } from '../thunks/types';
import { createWorkspace, deleteWorkspace, getWorkspaces, upsertProtos } from '../thunks/workspacesThunk';
import type {
  SelectedHttpMessageItemPayload,
  SelectedHttpMessageItemType,
  SelectedHttpMessageState,
} from './selectedHttpMessageSlice.types';
import type { CreateWorkspacesResponse } from './workspacesSlice.types';

export const initialSelectedHttpMessageItem: SelectedHttpMessageItemType = ['', '', ''];

const initialState: SelectedHttpMessageState = {};

const hydratedSelectedHttpMessageWithWorkspaces = (
  state: WritableDraft<SelectedHttpMessageState>,
  workspaces: ExtendedWorkspace[],
) => {
  workspaces.forEach((w) => {
    const { workspace_id } = w;
    if (w.hydrated) {
      Object.keys(w.messagesAndRpc.http).forEach((packageName) => {
        const messages = w.messagesAndRpc.http[packageName];
        const firstMessageName = Object.keys(messages)[0];
        const firstInstanceName = messages[firstMessageName]?.instances[0]?.instanceMetadata.name;
        if (typeof firstInstanceName === 'string') {
          state[workspace_id] = [packageName, firstMessageName, firstInstanceName];
        }
      });
    } else {
      state[workspace_id] = _.cloneDeep(initialSelectedHttpMessageItem);
    }
  });
};

const selectedHttpMessageSlice = createSlice({
  name: 'selectedHttpMessage',
  initialState,
  reducers: {
    setSelectedHttpMessage: (_state, { payload }: PayloadAction<SelectedHttpMessageState>) => payload,
    setSelectedHttpMessageItem: (state, { payload }: PayloadAction<SelectedHttpMessageItemPayload>) => {
      state[payload.wsId] = payload.value;
    },
    clearSelectedHttpMessage: () => initialState,
  },
  extraReducers: (builder) => {
    // upsertProtos ********************************************************************************
    builder.addCase(upsertProtos.fulfilled, (state, { payload }) => {
      const { workspace_id } = payload;
      if (payload.hydrated) {
        Object.keys(payload.messagesAndRpc.http).forEach((packageName) => {
          const messages = payload.messagesAndRpc.http[packageName];
          const firstMessageName = Object.keys(messages)[0];
          const firstInstanceName = messages[firstMessageName]?.instances[0]?.instanceMetadata.name;
          if (typeof firstInstanceName === 'string') {
            state[workspace_id] = [packageName, firstMessageName, firstInstanceName];
          } else {
            state[workspace_id] = _.cloneDeep(initialSelectedHttpMessageItem);
          }
        });
      } else {
        state[workspace_id] = _.cloneDeep(initialSelectedHttpMessageItem);
      }
    });
    // createWorkspace *****************************************************************************
    builder.addCase(createWorkspace.fulfilled, (state, { payload }: PayloadAction<CreateWorkspacesResponse>) => {
      state[payload.workspace_id] = _.cloneDeep(initialSelectedHttpMessageItem);
    });
    // getWorkspaces *******************************************************************************
    builder.addCase(getWorkspaces.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      hydratedSelectedHttpMessageWithWorkspaces(state, workspaces);
    });
    builder.addCase(importGithubWorkspace.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      hydratedSelectedHttpMessageWithWorkspaces(state, workspaces);
    });
    // deleteWorkspace *****************************************************************************
    builder.addCase(deleteWorkspace.fulfilled, (state, { meta }) => {
      delete state[meta.arg];
    });
  },
});

export const {
  setSelectedHttpMessage,
  setSelectedHttpMessageItem,
  clearSelectedHttpMessage,
} = selectedHttpMessageSlice.actions;

export default selectedHttpMessageSlice.reducer;
