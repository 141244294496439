import * as base64 from 'byte-base64';
import _ from 'lodash';
import * as pb from 'protobufjs';
import { useEffect } from 'react';
import { Dispatch } from 'redux';
import type { ContextValueTypes } from '../../../store/slices/dataSlice.types';
import { getDefaultGrpcMessageInstanceParams } from '../../../store/slices/grpcSlice.init';
import type { GrpcItemType, GrpcMethodType } from '../../../store/slices/grpcSlice.types';
import { initialHttpSendParams } from '../../../store/slices/httpSlice.init';
import type { DecodingItemType, HttpItemType } from '../../../store/slices/httpSlice.types';
import type { FieldOrOneOf, PackagesItemType } from '../../../store/slices/packagesSlice.types';
import type { SelectedMessagesItemType } from '../../../store/slices/selectedMessagesSlice.types';
import type { SelectedOneOfItemType } from '../../../store/slices/selectedOneOfSlice.types';
import validateNum from '../../../utils/validateNumbers';
import { FCContainer, RootField, UF } from '../MiddleColumn/FieldValueEditor/fieldValueEditor.types';
import type {
  DefaultFieldValueTypes,
  ImportContentsType,
  ImportsType,
  MainObjectsType,
  MessagesAndRpcObject,
  RecursiveMap,
  TypeField,
} from './util.types';
import { isContainerField } from '../MiddleColumn/FieldValueEditor/ContainerHelpers';
import { isOneOfField, isMapField, isRepeatedField, isMessageField, isEnumField } from './FieldTypeHelpers';

export const getGrpcMethodType = (method: pb.Method): GrpcMethodType => {
  const { requestStream, responseStream } = method;
  if (requestStream) {
    if (responseStream) {
      return 'bidirectional';
    }
    return 'client';
  }
  if (responseStream) {
    return 'server';
  }
  return 'unary';
};

export const getPackageName = (fullName: string, parentName: string): string =>
  fullName.slice(1, fullName.indexOf(parentName) - 1);

export const getFieldContext = (f: FieldOrOneOf, c?: ContextValueTypes): ContextValueTypes => {
  if (isOneOfField(f)) {
    if (c === undefined) {
      return [getPackageName(f.fullName, f.parent?.name ?? ''), f.parent?.name ?? ''];
    }
  } else if (f.partOf instanceof pb.OneOf) {
    if (c === undefined) {
      return [getPackageName(f.fullName, f.parent?.name ?? ''), f.parent?.name ?? '', f.name];
    }
  } else if (c === undefined) {
    return [getPackageName(f.fullName, f.parent?.name ?? ''), f.parent?.name ?? '', f.name];
  }
  return c;
};

/**
 * Use in DOM element for [id].
 * @param path dataDispatch.getPath().
 * @param i Index of Field-Map or Field-Repeated.
 */
export const getGlobalFieldKey = (path: Array<string | number>, i?: number | string): string => {
  return `${path.join(':')}${i !== undefined ? `:${i}` : ''}`;
};

export const getFieldKey = (f: FieldOrOneOf, i?: number | string): string => {
  if (isOneOfField(f)) {
    return `${f.fullName.slice(1)}`;
  }
  return `${f.fullName.slice(1)}:${f.type}:${f.id}${i !== undefined ? `:${i}` : ''}`;
};

export const getDefaultFieldValue = (f: pb.Field, destiny: '4temp' | '4data' | '4messages'): DefaultFieldValueTypes => {
  if (isMapField(f)) {
    return new Map();
  }
  if (isRepeatedField(f)) {
    return [];
  }
  if (isMessageField(f)) {
    return getFieldNames(f.resolvedType.fieldsArray, destiny);
  }
  if (isEnumField(f)) {
    return 0;
  }
  if (f.type === 'string' || f.type === 'bytes') {
    return '';
  }
  if (f.type === 'bool') {
    return false;
  }
  return 0;
};

const getNumberValidator = (fieldType: string) => {
  return (value: any) => {
    switch (typeof value) {
      case 'number':
        return true;
      case 'string':
        return validateNum(value, fieldType);
      default:
        return false;
    }
  };
};

const getMapKeyValidator = (field: pb.MapField) => {
  switch (field.keyType) {
    case 'string':
      return (key: any) => typeof key === 'string';
    case 'bool':
      return (key: any) => typeof key === 'boolean';
    // TODO: make sure this doesn't clobber map fields with number keys due to TextFieldAsNumber
    default:
      return getNumberValidator(field.keyType);
  }
};

const getValueValidator = (field: pb.Field) => {
  if (isMessageField(field)) {
    return (value: any) => value instanceof Map;
  }

  if (isEnumField(field)) {
    return (value: any) => {
      return typeof value === 'number' || field.resolvedType.valuesById[value] !== undefined;
    };
  }

  if (field.type === 'string') {
    return (value: any) => typeof value === 'string';
  }

  if (field.type === 'bool') {
    return (value: any) => typeof value === 'boolean';
  }

  if (field.type === 'bytes') {
    return (value: any) => {
      if (value instanceof Uint8Array) return true;
      if (typeof value === 'string') {
        try {
          const res = base64.base64ToBytes(value);
          return res instanceof Uint8Array;
        } catch (err) {
          return false;
        }
      }
      return false;
    };
  }

  return getNumberValidator(field.type);
};

const getDataValidator = (f: pb.Field) => {
  const valueValidator = getValueValidator(f);

  if (isMapField(f)) {
    const keyValidator = getMapKeyValidator(f);

    return (data: any) => {
      if (!(data instanceof Map)) return false;
      if (data.size === 0) return true;
      return Array.from(data).every(([key, value]) => keyValidator(key) && valueValidator(value));
    };
  }

  if (isRepeatedField(f)) {
    return (data: any) => {
      if (!Array.isArray(data)) return false;
      if (data.length === 0) return true;
      return data.every(valueValidator);
    };
  }

  return (data: any) => valueValidator(data);
};

export const useDataSanitizer = (fieldProps: UF<FCContainer<RootField>>, dispatch: Dispatch<any>): void => {
  const { field, dataDispatch, tempDataItem, updateBoth } = fieldProps;
  const dataValidator = getDataValidator(field);

  const defaultTempData = getDefaultFieldValue(field, '4temp');
  const defaultData = getDefaultFieldValue(field, '4data');

  useEffect(() => {
    if (!dataValidator(tempDataItem)) {
      dataDispatch
        .getNextFieldDispatch({
          append: [],
          newUpdateBoth: updateBoth,
          isContainerField: isContainerField(fieldProps),
        })
        .dispatch(defaultTempData, dispatch, defaultData);
    }
  }, [
    tempDataItem,
    dataDispatch,
    updateBoth,
    defaultTempData,
    defaultData,
    field,
    dispatch,
    dataValidator,
    fieldProps,
  ]);
};

const getValueTypeDisplay = (field: pb.Field) => {
  return field.resolvedType?.name ?? field.type;
};

export const getFieldTypeDisplay = (field: pb.Field): string => {
  if (isMapField(field)) {
    const keyDisplay = field.keyType;
    const valueType = getValueTypeDisplay(field);

    return `map<${keyDisplay}, ${valueType}>`;
  }

  if (isRepeatedField(field)) {
    const valueType = getValueTypeDisplay(field);
    return `repeated ${valueType}`;
  }

  return getValueTypeDisplay(field);
};

export const getFieldNameTypeIdDisplay = (field: pb.Field): string =>
  `${getFieldTypeDisplay(field)} ${field.name} = ${field.id}`;

const detectCycle = (field: TypeField<pb.Type>, original?: TypeField<pb.Type>): boolean => {
  if (field.resolvedType.constructor.name === original?.resolvedType.constructor.name) return true;
  return field.resolvedType.fieldsArray.filter(isMessageField).some((f) => detectCycle(f, original ?? field));
};

const detectOneofCycle = (field: pb.OneOf) => {
  return field.fieldsArray.filter(isMessageField).some((f) => detectCycle(f));
};

export const getFieldNames = (
  fields: FieldOrOneOf[],
  destiny: '4temp' | '4data' | '4messages',
): Map<string, DefaultFieldValueTypes> => {
  const result: Map<string, DefaultFieldValueTypes> = new Map();
  fields.forEach((v) => {
    if (destiny === '4temp') {
      if (isOneOfField(v)) {
        if (!detectOneofCycle(v)) {
          v.fieldsArray.map((f) => result.set(f.name, getDefaultFieldValue(f, destiny)));
        }
      } else {
        if (!(isMessageField(v) && detectCycle(v))) {
          result.set(v.name, getDefaultFieldValue(v, destiny));
        }
      }
    }
    if (destiny === '4data') {
      if (isOneOfField(v)) {
        v.fieldsArray.forEach((f) => {
          if (isMessageField(f) && !isMapField(f) && !isRepeatedField(f)) {
            return;
          }

          result.set(f.name, getDefaultFieldValue(f, destiny));
        });
      } else {
        if (isMessageField(v) && !isMapField(v) && !isRepeatedField(v)) {
          return;
        }
        result.set(v.name, getDefaultFieldValue(v, destiny));
      }
    }
    if (destiny === '4messages') {
      if (isOneOfField(v)) {
        v.fieldsArray.forEach((f) => {
          if (isMessageField(f) && !isMapField(f) && !isRepeatedField(f)) {
            result.set(f.name, getDefaultFieldValue(f, destiny));
          }
        });
      } else if (isMessageField(v) && !isMapField(v) && !isRepeatedField(v)) {
        result.set(v.name, getDefaultFieldValue(v, destiny));
      }
    }
  });
  return result;
};

export const getPackageNameFromField = (field: pb.FieldBase): string =>
  getPackageName(field.fullName, field.message?.name ?? '');

// export const getSelectedOneOfKey = (f: pb.OneOf): string => {
//   return [...getFieldContext(f), f.name].join(':');
// };

export const getSelectedOneOfKey = (f: FieldOrOneOf): string => {
  return [...getFieldContext(f), f.name].join(':');
};

export const getMessageKey = (f: FieldOrOneOf): string => {
  return [...getFieldContext(f)].join(':'); // !!!
};

export const hasRecursiveContents = (importObj: ImportsType): importObj is ImportContentsType => {
  return typeof importObj.contents === 'string' && importObj.imports.every(hasRecursiveContents);
};

const getPackageKeyData = (
  dataMap: Map<string, Map<string, Map<string, Map<string, RecursiveMap>>>>,
  packageName: string,
  key: string,
  messageName: string,
) => {
  const packageData = dataMap.get(packageName);
  const instanceData = new Map<string, RecursiveMap>();
  const defaultReturnMap = new Map<string, Map<string, RecursiveMap>>([[messageName, instanceData]]);
  if (!packageData) {
    dataMap.set(packageName, new Map([[key, defaultReturnMap]]));
    return instanceData;
  }

  const keyData = packageData.get(key);
  if (!keyData) {
    packageData.set(key, defaultReturnMap);
    return instanceData;
  }

  const messageData = keyData.get(messageName);
  if (!messageData) {
    keyData.set(messageName, instanceData);
    return instanceData;
  }

  return instanceData;
};

const populateMainMaps = (
  [messageName, messageFields]: [string, FieldOrOneOf[]],
  packagesMap: PackagesItemType,
  dataMap: Map<string, Map<string, Map<string, Map<string, RecursiveMap>>>>,
  tempDataMap: Map<string, Map<string, Map<string, Map<string, RecursiveMap>>>>,
  grpc?: GrpcItemType,
) => {
  const firstField = messageFields[0];
  const firstNamedField = isOneOfField(firstField) ? firstField.fieldsArray[0] : firstField;
  const packageName = getPackageNameFromField(firstNamedField);
  const existPackage = packagesMap.get(packageName);
  // 4 wsData
  const existPackageData = dataMap.get(packageName);
  if (existPackage && existPackageData) {
    existPackage.set(messageName, messageFields);
  } else {
    packagesMap.set(packageName, new Map([[messageName, messageFields]]));
  }

  if (grpc) {
    const serviceMethodsTuples = Object.keys(grpc)
      .filter((k) => packageName.length && k.startsWith(packageName))
      .map((k) => {
        const serviceName = k.replace(`${packageName}.`, '');
        const methodNames = Object.keys(grpc[k]);

        return [serviceName, methodNames] as const;
      });

    const serviceKeys = serviceMethodsTuples.flatMap(([service, methods]) =>
      methods.map((method) => `grpc:${service}:${method}`),
    );

    const dataKeys = ['http', ...serviceKeys];
    const defaultDataValues = getFieldNames(messageFields, '4data');
    const defaultTempDataValues = getFieldNames(messageFields, '4temp');

    dataKeys.forEach((k) => {
      getPackageKeyData(dataMap, packageName, k, messageName).set('0', defaultDataValues);
      getPackageKeyData(tempDataMap, packageName, k, messageName).set('0', defaultTempDataValues);
    });
  }
};

export const populateDecodingObj = (field: pb.FieldBase, decodingObj: DecodingItemType): void => {
  const decodingKey = field.message?.fullName!.substring(1);
  if (decodingKey) {
    decodingObj[decodingKey] = 'json';
  }
};

export const populateMessagesObj = (
  field: pb.Field,
  messagesObj: SelectedMessagesItemType,
  prevPath?: string,
): void => {
  const key = prevPath ? `${prevPath}:${field.name}` : getMessageKey(field);
  messagesObj[key] = false;
  if (isMessageField(field)) {
    field.resolvedType.fieldsArray.forEach((f) => {
      // avoid getting Repeated-fields which came from Message-field into "message" reducer
      if (isMessageField(f) && !isRepeatedField(f) && !isMapField(f)) {
        // Avoid cycles
        if (prevPath && !prevPath.includes(`:${f.name}:`)) {
          populateMessagesObj(f, messagesObj, key);
        }
      }
    });
  }
};

export const populateSelectedOneOfObj = (
  field: FieldOrOneOf,
  selectedOneOfObj: SelectedOneOfItemType,
  prevPath?: string,
): void => {
  const key = prevPath ? `${prevPath}:${field.name}` : getSelectedOneOfKey(field);
  if (isOneOfField(field)) {
    selectedOneOfObj[key] = -1;
  }
  if (
    field instanceof pb.Field &&
    field.resolvedType &&
    field.resolvedType instanceof pb.Type &&
    field.resolvedType.oneofsArray &&
    !field.map &&
    !field.repeated
  ) {
    field.resolvedType.oneofsArray.forEach((f) => {
      // Avoid cycles
      if (prevPath && !prevPath.includes(`:${field.name}:`)) {
        populateSelectedOneOfObj(
          f,
          selectedOneOfObj,
          prevPath ? `${prevPath}:${field.name}` : [...getFieldContext(field)].join(':'),
        );
      }
    });
    field.resolvedType.fieldsArray.forEach((f) => {
      if (prevPath && !prevPath.includes(`:${field.name}:`)) {
        populateSelectedOneOfObj(
          f,
          selectedOneOfObj,
          prevPath ? `${prevPath}:${field.name}` : [...getFieldContext(field)].join(':'),
        );
      }
    });
  }
};

const fromMessageFields = (
  messagesMap: Map<string, FieldOrOneOf[]>,
  mainObjects: MainObjectsType,
  grpc?: GrpcItemType,
): void => {
  const {
    packagesMap,
    dataMap,
    tempDataMap,
    selectedOneOfObj,
    decodingObj,
    messagesObj,
    // dataMap2,
    // tempDataMap2,
  } = mainObjects;
  messagesMap.forEach((messageFields, messageName) => {
    if (messageFields.length) {
      populateMainMaps([messageName, messageFields], packagesMap, dataMap, tempDataMap, grpc);
      messageFields.forEach((field) => {
        /**
         *  Population of OneOf-fields
         */
        populateSelectedOneOfObj(field, selectedOneOfObj);
        if (!(field instanceof pb.OneOf)) {
          populateDecodingObj(field, decodingObj);
        }
        /**
         *  Population of Message-fields
         */
        if (!isOneOfField(field) && isMessageField(field) && !isMapField(field) && !isRepeatedField(field)) {
          populateMessagesObj(field, messagesObj);
        }
        /**
         * populate Message-field if it's a part of OneOf
         */
        if (isOneOfField(field)) {
          field.fieldsArray.forEach((f) => {
            if (isMessageField(f)) {
              populateMessagesObj(f, messagesObj);
            }
          });
        }
      });
    }
  });
};

export const getMainObjects = (m: PackagesItemType, grpc?: GrpcItemType): MainObjectsType => {
  const packagesMap: PackagesItemType = new Map();
  const selectedOneOfObj: SelectedOneOfItemType = {};
  const decodingObj: DecodingItemType = {};
  const messagesObj: SelectedMessagesItemType = {};

  const dataMap: Map<string, Map<string, Map<string, Map<string, RecursiveMap>>>> = new Map();
  const tempDataMap: Map<string, Map<string, Map<string, Map<string, RecursiveMap>>>> = new Map();

  const mainObjects = {
    packagesMap,
    dataMap,
    tempDataMap,
    selectedOneOfObj,
    decodingObj,
    messagesObj,
  };

  m.forEach((messageFields) => {
    fromMessageFields(messageFields, mainObjects, grpc);
  });

  return mainObjects;
};

function setHttpItem(messageType: pb.Type, http: HttpItemType): void {
  const packageName = getPackageName(messageType.fullName ?? '', messageType.name ?? '');
  if (Object.prototype.hasOwnProperty.call(http, packageName)) {
    http[packageName][messageType.name] = { instances: [_.cloneDeep(initialHttpSendParams)] };
  } else {
    http[packageName] = { [messageType.name]: { instances: [_.cloneDeep(initialHttpSendParams)] } };
  }
}

export const getMessagesAndRpc = (root: pb.ReflectionObject): MessagesAndRpcObject => {
  const messages: PackagesItemType = new Map();
  const grpc: GrpcItemType = {};
  const http: HttpItemType = {};

  const getResult = (relectionObj: pb.ReflectionObject): void => {
    if (relectionObj instanceof pb.Root || relectionObj instanceof pb.Namespace) {
      relectionObj.nestedArray.forEach((nested) => getResult(nested));
    }
    if (relectionObj instanceof pb.Type && !relectionObj.oneofs) {
      const tmpMes = messages.get(getPackageName(relectionObj.fullName ?? '', relectionObj.name ?? ''));
      if (tmpMes) {
        tmpMes.set(relectionObj.name, relectionObj.fieldsArray);
      } else {
        messages.set(
          getPackageName(relectionObj.fullName ?? '', relectionObj.name ?? ''),
          new Map([[relectionObj.name, relectionObj.fieldsArray]]),
        );
      }
      setHttpItem(relectionObj, http);
    }
    if (relectionObj instanceof pb.Type && relectionObj.oneofs) {
      const fieldsArray = relectionObj.fieldsArray.filter((field) => !field.partOf);
      const tmpMes = messages.get(getPackageName(relectionObj.fullName ?? '', relectionObj.name ?? ''));
      if (tmpMes) {
        tmpMes.set(relectionObj.name, [...fieldsArray, ...relectionObj.oneofsArray]);
      } else {
        messages.set(
          getPackageName(relectionObj.fullName ?? '', relectionObj.name ?? ''),
          new Map([[relectionObj.name, [...fieldsArray, ...relectionObj.oneofsArray]]]),
        );
      }
      setHttpItem(relectionObj, http);
    }
    if (relectionObj instanceof pb.Service) {
      relectionObj.methodsArray.forEach((method) => {
        const requestMessage: [string, string] = [
          getPackageName(method.resolvedRequestType?.fullName ?? '', method.resolvedRequestType?.name ?? ''),
          method.resolvedRequestType?.name ?? '',
        ];
        const responseMessage: [string, string] = [
          getPackageName(method.resolvedResponseType?.fullName ?? '', method.resolvedResponseType?.name ?? ''),
          method.resolvedResponseType?.name ?? '',
        ];
        const grpcMethodType = getGrpcMethodType(method);
        const serviceName = method.parent?.fullName.substring(1) ?? '';
        const instance = getDefaultGrpcMessageInstanceParams(grpcMethodType, requestMessage, responseMessage);

        const service = grpc[serviceName];
        if (service) {
          service[method.name] = service[method.name] ?? { instances: [] };
          service[method.name].instances.push(instance);
        } else {
          grpc[serviceName] = { [method.name]: { instances: [instance] } };
        }
      });
    }
  };

  getResult(root);
  return { messages, grpc, http };
};
