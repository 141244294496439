import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { Root } from 'protobufjs';
import { HttpResponse, HttpSuccessResponse } from '../../pb-node/request_pb';
import { getStringified } from '../../utils/common';
import type { ObjectToSerialize } from '../../utils/common.types';
import { importGithubWorkspace } from '../thunks/githubThunk';
import { grpcCancel, grpcEnd, grpcPush, grpcSend, makePoll } from '../thunks/grpcThunk';
import { httpRequest } from '../thunks/httpThunk';
import { createWorkspace, deleteWorkspace, getWorkspaces, upsertProtos } from '../thunks/workspacesThunk';
import {
  ChangeInteractionDecodingPayloadType,
  HttpRes,
  InteractionItemType,
  InteractionState,
  RemoveInteractionItemPayloadType,
  RemoveInteractionPayloadType,
} from './interactionSlice.types';
import type { CreateWorkspacesResponse } from './workspacesSlice.types';

export const initialInteractionItem: InteractionItemType = {
  http: {},
  grpc: {},
};

const initialState: InteractionState = {};

const getHttpInteractionResult = (payload: HttpResponse, decodingMessage: string, root: Root): HttpRes => {
  const errorResponse = payload.getErrorResponse();
  if (errorResponse) {
    return {
      error: true,
      text: errorResponse.getMessage(),
      original: payload.clone(),
      decodingMessage,
    };
  }

  // We either get an error response or a success response
  const successResponse = payload.getSuccessResponse()!;
  const contentType = successResponse.getContentCase();

  try {
    switch (contentType) {
      case HttpSuccessResponse.ContentCase.PROTO_RESPONSE: {
        if (decodingMessage !== 'json' && decodingMessage !== 'text') {
          const decoder = root.lookupType(decodingMessage);
          // ! is ok here because of the swich on the oneof case
          const responsePayload = successResponse.getProtoResponse()!.getPayload_asU8();
          let decodedResponsePayload;
          try {
            decodedResponsePayload = decoder.decode(responsePayload);
          } catch (err) {
            console.log({ err }, `Error decoding proto response using ${decodingMessage}`);
            throw err;
          }
          const responseString = getStringified(decodedResponsePayload.toJSON());

          return {
            error: false,
            text: responseString,
            original: payload.clone(),
            decodingMessage,
          };
        }

        return {
          error: true,
          text: `Received a protobuf response but response decoding is configured to decode as ${decodingMessage}.  Please select the appropriate response type and try again.`,
          original: payload.clone(),
          decodingMessage,
        };
      }

      case HttpSuccessResponse.ContentCase.JSON_RESPONSE:
      case HttpSuccessResponse.ContentCase.TEXT_RESPONSE: {
        if (decodingMessage === 'json' || decodingMessage === 'text') {
          const responsePayload =
            successResponse.getJsonResponse()?.getPayload() ?? successResponse.getTextResponse()?.getPayload() ?? '';

          return {
            error: false,
            text: responsePayload,
            original: payload.clone(),
            decodingMessage,
          };
        }

        return {
          error: true,
          text: `Received a ${HttpSuccessResponse.ContentCase[contentType]} response but response decoding is configured to decode as ${decodingMessage}.  Please select the appropriate response type and try again.`,
          original: payload.clone(),
          decodingMessage,
        };
      }

      case HttpSuccessResponse.ContentCase.UNKNOWN_RESPONSE: {
        // ! is ok here because of the swich on the oneof case
        const responseString = getStringified(successResponse.getUnknownResponse()!.toObject());

        return {
          error: false,
          text: responseString,
          original: payload.clone(),
          decodingMessage,
        };
      }

      case HttpSuccessResponse.ContentCase.CONTENT_NOT_SET: {
        return {
          error: true,
          text: 'Invalid response from Protocall Agent',
          original: payload.clone(),
          decodingMessage,
        };
      }
    }
  } catch (e) {
    console.log({ e, payload, decodingMessage });
    // TODO: more informative error message?  We should probably be tracking these cases, since this shouldn't happen...
    return {
      error: true,
      text: 'Unknown error when decoding response.',
      original: payload.clone(),
      decodingMessage,
    };
  }
};

const interactionSlice = createSlice({
  name: 'interaction',
  initialState,
  reducers: {
    addInteractions: (_state, { payload }: PayloadAction<InteractionState>) => payload,
    removeInteraction: (state, { payload }: PayloadAction<RemoveInteractionPayloadType>) => {
      const { wsId, key, type } = payload;
      delete state[wsId][type][key];
    },
    removeInteractionItem: (state, { payload }: PayloadAction<RemoveInteractionItemPayloadType>) => {
      const { wsId, key, value, type } = payload;
      state[wsId][type][key].interactions.splice(value, 1);
    },
    changeHttpInteractionDecoding: (state, { payload }: PayloadAction<ChangeInteractionDecodingPayloadType>) => {
      const { wsId, decodingMessage, key, root } = payload;
      const previousInteractions = state[wsId].http[key].interactions;

      state[wsId].http[key].decodingMessage = decodingMessage;
      state[wsId].http[key].interactions = previousInteractions.map((interaction) => ({
        req: interaction.req,
        res: interaction.res.map((res) => {
          if (res.original) {
            return getHttpInteractionResult(res.original, decodingMessage, root);
          }
          return res;
        }),
      }));
    },
    clearInteractions: () => initialState,
  },
  extraReducers: (builder) => {
    // upsertProtos ********************************************************************************
    builder.addCase(upsertProtos.fulfilled, (state, { payload }) => {
      const { workspace_id } = payload;
      state[workspace_id] = _.cloneDeep(initialInteractionItem);
    });
    // createWorkspace *****************************************************************************
    builder.addCase(createWorkspace.fulfilled, (state, { payload }: PayloadAction<CreateWorkspacesResponse>) => {
      state[payload.workspace_id] = _.cloneDeep(initialInteractionItem);
    });
    // getWorkspaces *******************************************************************************
    builder.addCase(getWorkspaces.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      workspaces.forEach((w) => {
        state[w.workspace_id] = _.cloneDeep(initialInteractionItem);
      });
    });
    builder.addCase(importGithubWorkspace.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      workspaces.forEach((w) => {
        // Unlike with getWorkspaces, which is presumably only being called on app init,
        // We don't want to clobber data for existing workspaces
        // Since importGithubWorkspace still returns data for all of them
        state[w.workspace_id] = state[w.workspace_id] ?? _.cloneDeep(initialInteractionItem);
      });
    });
    // deleteWorkspace *****************************************************************************
    builder.addCase(deleteWorkspace.fulfilled, (state, { meta }) => {
      delete state[meta.arg];
    });
    // grpcSend ************************************************************************************
    builder.addCase(grpcSend.pending, (state, { meta }) => {
      const { wsId, dataToSend, currentSelectedRpc } = meta.arg;
      const item = {
        req: [getStringified(dataToSend)],
        res: [],
      };
      const { service, rpc, instance } = currentSelectedRpc;
      const path = `${service}.${rpc}.${instance}`;
      const value = state[wsId].grpc[path];
      if (value) {
        value.interactions.push(item);
      } else {
        state[wsId].grpc[path] = {
          interactions: [item],
        };
      }
    });
    builder.addCase(grpcSend.fulfilled, (state, { payload }) => {
      const responses = payload[0];
      responses.forEach((r) => {
        const { workspaceId, serviceName, methodName, instanceName, payload: pl, type } = r;
        const path = `${serviceName}.${methodName}.${instanceName}`;
        const { interactions } = state[workspaceId].grpc[path];
        if (interactions.length) {
          interactions[interactions.length - 1].res.push({
            error: type === 'error',
            text: getStringified(pl),
          });
        }
      });
    });
    builder.addCase(grpcSend.rejected, (state, { meta, payload }) => {
      const { wsId, currentSelectedRpc } = meta.arg;
      const { service, rpc, instance } = currentSelectedRpc;
      const path = `${service}.${rpc}.${instance}`;
      const { interactions } = state[wsId].grpc[path];
      interactions[interactions.length - 1].res.push({
        error: true,
        text: getStringified(payload ?? ''),
      });
    });
    // grpcPush ************************************************************************************
    builder.addCase(grpcPush.pending, (state, { meta }) => {
      const { wsId, dataToSend, currentSelectedRpc } = meta.arg;
      const { service, rpc, instance } = currentSelectedRpc;
      const path = `${service}.${rpc}.${instance}`;
      const value = state[wsId].grpc[path];
      if (value && value.interactions.length) {
        value.interactions[value.interactions.length - 1].req.push(getStringified(dataToSend));
      } else {
        const item = {
          req: [getStringified(dataToSend)],
          res: [],
        };
        state[wsId].grpc[path] = {
          interactions: [item],
        };
      }
    });
    builder.addCase(grpcPush.fulfilled, (state, { payload }) => {
      const responses = payload[0];
      responses.forEach((r) => {
        const { workspaceId, serviceName, methodName, instanceName, payload: pl } = r;
        const path = `${serviceName}.${methodName}.${instanceName}`;
        const { interactions } = state[workspaceId].grpc[path];
        interactions[interactions.length - 1].res.push({
          error: false,
          text: getStringified(pl),
        });
      });
    });
    builder.addCase(grpcPush.rejected, (state, { payload }) => {
      // TODO: display error, or something?
      console.log(payload);
    });
    // grpcEnd ************************************************************************************
    builder.addCase(grpcEnd.fulfilled, (state, { payload }) => {
      const responses = payload[0];
      responses.forEach((r) => {
        const { workspaceId, serviceName, methodName, instanceName, payload: pl } = r;
        // TODO Error after reloading app on empty array - 'interactions' not exists
        const path = `${serviceName}.${methodName}.${instanceName}`;
        const { interactions } = state[workspaceId].grpc[path];
        interactions[interactions.length - 1]?.res.push({
          error: false,
          text: getStringified(pl),
        });
      });
    });
    builder.addCase(grpcEnd.rejected, (state, { payload }) => {
      // TODO: display error, or something?
      console.log(payload);
    });
    // grpcCancel ************************************************************************************
    builder.addCase(grpcCancel.fulfilled, (state, { payload }) => {
      const responses = payload[0];
      responses.forEach((r) => {
        const { workspaceId, serviceName, methodName, instanceName, payload: pl } = r;
        const path = `${serviceName}.${methodName}.${instanceName}`;
        const { interactions } = state[workspaceId].grpc[path];
        interactions[interactions.length - 1]?.res.push({
          error: false,
          text: getStringified(pl),
        });
      });
    });
    builder.addCase(grpcCancel.rejected, (state, { payload }) => {
      // TODO: display error, or something?
      console.log(payload);
    });
    // makePoll ************************************************************************************
    builder.addCase(makePoll.fulfilled, (state, { payload }) => {
      const responses = payload[0];
      responses.forEach((r) => {
        const { workspaceId, serviceName, methodName, instanceName, payload: pl } = r;
        const path = `${serviceName}.${methodName}.${instanceName}`;
        const { interactions } = state[workspaceId].grpc[path];
        interactions[interactions.length - 1]?.res.push({
          error: false,
          text: getStringified(pl),
        });
      });
    });
    builder.addCase(makePoll.rejected, (state, { payload }) => {
      // TODO: display error, or something?
      console.log(payload);
    });
    // httpRequest *********************************************************************************
    builder.addCase(httpRequest.pending, (state, { meta }) => {
      const { wsId, dataToSend, httpMessage } = meta.arg;
      const selectedMessage = httpMessage.join('.');
      const item = {
        req: [getStringified(dataToSend)],
        res: [],
      };
      const value = state[wsId].http[selectedMessage];
      if (value) {
        value.interactions.push(item);
      } else {
        state[wsId].http[selectedMessage] = {
          decodingMessage: 'json',
          interactions: [item],
        };
      }
    });
    builder.addCase(httpRequest.fulfilled, (state, { meta, payload }) => {
      const { wsId, httpMessage, root } = meta.arg;
      const value = state[wsId].http[httpMessage.join('.')];
      if (value) {
        const responses = value.interactions[value.interactions.length - 1].res;
        const previousResponse = value.interactions[value.interactions.length - 2]?.res;
        const decodingMessage = previousResponse?.slice(-1)?.[0]?.decodingMessage ?? 'json';
        const decodedHttpResponse = getHttpInteractionResult(payload, decodingMessage, root);
        responses.push(decodedHttpResponse);
      }
    });
    builder.addCase(httpRequest.rejected, (state, { meta, payload }) => {
      const { wsId, httpMessage } = meta.arg;
      const value = state[wsId].http[httpMessage.join('.')];
      if (value) {
        const responses = value.interactions[value.interactions.length - 1].res;
        const previousResponse = value.interactions[value.interactions.length - 2]?.res;
        const decodingMessage = previousResponse?.slice(-1)?.[0]?.decodingMessage ?? 'json';
        responses.push({
          error: true,
          text: getStringified(payload as ObjectToSerialize),
          decodingMessage,
        });
      }
    });
  },
});

export const {
  addInteractions,
  removeInteraction,
  removeInteractionItem,
  changeHttpInteractionDecoding,
  clearInteractions,
} = interactionSlice.actions;

export default interactionSlice.reducer;
