import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-unresolved
import type { WritableDraft } from 'immer/dist/internal';
import _ from 'lodash';
import type { OperationsItemPayload, OperationsItemType, OperationsState } from './operationsSlice.types';
import { createWorkspace, deleteWorkspace, getWorkspaces, upsertProtos } from '../thunks/workspacesThunk';
import type { CreateWorkspacesResponse } from './workspacesSlice.types';
import type { ExtendedWorkspace } from '../thunks/types';
import { importGithubWorkspace } from '../thunks/githubThunk';

export const initialOperationsItem: OperationsItemType = { value: 0 };

const initialState: OperationsState = {};

const hydratedOperationsWithWorkspaces = (state: WritableDraft<OperationsState>, workspaces: ExtendedWorkspace[]) => {
  workspaces.forEach((w) => {
    const { workspace_id } = w;
    if (w.hydrated) {
      if (Object.keys(w.messagesAndRpc.grpc).length) {
        state[workspace_id] = { value: 0 };
      } else {
        state[workspace_id] = { value: 1 };
      }
    } else {
      state[workspace_id] = { value: 0 };
    }
  });
};

const operationsSlice = createSlice({
  name: 'operations',
  initialState,
  reducers: {
    setOperations: (_state, { payload }: PayloadAction<OperationsState>) => payload,
    setOperationsItem: (state, { payload }: PayloadAction<OperationsItemPayload>) => {
      const { wsId, value } = payload;
      state[wsId] = value;
    },
    clearOperations: () => initialState,
  },
  extraReducers: (builder) => {
    // upsertProtos ********************************************************************************
    builder.addCase(upsertProtos.fulfilled, (state, { payload }) => {
      const { workspace_id } = payload;
      if (payload.hydrated) {
        if (Object.keys(payload.messagesAndRpc.grpc).length) {
          state[workspace_id] = { value: 0 };
        } else {
          state[workspace_id] = { value: 1 };
        }
      }
    });
    // createWorkspace *****************************************************************************
    builder.addCase(createWorkspace.fulfilled, (state, { payload }: PayloadAction<CreateWorkspacesResponse>) => {
      state[payload.workspace_id] = _.cloneDeep(initialOperationsItem);
    });
    // getWorkspaces *******************************************************************************
    builder.addCase(getWorkspaces.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      hydratedOperationsWithWorkspaces(state, workspaces);
    });
    builder.addCase(importGithubWorkspace.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      hydratedOperationsWithWorkspaces(state, workspaces);
    });
    // deleteWorkspace *****************************************************************************
    builder.addCase(deleteWorkspace.fulfilled, (state, { meta }) => {
      delete state[meta.arg];
    });
  },
});

export const { setOperations, setOperationsItem, clearOperations } = operationsSlice.actions;

export default operationsSlice.reducer;
