import { createAsyncThunk } from '@reduxjs/toolkit';
import type { SendParseProtosType } from '../../components/MainContent/LeftColumn/sendProtos.types';
import { mapToObject } from '../../utils/common';
import type { PostResponse, PostRouteRequest } from '../axiosConfig/axios.types';
import axios from '../axiosConfig/axiosConfig';
import type { IWorkspacesState } from '../slices/workspacesSlice.types';
import type { AppThunk, Store } from '../store';
import { ExtendedWorkspace, GetWorkspacesResponse } from './types';
import { getExtendedWorkspaces, getExtendedWorkspacesItem } from './util';

export const addProtos = createAsyncThunk<
  ExtendedWorkspace,
  SendParseProtosType,
  {
    rejectValue: string;
    state: Store;
  }
>('workspaces/addProtos', async (data: SendParseProtosType, { rejectWithValue, getState }) => {
  try {
    const {
      auth: { sessionToken },
      workspaces,
    } = getState();
    const response = await axios.post('/add-protos', {
      sessionToken,
      protos: data,
    });
    return getExtendedWorkspacesItem({
      workspace_id: data[0].workspace_id,
      user_data: {},
      workspace_name: workspaces.workspaces[data[0].workspace_id].workspaceName,
      protos: response.data,
      last_updated_at: new Date().toString(),
    });
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

export const upsertProtos = createAsyncThunk<
  ExtendedWorkspace,
  SendParseProtosType,
  {
    rejectValue: string;
    state: Store;
  }
>('workspaces/upsertProtos', async (data: SendParseProtosType, { rejectWithValue, getState }) => {
  try {
    const { auth, workspaces } = getState();
    const response = await axios.post('/upsert-protos', {
      sessionToken: auth.sessionToken,
      protos: data,
    });
    return getExtendedWorkspacesItem({
      workspace_id: data[0].workspace_id,
      user_data: {},
      workspace_name: workspaces.workspaces[data[0].workspace_id].workspaceName,
      protos: response.data,
      last_updated_at: new Date().toString(),
    });
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

export const parseProtos = createAsyncThunk<
  PostResponse<'/parse-protos'>,
  SendParseProtosType,
  {
    rejectValue: string;
    state: Store;
  }
>('workspaces/parseProtos', async (data: SendParseProtosType, { rejectWithValue, getState }) => {
  try {
    const { sessionToken } = getState().auth;
    const response = await axios.post('/parse-protos', {
      sessionToken,
      protos: data,
    });
    return response.data;
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

export const createWorkspace = createAsyncThunk<
  PostResponse<'/create-workspace'>,
  string,
  {
    rejectValue: string;
    state: Store;
  }
>('workspaces/createWorkspace', async (data: string, { rejectWithValue, getState }) => {
  try {
    const { sessionToken } = getState().auth;
    const response = await axios.post('/create-workspace', {
      sessionToken,
      workspace_name: data,
    });
    return response.data;
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

export const getWorkspaces = createAsyncThunk<
  GetWorkspacesResponse,
  void,
  {
    rejectValue: string;
    state: Store;
  }
>('workspaces/getWorkspaces', async (_args, { rejectWithValue, getState }) => {
  try {
    const { sessionToken } = getState().auth;
    const response = await axios.post('/get-workspaces', { sessionToken });
    const extendedWorkspaces = getExtendedWorkspaces(response.data.workspaces);
    return { workspaces: extendedWorkspaces };
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

export const deleteWorkspace = createAsyncThunk<
  { ws: IWorkspacesState['workspaces'] },
  string,
  {
    rejectValue: string;
    state: Store;
  }
>('workspaces/deleteWorkspace', async (data: string, { rejectWithValue, getState }) => {
  try {
    const { sessionToken } = getState().auth;
    await axios.post('/delete-workspace', {
      sessionToken,
      workspace_id: data,
    });
    return { ws: getState().workspaces.workspaces };
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

export const updateWorkspaceUserData = createAsyncThunk<
  PostResponse<'/update-account-workspace-data'>,
  void,
  {
    rejectValue: string;
    state: Store;
  }
>('workspaces/updateWorkspaceUserData', async (_args, { rejectWithValue, getState }) => {
  const {
    auth,
    data,
    tempData,
    grpc,
    http,
    selectedOneOf,
    selectedMessages,
    anySchema,
    workspaces,
    autosave,
  } = getState();

  const workspaceIdsToUpdate = Object.keys(autosave).filter((key) => autosave[key].needsUpdate);

  if (workspaces.loading || workspaces.errorMessage.length > 0 || workspaceIdsToUpdate.length === 0) {
    return Promise.resolve({
      updates: [],
    });
  }

  const { sessionToken } = auth;

  const workspaceUpdates = workspaceIdsToUpdate.map((workspaceId) => {
    const workspaceData = mapToObject(data[workspaceId]);
    const workspaceTempData = mapToObject(tempData[workspaceId]);
    const workspaceGrpc = grpc[workspaceId];
    const workspaceHttp = http[workspaceId];
    const workspaceSelectedOneofs = selectedOneOf[workspaceId];
    const workspaceSelectedMessages = selectedMessages[workspaceId];

    const userData: PostRouteRequest<'/update-account-workspace-data'>['updates'][number]['user_data'] = {
      data: workspaceData,
      temp_data: workspaceTempData,
      grpc: workspaceGrpc,
      http: workspaceHttp,
      selected_oneofs: workspaceSelectedOneofs,
      selected_messages: workspaceSelectedMessages,
    };

    return {
      workspace_id: workspaceId,
      user_data: userData,
    };
  });

  try {
    const response = await axios.post('/update-account-workspace-data', {
      sessionToken,
      updates: workspaceUpdates,
    });
    return response.data;
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

const POLLING_DELAY = 60000;
export const autosavePollThunk = (): AppThunk => (dispatch) => {
  const pollingId = setInterval(async () => {
    await dispatch(updateWorkspaceUserData());
  }, POLLING_DELAY);
};
