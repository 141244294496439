import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-unresolved
import type { WritableDraft } from 'immer/dist/internal';
import _ from 'lodash';
import { importGithubWorkspace } from '../thunks/githubThunk';
import type { ExtendedWorkspace } from '../thunks/types';
import { createWorkspace, deleteWorkspace, getWorkspaces, upsertProtos } from '../thunks/workspacesThunk';
import type {
  SelectedGrpcMessageItemPayload,
  SelectedGrpcMessageItemType,
  SelectedGrpcMessageState,
} from './selectedGrpcMessageSlice.types';
import type { CreateWorkspacesResponse } from './workspacesSlice.types';

export const initialSelectedGrpcMessageItem: SelectedGrpcMessageItemType = ['', ''];

const initialState: SelectedGrpcMessageState = {};

const hydratedSelectedHttpMessageWithWorkspaces = (
  state: WritableDraft<SelectedGrpcMessageState>,
  workspaces: ExtendedWorkspace[],
) => {
  workspaces.forEach((w) => {
    const { workspace_id } = w;
    if (w.hydrated) {
      if (Object.keys(w.messagesAndRpc.grpc).length) {
        const [, rpcs] = Object.entries(w.messagesAndRpc.grpc)[0];
        const messageInstances = Object.values(rpcs)[0];
        const requestMessage = messageInstances?.instances[0]?.requestMessage;
        if (requestMessage) state[workspace_id] = requestMessage;
      } else {
        state[workspace_id] = _.cloneDeep(initialSelectedGrpcMessageItem);
      }
    } else {
      state[workspace_id] = _.cloneDeep(initialSelectedGrpcMessageItem);
    }
  });
};

const selectedGrpcMessageSlice = createSlice({
  name: 'selectedGrpcMessage',
  initialState,
  reducers: {
    setSelectedGrpcMessage: (_state, { payload }: PayloadAction<SelectedGrpcMessageState>) => payload,
    setSelectedGrpcMessageItem: (state, { payload }: PayloadAction<SelectedGrpcMessageItemPayload>) => {
      state[payload.wsId] = payload.value;
    },
    clearSelectedGrpcMessage: () => initialState,
  },
  extraReducers: (builder) => {
    // upsertProtos ********************************************************************************
    builder.addCase(upsertProtos.fulfilled, (state, { payload }) => {
      const { workspace_id } = payload;
      if (payload.hydrated) {
        if (Object.keys(payload.messagesAndRpc.grpc).length) {
          const [, rpcs] = Object.entries(payload.messagesAndRpc.grpc)[0];
          const messageInstances = Object.values(rpcs)[0];
          const requestMessage = messageInstances?.instances[0]?.requestMessage;
          if (requestMessage) state[workspace_id] = requestMessage;
          else state[workspace_id] = _.cloneDeep(initialSelectedGrpcMessageItem);
        } else {
          state[workspace_id] = _.cloneDeep(initialSelectedGrpcMessageItem);
        }
      }
    });
    // createWorkspace *****************************************************************************
    builder.addCase(createWorkspace.fulfilled, (state, { payload }: PayloadAction<CreateWorkspacesResponse>) => {
      state[payload.workspace_id] = _.cloneDeep(initialSelectedGrpcMessageItem);
    });
    // getWorkspaces *******************************************************************************
    builder.addCase(getWorkspaces.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      hydratedSelectedHttpMessageWithWorkspaces(state, workspaces);
    });
    builder.addCase(importGithubWorkspace.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      hydratedSelectedHttpMessageWithWorkspaces(state, workspaces);
    });
    // deleteWorkspace *****************************************************************************
    builder.addCase(deleteWorkspace.fulfilled, (state, { meta }) => {
      delete state[meta.arg];
    });
  },
});

export const {
  setSelectedGrpcMessage,
  setSelectedGrpcMessageItem,
  clearSelectedGrpcMessage,
} = selectedGrpcMessageSlice.actions;

export default selectedGrpcMessageSlice.reducer;
