import * as pb from 'protobufjs';
import { getMessagesAndRpc, getMainObjects } from '../../components/MainContent/LeftColumn/util';
import type { PostResponse } from '../axiosConfig/axios.types';
import type { BaseGetWorkspacesResponse, ExtendedWorkspace, GeneratedProtoDefaults } from './types';

const hasHydratedProtos = (ws: BaseGetWorkspacesResponse): ws is BaseGetWorkspacesResponse & GeneratedProtoDefaults => {
  return !!ws.protos?.nested;
};

const getExtendedWorkspacesItem = function getExtendedWorkspacesItem(
  ws: PostResponse<'/get-workspaces'>['workspaces'][number],
): ExtendedWorkspace {
  if (hasHydratedProtos(ws)) {
    try {
      const root = new pb.Root();
      root.addJSON(ws.protos.nested);
      root.resolveAll();
      const messagesAndRpc = getMessagesAndRpc(root);
      const mainObjects = getMainObjects(messagesAndRpc.messages, messagesAndRpc.grpc);
      return { ...ws, messagesAndRpc, mainObjects, root, hydrated: true };
    } catch (e) {
      console.log({ e });
      throw Error(e);
    }
  } else {
    return { ...ws, hydrated: false };
  }
};

const getExtendedWorkspaces = (workspaces: PostResponse<'/get-workspaces'>['workspaces']): ExtendedWorkspace[] => {
  return workspaces.map((w) => {
    return getExtendedWorkspacesItem(w);
  });
};

export { getExtendedWorkspacesItem, getExtendedWorkspaces };
