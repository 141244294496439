import { Lazy, pipe } from 'fp-ts/lib/function';
import * as o from 'fp-ts/Option';
// eslint-disable-next-line import/no-unresolved
import type { WritableDraft } from 'immer/dist/internal';
import path from 'path';
import * as pb from 'protobufjs';
import { ComponentProps, FC, ReactElement } from 'react';
import { ImportsType, RecursiveMap } from '../components/MainContent/LeftColumn/util.types';
import { GrpcState, RpcParamsType } from '../store/slices/grpcSlice.types';
import { HttpParamsType, HttpState } from '../store/slices/httpSlice.types';
import { SelectedHttpMessageItemType } from '../store/slices/selectedHttpMessageSlice.types';
import { SelectedRpcPayloadType } from '../store/slices/selectedRpcSlice.types';
import { ObjectToSerialize } from './common.types';

export const serialize = (m: Map<any, any>): ObjectToSerialize => {
  let i = 0;
  const obj: ObjectToSerialize = {};
  m.forEach((v, k) => {
    const to: ObjectToSerialize = {};
    if (v instanceof Map) {
      to[k] = serialize(v);
    } else if (v instanceof Array) {
      to[k] = v.map((item) => {
        if (item instanceof Map) {
          return serialize(item);
        }
        return item;
      });
    } else {
      to[k] = v;
    }
    obj[i] = to;
    i += 1;
  });
  return obj;
};

export const deserialize = (obj: ObjectToSerialize): Map<any, any> => {
  const m = new Map();
  Object.entries(obj).forEach(([_i, v]) => {
    const k = Object.keys(v)[0];
    const val = v[k];
    if (val instanceof Array) {
      m.set(
        k,
        val.map((item) => {
          if (item instanceof Object) {
            return deserialize(item);
          }
          return item;
        }),
      );
    } else if (val instanceof Object) {
      m.set(k, deserialize(val));
    } else {
      m.set(k, val);
    }
  });
  return m;
};

export const mapToObject = (m: RecursiveMap): ObjectToSerialize => {
  const obj: ObjectToSerialize = {};
  m.forEach((v, k) => {
    if (v instanceof Map) {
      obj[k] = mapToObject(v);
    } else if (v instanceof Array) {
      obj[k] = v.map((item) => {
        if (item instanceof Map) {
          return mapToObject(item);
        }
        return item;
      });
    } else {
      obj[k] = v;
    }
  });
  return obj;
};

export const objectToMap = (obj: ObjectToSerialize): Map<any, any> => {
  const m = new Map();
  Object.keys(obj).forEach((k) => {
    if (Array.isArray(obj[k])) {
      m.set(
        k,
        obj[k].map((item: any) => {
          if (item instanceof Object) {
            return objectToMap(item);
          }
          return item;
        }),
      );
    } else if (obj[k] instanceof Object) {
      m.set(k, objectToMap(obj[k]));
    } else {
      m.set(k, obj[k]);
    }
  });
  return m;
};

export const getStringified = (str: ObjectToSerialize | string): string => {
  return JSON.stringify(str, null, 2);
};

type ReadFileType = [contents: string, imports: ImportsType[]];

export const UseReadProtoFile = async (file: File): Promise<ReadFileType> => {
  const readFile = (f: File): Promise<string> => {
    const fr = new FileReader();

    return new Promise((resolve, reject) => {
      fr.onerror = () => {
        fr.abort();
        reject(new Error('Problem parsing input file.'));
      };

      fr.onload = () => {
        resolve(fr.result?.toString() ?? '');
      };
      fr.readAsText(f);
    });
  };

  const contents = await readFile(file);
  let imports;
  try {
    imports = pb
      .parse(contents)
      .imports?.filter((i) => {
        // remove WellKnown-Types
        return !i.includes('google/protobuf');
      })
      .map((i) => {
        const directory = path.dirname(i);
        const filename = path.basename(i);
        return { directory, filename, imports: [] };
      });
  } catch (err) {
    console.log(err);
  }
  return [contents, imports ?? []];
};

export const readTlsFile = async (file: File): Promise<string> => {
  const readFile = (f: File): Promise<string> => {
    const fr = new FileReader();

    return new Promise((resolve, reject) => {
      fr.onerror = () => {
        fr.abort();
        reject(new Error('Problem parsing input file.'));
      };

      fr.onload = () => {
        resolve(fr.result?.toString() ?? '');
      };
      fr.readAsText(f);
    });
  };

  return readFile(file);
};

export const isNonPartial = <T extends Record<string, any>>(input: T): input is Required<T> => {
  return Object.values(input).every((v) => v !== undefined && v !== null);
};

type KnownKeys<T> = {
  [K in keyof T]: string extends K ? never : number extends K ? never : K;
} extends { [_ in keyof T]: infer U }
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {} extends U
    ? never
    : U
  : never;

export const typedKeys = <T extends Record<string, any>>(input: T): KnownKeys<T>[] => {
  return Object.keys(input) as KnownKeys<T>[];
};

const getGrpcInstance = (
  state: WritableDraft<GrpcState> | GrpcState,
  wsId: string,
  selectedRpcInstance: SelectedRpcPayloadType,
): RpcParamsType | undefined => {
  const { service, rpc, instance } = selectedRpcInstance;
  return state[wsId]?.[service]?.[rpc]?.instances.find((i) => i.instanceMetadata.name === instance);
};

const getHttpInstance = (
  state: WritableDraft<HttpState> | HttpState,
  wsId: string,
  selectedMessageInstance: SelectedHttpMessageItemType,
): HttpParamsType | undefined => {
  const [packageName, messageName, instanceName] = selectedMessageInstance;
  return state[wsId]?.[packageName]?.[messageName]?.instances.find(
    ({ instanceMetadata: { name } }) => name === instanceName,
  );
};

const messageInstanceNameValidator = (
  newMessageInstanceName: string,
  currentMessageInstanceNames: string[],
): o.Option<string> => {
  const alphanumeric = /^[a-z0-9]+$/i;
  const result = alphanumeric.test(newMessageInstanceName);
  if (!result) {
    return o.some('Alphanumeric chars only!');
  }

  const nameConflict = currentMessageInstanceNames.find((name) => name === newMessageInstanceName);
  if (nameConflict) {
    return o.some('Name must be unique!');
  }
  return o.none;
};

export { getGrpcInstance, getHttpInstance, messageInstanceNameValidator };
