// source: request.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_rpc_status_pb = require('./google/rpc/status_pb.js');
goog.object.extend(proto, google_rpc_status_pb);
goog.exportSymbol('proto.Protocall.BidiStreamRequestWrapper', null, global);
goog.exportSymbol('proto.Protocall.BidiStreamRequestWrapper.ActionCase', null, global);
goog.exportSymbol('proto.Protocall.CancelRequest', null, global);
goog.exportSymbol('proto.Protocall.CertWrapper', null, global);
goog.exportSymbol('proto.Protocall.ClientStreamRequestWrapper', null, global);
goog.exportSymbol('proto.Protocall.ClientStreamRequestWrapper.ActionCase', null, global);
goog.exportSymbol('proto.Protocall.GrpcErrorResponse', null, global);
goog.exportSymbol('proto.Protocall.GrpcRequestOptions', null, global);
goog.exportSymbol('proto.Protocall.GrpcRequestWrapper', null, global);
goog.exportSymbol('proto.Protocall.GrpcRequestWrapper.RequestCase', null, global);
goog.exportSymbol('proto.Protocall.GrpcResponse', null, global);
goog.exportSymbol('proto.Protocall.GrpcResponse.ResponseCase', null, global);
goog.exportSymbol('proto.Protocall.GrpcResponseWrapper', null, global);
goog.exportSymbol('proto.Protocall.GrpcSuccessResponse', null, global);
goog.exportSymbol('proto.Protocall.HttpErrorResponse', null, global);
goog.exportSymbol('proto.Protocall.HttpJsonResponse', null, global);
goog.exportSymbol('proto.Protocall.HttpProtoResponse', null, global);
goog.exportSymbol('proto.Protocall.HttpRequest', null, global);
goog.exportSymbol('proto.Protocall.HttpResponse', null, global);
goog.exportSymbol('proto.Protocall.HttpResponse.ResponseCase', null, global);
goog.exportSymbol('proto.Protocall.HttpSuccessResponse', null, global);
goog.exportSymbol('proto.Protocall.HttpSuccessResponse.ContentCase', null, global);
goog.exportSymbol('proto.Protocall.HttpTextResponse', null, global);
goog.exportSymbol('proto.Protocall.HttpUnknownResponse', null, global);
goog.exportSymbol('proto.Protocall.OpenChannel', null, global);
goog.exportSymbol('proto.Protocall.PollingRequestWrapper', null, global);
goog.exportSymbol('proto.Protocall.ServerStreamRequestWrapper', null, global);
goog.exportSymbol('proto.Protocall.ServerStreamRequestWrapper.ActionCase', null, global);
goog.exportSymbol('proto.Protocall.UnaryRequestWrapper', null, global);
goog.exportSymbol('proto.Protocall.UnaryRequestWrapper.ActionCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.HttpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Protocall.HttpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.HttpRequest.displayName = 'proto.Protocall.HttpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.HttpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Protocall.HttpResponse.oneofGroups_);
};
goog.inherits(proto.Protocall.HttpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.HttpResponse.displayName = 'proto.Protocall.HttpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.HttpSuccessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Protocall.HttpSuccessResponse.oneofGroups_);
};
goog.inherits(proto.Protocall.HttpSuccessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.HttpSuccessResponse.displayName = 'proto.Protocall.HttpSuccessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.HttpErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Protocall.HttpErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.HttpErrorResponse.displayName = 'proto.Protocall.HttpErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.HttpProtoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Protocall.HttpProtoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.HttpProtoResponse.displayName = 'proto.Protocall.HttpProtoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.HttpJsonResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Protocall.HttpJsonResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.HttpJsonResponse.displayName = 'proto.Protocall.HttpJsonResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.HttpTextResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Protocall.HttpTextResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.HttpTextResponse.displayName = 'proto.Protocall.HttpTextResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.HttpUnknownResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Protocall.HttpUnknownResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.HttpUnknownResponse.displayName = 'proto.Protocall.HttpUnknownResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.GrpcRequestOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Protocall.GrpcRequestOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.GrpcRequestOptions.displayName = 'proto.Protocall.GrpcRequestOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.CertWrapper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Protocall.CertWrapper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.CertWrapper.displayName = 'proto.Protocall.CertWrapper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.GrpcRequestWrapper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Protocall.GrpcRequestWrapper.oneofGroups_);
};
goog.inherits(proto.Protocall.GrpcRequestWrapper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.GrpcRequestWrapper.displayName = 'proto.Protocall.GrpcRequestWrapper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.GrpcSuccessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Protocall.GrpcSuccessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.GrpcSuccessResponse.displayName = 'proto.Protocall.GrpcSuccessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.GrpcErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Protocall.GrpcErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.GrpcErrorResponse.displayName = 'proto.Protocall.GrpcErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.GrpcResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Protocall.GrpcResponse.oneofGroups_);
};
goog.inherits(proto.Protocall.GrpcResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.GrpcResponse.displayName = 'proto.Protocall.GrpcResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.OpenChannel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Protocall.OpenChannel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.OpenChannel.displayName = 'proto.Protocall.OpenChannel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.GrpcResponseWrapper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Protocall.GrpcResponseWrapper.repeatedFields_, null);
};
goog.inherits(proto.Protocall.GrpcResponseWrapper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.GrpcResponseWrapper.displayName = 'proto.Protocall.GrpcResponseWrapper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.CancelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Protocall.CancelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.CancelRequest.displayName = 'proto.Protocall.CancelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.UnaryRequestWrapper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Protocall.UnaryRequestWrapper.oneofGroups_);
};
goog.inherits(proto.Protocall.UnaryRequestWrapper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.UnaryRequestWrapper.displayName = 'proto.Protocall.UnaryRequestWrapper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.ClientStreamRequestWrapper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Protocall.ClientStreamRequestWrapper.oneofGroups_);
};
goog.inherits(proto.Protocall.ClientStreamRequestWrapper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.ClientStreamRequestWrapper.displayName = 'proto.Protocall.ClientStreamRequestWrapper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.ServerStreamRequestWrapper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Protocall.ServerStreamRequestWrapper.oneofGroups_);
};
goog.inherits(proto.Protocall.ServerStreamRequestWrapper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.ServerStreamRequestWrapper.displayName = 'proto.Protocall.ServerStreamRequestWrapper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.BidiStreamRequestWrapper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Protocall.BidiStreamRequestWrapper.oneofGroups_);
};
goog.inherits(proto.Protocall.BidiStreamRequestWrapper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.BidiStreamRequestWrapper.displayName = 'proto.Protocall.BidiStreamRequestWrapper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Protocall.PollingRequestWrapper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Protocall.PollingRequestWrapper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Protocall.PollingRequestWrapper.displayName = 'proto.Protocall.PollingRequestWrapper';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.HttpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.HttpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.HttpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.HttpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    method: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uri: jspb.Message.getFieldWithDefault(msg, 2, ""),
    headersMap: (f = msg.getHeadersMap()) ? f.toObject(includeInstance, undefined) : [],
    timeout: jspb.Message.getFieldWithDefault(msg, 4, 0),
    payload: msg.getPayload_asB64(),
    sessionToken: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.HttpRequest}
 */
proto.Protocall.HttpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.HttpRequest;
  return proto.Protocall.HttpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.HttpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.HttpRequest}
 */
proto.Protocall.HttpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethod(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUri(value);
      break;
    case 3:
      var value = msg.getHeadersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimeout(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPayload(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.HttpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.HttpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.HttpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.HttpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMethod();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHeadersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getTimeout();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPayload_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = message.getSessionToken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string method = 1;
 * @return {string}
 */
proto.Protocall.HttpRequest.prototype.getMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.HttpRequest} returns this
 */
proto.Protocall.HttpRequest.prototype.setMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string uri = 2;
 * @return {string}
 */
proto.Protocall.HttpRequest.prototype.getUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.HttpRequest} returns this
 */
proto.Protocall.HttpRequest.prototype.setUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * map<string, string> headers = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.Protocall.HttpRequest.prototype.getHeadersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Protocall.HttpRequest} returns this
 */
proto.Protocall.HttpRequest.prototype.clearHeadersMap = function() {
  this.getHeadersMap().clear();
  return this;};


/**
 * optional int32 timeout = 4;
 * @return {number}
 */
proto.Protocall.HttpRequest.prototype.getTimeout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.Protocall.HttpRequest} returns this
 */
proto.Protocall.HttpRequest.prototype.setTimeout = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bytes payload = 5;
 * @return {!(string|Uint8Array)}
 */
proto.Protocall.HttpRequest.prototype.getPayload = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes payload = 5;
 * This is a type-conversion wrapper around `getPayload()`
 * @return {string}
 */
proto.Protocall.HttpRequest.prototype.getPayload_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPayload()));
};


/**
 * optional bytes payload = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPayload()`
 * @return {!Uint8Array}
 */
proto.Protocall.HttpRequest.prototype.getPayload_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPayload()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.Protocall.HttpRequest} returns this
 */
proto.Protocall.HttpRequest.prototype.setPayload = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};


/**
 * optional string session_token = 6;
 * @return {string}
 */
proto.Protocall.HttpRequest.prototype.getSessionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.HttpRequest} returns this
 */
proto.Protocall.HttpRequest.prototype.setSessionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Protocall.HttpResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.Protocall.HttpResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  SUCCESS_RESPONSE: 1,
  ERROR_RESPONSE: 2
};

/**
 * @return {proto.Protocall.HttpResponse.ResponseCase}
 */
proto.Protocall.HttpResponse.prototype.getResponseCase = function() {
  return /** @type {proto.Protocall.HttpResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.Protocall.HttpResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.HttpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.HttpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.HttpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.HttpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    successResponse: (f = msg.getSuccessResponse()) && proto.Protocall.HttpSuccessResponse.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.Protocall.HttpErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.HttpResponse}
 */
proto.Protocall.HttpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.HttpResponse;
  return proto.Protocall.HttpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.HttpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.HttpResponse}
 */
proto.Protocall.HttpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Protocall.HttpSuccessResponse;
      reader.readMessage(value,proto.Protocall.HttpSuccessResponse.deserializeBinaryFromReader);
      msg.setSuccessResponse(value);
      break;
    case 2:
      var value = new proto.Protocall.HttpErrorResponse;
      reader.readMessage(value,proto.Protocall.HttpErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.HttpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.HttpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.HttpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.HttpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccessResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Protocall.HttpSuccessResponse.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Protocall.HttpErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional HttpSuccessResponse success_response = 1;
 * @return {?proto.Protocall.HttpSuccessResponse}
 */
proto.Protocall.HttpResponse.prototype.getSuccessResponse = function() {
  return /** @type{?proto.Protocall.HttpSuccessResponse} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.HttpSuccessResponse, 1));
};


/**
 * @param {?proto.Protocall.HttpSuccessResponse|undefined} value
 * @return {!proto.Protocall.HttpResponse} returns this
*/
proto.Protocall.HttpResponse.prototype.setSuccessResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.Protocall.HttpResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.HttpResponse} returns this
 */
proto.Protocall.HttpResponse.prototype.clearSuccessResponse = function() {
  return this.setSuccessResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.HttpResponse.prototype.hasSuccessResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional HttpErrorResponse error_response = 2;
 * @return {?proto.Protocall.HttpErrorResponse}
 */
proto.Protocall.HttpResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.Protocall.HttpErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.HttpErrorResponse, 2));
};


/**
 * @param {?proto.Protocall.HttpErrorResponse|undefined} value
 * @return {!proto.Protocall.HttpResponse} returns this
*/
proto.Protocall.HttpResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.Protocall.HttpResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.HttpResponse} returns this
 */
proto.Protocall.HttpResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.HttpResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Protocall.HttpSuccessResponse.oneofGroups_ = [[3,4,5,6]];

/**
 * @enum {number}
 */
proto.Protocall.HttpSuccessResponse.ContentCase = {
  CONTENT_NOT_SET: 0,
  PROTO_RESPONSE: 3,
  JSON_RESPONSE: 4,
  TEXT_RESPONSE: 5,
  UNKNOWN_RESPONSE: 6
};

/**
 * @return {proto.Protocall.HttpSuccessResponse.ContentCase}
 */
proto.Protocall.HttpSuccessResponse.prototype.getContentCase = function() {
  return /** @type {proto.Protocall.HttpSuccessResponse.ContentCase} */(jspb.Message.computeOneofCase(this, proto.Protocall.HttpSuccessResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.HttpSuccessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.HttpSuccessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.HttpSuccessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.HttpSuccessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    responseHeadersMap: (f = msg.getResponseHeadersMap()) ? f.toObject(includeInstance, undefined) : [],
    protoResponse: (f = msg.getProtoResponse()) && proto.Protocall.HttpProtoResponse.toObject(includeInstance, f),
    jsonResponse: (f = msg.getJsonResponse()) && proto.Protocall.HttpJsonResponse.toObject(includeInstance, f),
    textResponse: (f = msg.getTextResponse()) && proto.Protocall.HttpTextResponse.toObject(includeInstance, f),
    unknownResponse: (f = msg.getUnknownResponse()) && proto.Protocall.HttpUnknownResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.HttpSuccessResponse}
 */
proto.Protocall.HttpSuccessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.HttpSuccessResponse;
  return proto.Protocall.HttpSuccessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.HttpSuccessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.HttpSuccessResponse}
 */
proto.Protocall.HttpSuccessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatusCode(value);
      break;
    case 2:
      var value = msg.getResponseHeadersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 3:
      var value = new proto.Protocall.HttpProtoResponse;
      reader.readMessage(value,proto.Protocall.HttpProtoResponse.deserializeBinaryFromReader);
      msg.setProtoResponse(value);
      break;
    case 4:
      var value = new proto.Protocall.HttpJsonResponse;
      reader.readMessage(value,proto.Protocall.HttpJsonResponse.deserializeBinaryFromReader);
      msg.setJsonResponse(value);
      break;
    case 5:
      var value = new proto.Protocall.HttpTextResponse;
      reader.readMessage(value,proto.Protocall.HttpTextResponse.deserializeBinaryFromReader);
      msg.setTextResponse(value);
      break;
    case 6:
      var value = new proto.Protocall.HttpUnknownResponse;
      reader.readMessage(value,proto.Protocall.HttpUnknownResponse.deserializeBinaryFromReader);
      msg.setUnknownResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.HttpSuccessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.HttpSuccessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.HttpSuccessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.HttpSuccessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getResponseHeadersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getProtoResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Protocall.HttpProtoResponse.serializeBinaryToWriter
    );
  }
  f = message.getJsonResponse();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Protocall.HttpJsonResponse.serializeBinaryToWriter
    );
  }
  f = message.getTextResponse();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Protocall.HttpTextResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnknownResponse();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.Protocall.HttpUnknownResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 status_code = 1;
 * @return {number}
 */
proto.Protocall.HttpSuccessResponse.prototype.getStatusCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Protocall.HttpSuccessResponse} returns this
 */
proto.Protocall.HttpSuccessResponse.prototype.setStatusCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * map<string, string> response_headers = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.Protocall.HttpSuccessResponse.prototype.getResponseHeadersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Protocall.HttpSuccessResponse} returns this
 */
proto.Protocall.HttpSuccessResponse.prototype.clearResponseHeadersMap = function() {
  this.getResponseHeadersMap().clear();
  return this;};


/**
 * optional HttpProtoResponse proto_response = 3;
 * @return {?proto.Protocall.HttpProtoResponse}
 */
proto.Protocall.HttpSuccessResponse.prototype.getProtoResponse = function() {
  return /** @type{?proto.Protocall.HttpProtoResponse} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.HttpProtoResponse, 3));
};


/**
 * @param {?proto.Protocall.HttpProtoResponse|undefined} value
 * @return {!proto.Protocall.HttpSuccessResponse} returns this
*/
proto.Protocall.HttpSuccessResponse.prototype.setProtoResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.Protocall.HttpSuccessResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.HttpSuccessResponse} returns this
 */
proto.Protocall.HttpSuccessResponse.prototype.clearProtoResponse = function() {
  return this.setProtoResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.HttpSuccessResponse.prototype.hasProtoResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional HttpJsonResponse json_response = 4;
 * @return {?proto.Protocall.HttpJsonResponse}
 */
proto.Protocall.HttpSuccessResponse.prototype.getJsonResponse = function() {
  return /** @type{?proto.Protocall.HttpJsonResponse} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.HttpJsonResponse, 4));
};


/**
 * @param {?proto.Protocall.HttpJsonResponse|undefined} value
 * @return {!proto.Protocall.HttpSuccessResponse} returns this
*/
proto.Protocall.HttpSuccessResponse.prototype.setJsonResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.Protocall.HttpSuccessResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.HttpSuccessResponse} returns this
 */
proto.Protocall.HttpSuccessResponse.prototype.clearJsonResponse = function() {
  return this.setJsonResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.HttpSuccessResponse.prototype.hasJsonResponse = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional HttpTextResponse text_response = 5;
 * @return {?proto.Protocall.HttpTextResponse}
 */
proto.Protocall.HttpSuccessResponse.prototype.getTextResponse = function() {
  return /** @type{?proto.Protocall.HttpTextResponse} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.HttpTextResponse, 5));
};


/**
 * @param {?proto.Protocall.HttpTextResponse|undefined} value
 * @return {!proto.Protocall.HttpSuccessResponse} returns this
*/
proto.Protocall.HttpSuccessResponse.prototype.setTextResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.Protocall.HttpSuccessResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.HttpSuccessResponse} returns this
 */
proto.Protocall.HttpSuccessResponse.prototype.clearTextResponse = function() {
  return this.setTextResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.HttpSuccessResponse.prototype.hasTextResponse = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional HttpUnknownResponse unknown_response = 6;
 * @return {?proto.Protocall.HttpUnknownResponse}
 */
proto.Protocall.HttpSuccessResponse.prototype.getUnknownResponse = function() {
  return /** @type{?proto.Protocall.HttpUnknownResponse} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.HttpUnknownResponse, 6));
};


/**
 * @param {?proto.Protocall.HttpUnknownResponse|undefined} value
 * @return {!proto.Protocall.HttpSuccessResponse} returns this
*/
proto.Protocall.HttpSuccessResponse.prototype.setUnknownResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.Protocall.HttpSuccessResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.HttpSuccessResponse} returns this
 */
proto.Protocall.HttpSuccessResponse.prototype.clearUnknownResponse = function() {
  return this.setUnknownResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.HttpSuccessResponse.prototype.hasUnknownResponse = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.HttpErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.HttpErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.HttpErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.HttpErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.HttpErrorResponse}
 */
proto.Protocall.HttpErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.HttpErrorResponse;
  return proto.Protocall.HttpErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.HttpErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.HttpErrorResponse}
 */
proto.Protocall.HttpErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.HttpErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.HttpErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.HttpErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.HttpErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.Protocall.HttpErrorResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.HttpErrorResponse} returns this
 */
proto.Protocall.HttpErrorResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.HttpProtoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.HttpProtoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.HttpProtoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.HttpProtoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    payload: msg.getPayload_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.HttpProtoResponse}
 */
proto.Protocall.HttpProtoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.HttpProtoResponse;
  return proto.Protocall.HttpProtoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.HttpProtoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.HttpProtoResponse}
 */
proto.Protocall.HttpProtoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPayload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.HttpProtoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.HttpProtoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.HttpProtoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.HttpProtoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayload_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes payload = 1;
 * @return {!(string|Uint8Array)}
 */
proto.Protocall.HttpProtoResponse.prototype.getPayload = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes payload = 1;
 * This is a type-conversion wrapper around `getPayload()`
 * @return {string}
 */
proto.Protocall.HttpProtoResponse.prototype.getPayload_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPayload()));
};


/**
 * optional bytes payload = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPayload()`
 * @return {!Uint8Array}
 */
proto.Protocall.HttpProtoResponse.prototype.getPayload_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPayload()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.Protocall.HttpProtoResponse} returns this
 */
proto.Protocall.HttpProtoResponse.prototype.setPayload = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.HttpJsonResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.HttpJsonResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.HttpJsonResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.HttpJsonResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    payload: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.HttpJsonResponse}
 */
proto.Protocall.HttpJsonResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.HttpJsonResponse;
  return proto.Protocall.HttpJsonResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.HttpJsonResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.HttpJsonResponse}
 */
proto.Protocall.HttpJsonResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.HttpJsonResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.HttpJsonResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.HttpJsonResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.HttpJsonResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string payload = 1;
 * @return {string}
 */
proto.Protocall.HttpJsonResponse.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.HttpJsonResponse} returns this
 */
proto.Protocall.HttpJsonResponse.prototype.setPayload = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.HttpTextResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.HttpTextResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.HttpTextResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.HttpTextResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    payload: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.HttpTextResponse}
 */
proto.Protocall.HttpTextResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.HttpTextResponse;
  return proto.Protocall.HttpTextResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.HttpTextResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.HttpTextResponse}
 */
proto.Protocall.HttpTextResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.HttpTextResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.HttpTextResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.HttpTextResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.HttpTextResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string payload = 1;
 * @return {string}
 */
proto.Protocall.HttpTextResponse.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.HttpTextResponse} returns this
 */
proto.Protocall.HttpTextResponse.prototype.setPayload = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.HttpUnknownResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.HttpUnknownResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.HttpUnknownResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.HttpUnknownResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    payload: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.HttpUnknownResponse}
 */
proto.Protocall.HttpUnknownResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.HttpUnknownResponse;
  return proto.Protocall.HttpUnknownResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.HttpUnknownResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.HttpUnknownResponse}
 */
proto.Protocall.HttpUnknownResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.HttpUnknownResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.HttpUnknownResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.HttpUnknownResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.HttpUnknownResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string payload = 1;
 * @return {string}
 */
proto.Protocall.HttpUnknownResponse.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.HttpUnknownResponse} returns this
 */
proto.Protocall.HttpUnknownResponse.prototype.setPayload = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.GrpcRequestOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.GrpcRequestOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.GrpcRequestOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.GrpcRequestOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    uri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : [],
    deadline: jspb.Message.getFieldWithDefault(msg, 3, 0),
    payload: msg.getPayload_asB64(),
    serviceName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    methodName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    instanceName: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.GrpcRequestOptions}
 */
proto.Protocall.GrpcRequestOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.GrpcRequestOptions;
  return proto.Protocall.GrpcRequestOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.GrpcRequestOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.GrpcRequestOptions}
 */
proto.Protocall.GrpcRequestOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUri(value);
      break;
    case 2:
      var value = msg.getMetadataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeadline(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPayload(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethodName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.GrpcRequestOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.GrpcRequestOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.GrpcRequestOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.GrpcRequestOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getDeadline();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPayload_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMethodName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getInstanceName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string uri = 1;
 * @return {string}
 */
proto.Protocall.GrpcRequestOptions.prototype.getUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.GrpcRequestOptions} returns this
 */
proto.Protocall.GrpcRequestOptions.prototype.setUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, string> metadata = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.Protocall.GrpcRequestOptions.prototype.getMetadataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Protocall.GrpcRequestOptions} returns this
 */
proto.Protocall.GrpcRequestOptions.prototype.clearMetadataMap = function() {
  this.getMetadataMap().clear();
  return this;};


/**
 * optional int32 deadline = 3;
 * @return {number}
 */
proto.Protocall.GrpcRequestOptions.prototype.getDeadline = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.Protocall.GrpcRequestOptions} returns this
 */
proto.Protocall.GrpcRequestOptions.prototype.setDeadline = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bytes payload = 4;
 * @return {!(string|Uint8Array)}
 */
proto.Protocall.GrpcRequestOptions.prototype.getPayload = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes payload = 4;
 * This is a type-conversion wrapper around `getPayload()`
 * @return {string}
 */
proto.Protocall.GrpcRequestOptions.prototype.getPayload_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPayload()));
};


/**
 * optional bytes payload = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPayload()`
 * @return {!Uint8Array}
 */
proto.Protocall.GrpcRequestOptions.prototype.getPayload_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPayload()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.Protocall.GrpcRequestOptions} returns this
 */
proto.Protocall.GrpcRequestOptions.prototype.setPayload = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional string service_name = 5;
 * @return {string}
 */
proto.Protocall.GrpcRequestOptions.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.GrpcRequestOptions} returns this
 */
proto.Protocall.GrpcRequestOptions.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string method_name = 6;
 * @return {string}
 */
proto.Protocall.GrpcRequestOptions.prototype.getMethodName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.GrpcRequestOptions} returns this
 */
proto.Protocall.GrpcRequestOptions.prototype.setMethodName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string workspace_id = 7;
 * @return {string}
 */
proto.Protocall.GrpcRequestOptions.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.GrpcRequestOptions} returns this
 */
proto.Protocall.GrpcRequestOptions.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string instance_name = 8;
 * @return {string}
 */
proto.Protocall.GrpcRequestOptions.prototype.getInstanceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.GrpcRequestOptions} returns this
 */
proto.Protocall.GrpcRequestOptions.prototype.setInstanceName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.CertWrapper.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.CertWrapper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.CertWrapper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.CertWrapper.toObject = function(includeInstance, msg) {
  var f, obj = {
    caCert: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    clientCert: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.CertWrapper}
 */
proto.Protocall.CertWrapper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.CertWrapper;
  return proto.Protocall.CertWrapper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.CertWrapper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.CertWrapper}
 */
proto.Protocall.CertWrapper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaCert(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientCert(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.CertWrapper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.CertWrapper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.CertWrapper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.CertWrapper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCaCert();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClientCert();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string ca_cert = 1;
 * @return {string}
 */
proto.Protocall.CertWrapper.prototype.getCaCert = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.CertWrapper} returns this
 */
proto.Protocall.CertWrapper.prototype.setCaCert = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_key = 2;
 * @return {string}
 */
proto.Protocall.CertWrapper.prototype.getClientKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.CertWrapper} returns this
 */
proto.Protocall.CertWrapper.prototype.setClientKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string client_cert = 3;
 * @return {string}
 */
proto.Protocall.CertWrapper.prototype.getClientCert = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.CertWrapper} returns this
 */
proto.Protocall.CertWrapper.prototype.setClientCert = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Protocall.GrpcRequestWrapper.oneofGroups_ = [[1,2,3,4,6]];

/**
 * @enum {number}
 */
proto.Protocall.GrpcRequestWrapper.RequestCase = {
  REQUEST_NOT_SET: 0,
  UNARY: 1,
  CLIENT_STREAM: 2,
  SERVER_STREAM: 3,
  BIDI_STREAM: 4,
  POLL: 6
};

/**
 * @return {proto.Protocall.GrpcRequestWrapper.RequestCase}
 */
proto.Protocall.GrpcRequestWrapper.prototype.getRequestCase = function() {
  return /** @type {proto.Protocall.GrpcRequestWrapper.RequestCase} */(jspb.Message.computeOneofCase(this, proto.Protocall.GrpcRequestWrapper.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.GrpcRequestWrapper.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.GrpcRequestWrapper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.GrpcRequestWrapper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.GrpcRequestWrapper.toObject = function(includeInstance, msg) {
  var f, obj = {
    unary: (f = msg.getUnary()) && proto.Protocall.UnaryRequestWrapper.toObject(includeInstance, f),
    clientStream: (f = msg.getClientStream()) && proto.Protocall.ClientStreamRequestWrapper.toObject(includeInstance, f),
    serverStream: (f = msg.getServerStream()) && proto.Protocall.ServerStreamRequestWrapper.toObject(includeInstance, f),
    bidiStream: (f = msg.getBidiStream()) && proto.Protocall.BidiStreamRequestWrapper.toObject(includeInstance, f),
    poll: (f = msg.getPoll()) && proto.Protocall.PollingRequestWrapper.toObject(includeInstance, f),
    sessionToken: jspb.Message.getFieldWithDefault(msg, 5, ""),
    certs: (f = msg.getCerts()) && proto.Protocall.CertWrapper.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.GrpcRequestWrapper}
 */
proto.Protocall.GrpcRequestWrapper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.GrpcRequestWrapper;
  return proto.Protocall.GrpcRequestWrapper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.GrpcRequestWrapper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.GrpcRequestWrapper}
 */
proto.Protocall.GrpcRequestWrapper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Protocall.UnaryRequestWrapper;
      reader.readMessage(value,proto.Protocall.UnaryRequestWrapper.deserializeBinaryFromReader);
      msg.setUnary(value);
      break;
    case 2:
      var value = new proto.Protocall.ClientStreamRequestWrapper;
      reader.readMessage(value,proto.Protocall.ClientStreamRequestWrapper.deserializeBinaryFromReader);
      msg.setClientStream(value);
      break;
    case 3:
      var value = new proto.Protocall.ServerStreamRequestWrapper;
      reader.readMessage(value,proto.Protocall.ServerStreamRequestWrapper.deserializeBinaryFromReader);
      msg.setServerStream(value);
      break;
    case 4:
      var value = new proto.Protocall.BidiStreamRequestWrapper;
      reader.readMessage(value,proto.Protocall.BidiStreamRequestWrapper.deserializeBinaryFromReader);
      msg.setBidiStream(value);
      break;
    case 6:
      var value = new proto.Protocall.PollingRequestWrapper;
      reader.readMessage(value,proto.Protocall.PollingRequestWrapper.deserializeBinaryFromReader);
      msg.setPoll(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionToken(value);
      break;
    case 7:
      var value = new proto.Protocall.CertWrapper;
      reader.readMessage(value,proto.Protocall.CertWrapper.deserializeBinaryFromReader);
      msg.setCerts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.GrpcRequestWrapper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.GrpcRequestWrapper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.GrpcRequestWrapper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.GrpcRequestWrapper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnary();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Protocall.UnaryRequestWrapper.serializeBinaryToWriter
    );
  }
  f = message.getClientStream();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Protocall.ClientStreamRequestWrapper.serializeBinaryToWriter
    );
  }
  f = message.getServerStream();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Protocall.ServerStreamRequestWrapper.serializeBinaryToWriter
    );
  }
  f = message.getBidiStream();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Protocall.BidiStreamRequestWrapper.serializeBinaryToWriter
    );
  }
  f = message.getPoll();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.Protocall.PollingRequestWrapper.serializeBinaryToWriter
    );
  }
  f = message.getSessionToken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCerts();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.Protocall.CertWrapper.serializeBinaryToWriter
    );
  }
};


/**
 * optional UnaryRequestWrapper unary = 1;
 * @return {?proto.Protocall.UnaryRequestWrapper}
 */
proto.Protocall.GrpcRequestWrapper.prototype.getUnary = function() {
  return /** @type{?proto.Protocall.UnaryRequestWrapper} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.UnaryRequestWrapper, 1));
};


/**
 * @param {?proto.Protocall.UnaryRequestWrapper|undefined} value
 * @return {!proto.Protocall.GrpcRequestWrapper} returns this
*/
proto.Protocall.GrpcRequestWrapper.prototype.setUnary = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.Protocall.GrpcRequestWrapper.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.GrpcRequestWrapper} returns this
 */
proto.Protocall.GrpcRequestWrapper.prototype.clearUnary = function() {
  return this.setUnary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.GrpcRequestWrapper.prototype.hasUnary = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ClientStreamRequestWrapper client_stream = 2;
 * @return {?proto.Protocall.ClientStreamRequestWrapper}
 */
proto.Protocall.GrpcRequestWrapper.prototype.getClientStream = function() {
  return /** @type{?proto.Protocall.ClientStreamRequestWrapper} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.ClientStreamRequestWrapper, 2));
};


/**
 * @param {?proto.Protocall.ClientStreamRequestWrapper|undefined} value
 * @return {!proto.Protocall.GrpcRequestWrapper} returns this
*/
proto.Protocall.GrpcRequestWrapper.prototype.setClientStream = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.Protocall.GrpcRequestWrapper.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.GrpcRequestWrapper} returns this
 */
proto.Protocall.GrpcRequestWrapper.prototype.clearClientStream = function() {
  return this.setClientStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.GrpcRequestWrapper.prototype.hasClientStream = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ServerStreamRequestWrapper server_stream = 3;
 * @return {?proto.Protocall.ServerStreamRequestWrapper}
 */
proto.Protocall.GrpcRequestWrapper.prototype.getServerStream = function() {
  return /** @type{?proto.Protocall.ServerStreamRequestWrapper} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.ServerStreamRequestWrapper, 3));
};


/**
 * @param {?proto.Protocall.ServerStreamRequestWrapper|undefined} value
 * @return {!proto.Protocall.GrpcRequestWrapper} returns this
*/
proto.Protocall.GrpcRequestWrapper.prototype.setServerStream = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.Protocall.GrpcRequestWrapper.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.GrpcRequestWrapper} returns this
 */
proto.Protocall.GrpcRequestWrapper.prototype.clearServerStream = function() {
  return this.setServerStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.GrpcRequestWrapper.prototype.hasServerStream = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional BidiStreamRequestWrapper bidi_stream = 4;
 * @return {?proto.Protocall.BidiStreamRequestWrapper}
 */
proto.Protocall.GrpcRequestWrapper.prototype.getBidiStream = function() {
  return /** @type{?proto.Protocall.BidiStreamRequestWrapper} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.BidiStreamRequestWrapper, 4));
};


/**
 * @param {?proto.Protocall.BidiStreamRequestWrapper|undefined} value
 * @return {!proto.Protocall.GrpcRequestWrapper} returns this
*/
proto.Protocall.GrpcRequestWrapper.prototype.setBidiStream = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.Protocall.GrpcRequestWrapper.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.GrpcRequestWrapper} returns this
 */
proto.Protocall.GrpcRequestWrapper.prototype.clearBidiStream = function() {
  return this.setBidiStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.GrpcRequestWrapper.prototype.hasBidiStream = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PollingRequestWrapper poll = 6;
 * @return {?proto.Protocall.PollingRequestWrapper}
 */
proto.Protocall.GrpcRequestWrapper.prototype.getPoll = function() {
  return /** @type{?proto.Protocall.PollingRequestWrapper} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.PollingRequestWrapper, 6));
};


/**
 * @param {?proto.Protocall.PollingRequestWrapper|undefined} value
 * @return {!proto.Protocall.GrpcRequestWrapper} returns this
*/
proto.Protocall.GrpcRequestWrapper.prototype.setPoll = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.Protocall.GrpcRequestWrapper.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.GrpcRequestWrapper} returns this
 */
proto.Protocall.GrpcRequestWrapper.prototype.clearPoll = function() {
  return this.setPoll(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.GrpcRequestWrapper.prototype.hasPoll = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string session_token = 5;
 * @return {string}
 */
proto.Protocall.GrpcRequestWrapper.prototype.getSessionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.GrpcRequestWrapper} returns this
 */
proto.Protocall.GrpcRequestWrapper.prototype.setSessionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional CertWrapper certs = 7;
 * @return {?proto.Protocall.CertWrapper}
 */
proto.Protocall.GrpcRequestWrapper.prototype.getCerts = function() {
  return /** @type{?proto.Protocall.CertWrapper} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.CertWrapper, 7));
};


/**
 * @param {?proto.Protocall.CertWrapper|undefined} value
 * @return {!proto.Protocall.GrpcRequestWrapper} returns this
*/
proto.Protocall.GrpcRequestWrapper.prototype.setCerts = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.GrpcRequestWrapper} returns this
 */
proto.Protocall.GrpcRequestWrapper.prototype.clearCerts = function() {
  return this.setCerts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.GrpcRequestWrapper.prototype.hasCerts = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.GrpcSuccessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.GrpcSuccessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.GrpcSuccessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.GrpcSuccessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    payload: msg.getPayload_asB64(),
    metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : [],
    status: (f = msg.getStatus()) && google_rpc_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.GrpcSuccessResponse}
 */
proto.Protocall.GrpcSuccessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.GrpcSuccessResponse;
  return proto.Protocall.GrpcSuccessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.GrpcSuccessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.GrpcSuccessResponse}
 */
proto.Protocall.GrpcSuccessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPayload(value);
      break;
    case 2:
      var value = msg.getMetadataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 3:
      var value = new google_rpc_status_pb.Status;
      reader.readMessage(value,google_rpc_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.GrpcSuccessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.GrpcSuccessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.GrpcSuccessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.GrpcSuccessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayload_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_rpc_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes payload = 1;
 * @return {!(string|Uint8Array)}
 */
proto.Protocall.GrpcSuccessResponse.prototype.getPayload = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes payload = 1;
 * This is a type-conversion wrapper around `getPayload()`
 * @return {string}
 */
proto.Protocall.GrpcSuccessResponse.prototype.getPayload_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPayload()));
};


/**
 * optional bytes payload = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPayload()`
 * @return {!Uint8Array}
 */
proto.Protocall.GrpcSuccessResponse.prototype.getPayload_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPayload()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.Protocall.GrpcSuccessResponse} returns this
 */
proto.Protocall.GrpcSuccessResponse.prototype.setPayload = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * map<string, string> metadata = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.Protocall.GrpcSuccessResponse.prototype.getMetadataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Protocall.GrpcSuccessResponse} returns this
 */
proto.Protocall.GrpcSuccessResponse.prototype.clearMetadataMap = function() {
  this.getMetadataMap().clear();
  return this;};


/**
 * optional google.rpc.Status status = 3;
 * @return {?proto.google.rpc.Status}
 */
proto.Protocall.GrpcSuccessResponse.prototype.getStatus = function() {
  return /** @type{?proto.google.rpc.Status} */ (
    jspb.Message.getWrapperField(this, google_rpc_status_pb.Status, 3));
};


/**
 * @param {?proto.google.rpc.Status|undefined} value
 * @return {!proto.Protocall.GrpcSuccessResponse} returns this
*/
proto.Protocall.GrpcSuccessResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.GrpcSuccessResponse} returns this
 */
proto.Protocall.GrpcSuccessResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.GrpcSuccessResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.GrpcErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.GrpcErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.GrpcErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.GrpcErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: (f = msg.getStatus()) && google_rpc_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.GrpcErrorResponse}
 */
proto.Protocall.GrpcErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.GrpcErrorResponse;
  return proto.Protocall.GrpcErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.GrpcErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.GrpcErrorResponse}
 */
proto.Protocall.GrpcErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new google_rpc_status_pb.Status;
      reader.readMessage(value,google_rpc_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.GrpcErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.GrpcErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.GrpcErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.GrpcErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_rpc_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.Protocall.GrpcErrorResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.GrpcErrorResponse} returns this
 */
proto.Protocall.GrpcErrorResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.rpc.Status status = 2;
 * @return {?proto.google.rpc.Status}
 */
proto.Protocall.GrpcErrorResponse.prototype.getStatus = function() {
  return /** @type{?proto.google.rpc.Status} */ (
    jspb.Message.getWrapperField(this, google_rpc_status_pb.Status, 2));
};


/**
 * @param {?proto.google.rpc.Status|undefined} value
 * @return {!proto.Protocall.GrpcErrorResponse} returns this
*/
proto.Protocall.GrpcErrorResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.GrpcErrorResponse} returns this
 */
proto.Protocall.GrpcErrorResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.GrpcErrorResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Protocall.GrpcResponse.oneofGroups_ = [[4,5]];

/**
 * @enum {number}
 */
proto.Protocall.GrpcResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  SUCCESS: 4,
  ERROR: 5
};

/**
 * @return {proto.Protocall.GrpcResponse.ResponseCase}
 */
proto.Protocall.GrpcResponse.prototype.getResponseCase = function() {
  return /** @type {proto.Protocall.GrpcResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.Protocall.GrpcResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.GrpcResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.GrpcResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.GrpcResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.GrpcResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serviceName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    methodName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    success: (f = msg.getSuccess()) && proto.Protocall.GrpcSuccessResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.Protocall.GrpcErrorResponse.toObject(includeInstance, f),
    instanceName: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.GrpcResponse}
 */
proto.Protocall.GrpcResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.GrpcResponse;
  return proto.Protocall.GrpcResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.GrpcResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.GrpcResponse}
 */
proto.Protocall.GrpcResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethodName(value);
      break;
    case 4:
      var value = new proto.Protocall.GrpcSuccessResponse;
      reader.readMessage(value,proto.Protocall.GrpcSuccessResponse.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    case 5:
      var value = new proto.Protocall.GrpcErrorResponse;
      reader.readMessage(value,proto.Protocall.GrpcErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.GrpcResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.GrpcResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.GrpcResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.GrpcResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMethodName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Protocall.GrpcSuccessResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Protocall.GrpcErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getInstanceName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.Protocall.GrpcResponse.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.GrpcResponse} returns this
 */
proto.Protocall.GrpcResponse.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string service_name = 2;
 * @return {string}
 */
proto.Protocall.GrpcResponse.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.GrpcResponse} returns this
 */
proto.Protocall.GrpcResponse.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string method_name = 3;
 * @return {string}
 */
proto.Protocall.GrpcResponse.prototype.getMethodName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.GrpcResponse} returns this
 */
proto.Protocall.GrpcResponse.prototype.setMethodName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional GrpcSuccessResponse success = 4;
 * @return {?proto.Protocall.GrpcSuccessResponse}
 */
proto.Protocall.GrpcResponse.prototype.getSuccess = function() {
  return /** @type{?proto.Protocall.GrpcSuccessResponse} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.GrpcSuccessResponse, 4));
};


/**
 * @param {?proto.Protocall.GrpcSuccessResponse|undefined} value
 * @return {!proto.Protocall.GrpcResponse} returns this
*/
proto.Protocall.GrpcResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.Protocall.GrpcResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.GrpcResponse} returns this
 */
proto.Protocall.GrpcResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.GrpcResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional GrpcErrorResponse error = 5;
 * @return {?proto.Protocall.GrpcErrorResponse}
 */
proto.Protocall.GrpcResponse.prototype.getError = function() {
  return /** @type{?proto.Protocall.GrpcErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.GrpcErrorResponse, 5));
};


/**
 * @param {?proto.Protocall.GrpcErrorResponse|undefined} value
 * @return {!proto.Protocall.GrpcResponse} returns this
*/
proto.Protocall.GrpcResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.Protocall.GrpcResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.GrpcResponse} returns this
 */
proto.Protocall.GrpcResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.GrpcResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string instance_name = 6;
 * @return {string}
 */
proto.Protocall.GrpcResponse.prototype.getInstanceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.GrpcResponse} returns this
 */
proto.Protocall.GrpcResponse.prototype.setInstanceName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.OpenChannel.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.OpenChannel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.OpenChannel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.OpenChannel.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serviceName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    methodName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    instanceName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.OpenChannel}
 */
proto.Protocall.OpenChannel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.OpenChannel;
  return proto.Protocall.OpenChannel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.OpenChannel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.OpenChannel}
 */
proto.Protocall.OpenChannel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethodName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.OpenChannel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.OpenChannel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.OpenChannel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.OpenChannel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMethodName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInstanceName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.Protocall.OpenChannel.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.OpenChannel} returns this
 */
proto.Protocall.OpenChannel.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string service_name = 2;
 * @return {string}
 */
proto.Protocall.OpenChannel.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.OpenChannel} returns this
 */
proto.Protocall.OpenChannel.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string method_name = 3;
 * @return {string}
 */
proto.Protocall.OpenChannel.prototype.getMethodName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.OpenChannel} returns this
 */
proto.Protocall.OpenChannel.prototype.setMethodName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string instance_name = 4;
 * @return {string}
 */
proto.Protocall.OpenChannel.prototype.getInstanceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.OpenChannel} returns this
 */
proto.Protocall.OpenChannel.prototype.setInstanceName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Protocall.GrpcResponseWrapper.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.GrpcResponseWrapper.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.GrpcResponseWrapper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.GrpcResponseWrapper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.GrpcResponseWrapper.toObject = function(includeInstance, msg) {
  var f, obj = {
    responsesList: jspb.Message.toObjectList(msg.getResponsesList(),
    proto.Protocall.GrpcResponse.toObject, includeInstance),
    openChannelsList: jspb.Message.toObjectList(msg.getOpenChannelsList(),
    proto.Protocall.OpenChannel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.GrpcResponseWrapper}
 */
proto.Protocall.GrpcResponseWrapper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.GrpcResponseWrapper;
  return proto.Protocall.GrpcResponseWrapper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.GrpcResponseWrapper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.GrpcResponseWrapper}
 */
proto.Protocall.GrpcResponseWrapper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Protocall.GrpcResponse;
      reader.readMessage(value,proto.Protocall.GrpcResponse.deserializeBinaryFromReader);
      msg.addResponses(value);
      break;
    case 2:
      var value = new proto.Protocall.OpenChannel;
      reader.readMessage(value,proto.Protocall.OpenChannel.deserializeBinaryFromReader);
      msg.addOpenChannels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.GrpcResponseWrapper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.GrpcResponseWrapper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.GrpcResponseWrapper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.GrpcResponseWrapper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Protocall.GrpcResponse.serializeBinaryToWriter
    );
  }
  f = message.getOpenChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.Protocall.OpenChannel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GrpcResponse responses = 1;
 * @return {!Array<!proto.Protocall.GrpcResponse>}
 */
proto.Protocall.GrpcResponseWrapper.prototype.getResponsesList = function() {
  return /** @type{!Array<!proto.Protocall.GrpcResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Protocall.GrpcResponse, 1));
};


/**
 * @param {!Array<!proto.Protocall.GrpcResponse>} value
 * @return {!proto.Protocall.GrpcResponseWrapper} returns this
*/
proto.Protocall.GrpcResponseWrapper.prototype.setResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Protocall.GrpcResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Protocall.GrpcResponse}
 */
proto.Protocall.GrpcResponseWrapper.prototype.addResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Protocall.GrpcResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Protocall.GrpcResponseWrapper} returns this
 */
proto.Protocall.GrpcResponseWrapper.prototype.clearResponsesList = function() {
  return this.setResponsesList([]);
};


/**
 * repeated OpenChannel open_channels = 2;
 * @return {!Array<!proto.Protocall.OpenChannel>}
 */
proto.Protocall.GrpcResponseWrapper.prototype.getOpenChannelsList = function() {
  return /** @type{!Array<!proto.Protocall.OpenChannel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Protocall.OpenChannel, 2));
};


/**
 * @param {!Array<!proto.Protocall.OpenChannel>} value
 * @return {!proto.Protocall.GrpcResponseWrapper} returns this
*/
proto.Protocall.GrpcResponseWrapper.prototype.setOpenChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.Protocall.OpenChannel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Protocall.OpenChannel}
 */
proto.Protocall.GrpcResponseWrapper.prototype.addOpenChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.Protocall.OpenChannel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Protocall.GrpcResponseWrapper} returns this
 */
proto.Protocall.GrpcResponseWrapper.prototype.clearOpenChannelsList = function() {
  return this.setOpenChannelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.CancelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.CancelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.CancelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.CancelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serviceName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    methodName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    instanceName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.CancelRequest}
 */
proto.Protocall.CancelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.CancelRequest;
  return proto.Protocall.CancelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.CancelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.CancelRequest}
 */
proto.Protocall.CancelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethodName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.CancelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.CancelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.CancelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.CancelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMethodName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInstanceName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.Protocall.CancelRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.CancelRequest} returns this
 */
proto.Protocall.CancelRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string service_name = 2;
 * @return {string}
 */
proto.Protocall.CancelRequest.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.CancelRequest} returns this
 */
proto.Protocall.CancelRequest.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string method_name = 3;
 * @return {string}
 */
proto.Protocall.CancelRequest.prototype.getMethodName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.CancelRequest} returns this
 */
proto.Protocall.CancelRequest.prototype.setMethodName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string instance_name = 4;
 * @return {string}
 */
proto.Protocall.CancelRequest.prototype.getInstanceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Protocall.CancelRequest} returns this
 */
proto.Protocall.CancelRequest.prototype.setInstanceName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Protocall.UnaryRequestWrapper.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.Protocall.UnaryRequestWrapper.ActionCase = {
  ACTION_NOT_SET: 0,
  START: 1,
  CANCEL: 2
};

/**
 * @return {proto.Protocall.UnaryRequestWrapper.ActionCase}
 */
proto.Protocall.UnaryRequestWrapper.prototype.getActionCase = function() {
  return /** @type {proto.Protocall.UnaryRequestWrapper.ActionCase} */(jspb.Message.computeOneofCase(this, proto.Protocall.UnaryRequestWrapper.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.UnaryRequestWrapper.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.UnaryRequestWrapper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.UnaryRequestWrapper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.UnaryRequestWrapper.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && proto.Protocall.GrpcRequestOptions.toObject(includeInstance, f),
    cancel: (f = msg.getCancel()) && proto.Protocall.CancelRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.UnaryRequestWrapper}
 */
proto.Protocall.UnaryRequestWrapper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.UnaryRequestWrapper;
  return proto.Protocall.UnaryRequestWrapper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.UnaryRequestWrapper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.UnaryRequestWrapper}
 */
proto.Protocall.UnaryRequestWrapper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Protocall.GrpcRequestOptions;
      reader.readMessage(value,proto.Protocall.GrpcRequestOptions.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 2:
      var value = new proto.Protocall.CancelRequest;
      reader.readMessage(value,proto.Protocall.CancelRequest.deserializeBinaryFromReader);
      msg.setCancel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.UnaryRequestWrapper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.UnaryRequestWrapper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.UnaryRequestWrapper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.UnaryRequestWrapper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Protocall.GrpcRequestOptions.serializeBinaryToWriter
    );
  }
  f = message.getCancel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Protocall.CancelRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional GrpcRequestOptions start = 1;
 * @return {?proto.Protocall.GrpcRequestOptions}
 */
proto.Protocall.UnaryRequestWrapper.prototype.getStart = function() {
  return /** @type{?proto.Protocall.GrpcRequestOptions} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.GrpcRequestOptions, 1));
};


/**
 * @param {?proto.Protocall.GrpcRequestOptions|undefined} value
 * @return {!proto.Protocall.UnaryRequestWrapper} returns this
*/
proto.Protocall.UnaryRequestWrapper.prototype.setStart = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.Protocall.UnaryRequestWrapper.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.UnaryRequestWrapper} returns this
 */
proto.Protocall.UnaryRequestWrapper.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.UnaryRequestWrapper.prototype.hasStart = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CancelRequest cancel = 2;
 * @return {?proto.Protocall.CancelRequest}
 */
proto.Protocall.UnaryRequestWrapper.prototype.getCancel = function() {
  return /** @type{?proto.Protocall.CancelRequest} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.CancelRequest, 2));
};


/**
 * @param {?proto.Protocall.CancelRequest|undefined} value
 * @return {!proto.Protocall.UnaryRequestWrapper} returns this
*/
proto.Protocall.UnaryRequestWrapper.prototype.setCancel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.Protocall.UnaryRequestWrapper.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.UnaryRequestWrapper} returns this
 */
proto.Protocall.UnaryRequestWrapper.prototype.clearCancel = function() {
  return this.setCancel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.UnaryRequestWrapper.prototype.hasCancel = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Protocall.ClientStreamRequestWrapper.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.Protocall.ClientStreamRequestWrapper.ActionCase = {
  ACTION_NOT_SET: 0,
  START: 1,
  PUSH: 2,
  CANCEL: 3,
  END: 4
};

/**
 * @return {proto.Protocall.ClientStreamRequestWrapper.ActionCase}
 */
proto.Protocall.ClientStreamRequestWrapper.prototype.getActionCase = function() {
  return /** @type {proto.Protocall.ClientStreamRequestWrapper.ActionCase} */(jspb.Message.computeOneofCase(this, proto.Protocall.ClientStreamRequestWrapper.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.ClientStreamRequestWrapper.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.ClientStreamRequestWrapper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.ClientStreamRequestWrapper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.ClientStreamRequestWrapper.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && proto.Protocall.GrpcRequestOptions.toObject(includeInstance, f),
    push: (f = msg.getPush()) && proto.Protocall.GrpcRequestOptions.toObject(includeInstance, f),
    cancel: (f = msg.getCancel()) && proto.Protocall.CancelRequest.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && proto.Protocall.CancelRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.ClientStreamRequestWrapper}
 */
proto.Protocall.ClientStreamRequestWrapper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.ClientStreamRequestWrapper;
  return proto.Protocall.ClientStreamRequestWrapper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.ClientStreamRequestWrapper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.ClientStreamRequestWrapper}
 */
proto.Protocall.ClientStreamRequestWrapper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Protocall.GrpcRequestOptions;
      reader.readMessage(value,proto.Protocall.GrpcRequestOptions.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 2:
      var value = new proto.Protocall.GrpcRequestOptions;
      reader.readMessage(value,proto.Protocall.GrpcRequestOptions.deserializeBinaryFromReader);
      msg.setPush(value);
      break;
    case 3:
      var value = new proto.Protocall.CancelRequest;
      reader.readMessage(value,proto.Protocall.CancelRequest.deserializeBinaryFromReader);
      msg.setCancel(value);
      break;
    case 4:
      var value = new proto.Protocall.CancelRequest;
      reader.readMessage(value,proto.Protocall.CancelRequest.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.ClientStreamRequestWrapper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.ClientStreamRequestWrapper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.ClientStreamRequestWrapper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.ClientStreamRequestWrapper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Protocall.GrpcRequestOptions.serializeBinaryToWriter
    );
  }
  f = message.getPush();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Protocall.GrpcRequestOptions.serializeBinaryToWriter
    );
  }
  f = message.getCancel();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Protocall.CancelRequest.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Protocall.CancelRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional GrpcRequestOptions start = 1;
 * @return {?proto.Protocall.GrpcRequestOptions}
 */
proto.Protocall.ClientStreamRequestWrapper.prototype.getStart = function() {
  return /** @type{?proto.Protocall.GrpcRequestOptions} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.GrpcRequestOptions, 1));
};


/**
 * @param {?proto.Protocall.GrpcRequestOptions|undefined} value
 * @return {!proto.Protocall.ClientStreamRequestWrapper} returns this
*/
proto.Protocall.ClientStreamRequestWrapper.prototype.setStart = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.Protocall.ClientStreamRequestWrapper.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.ClientStreamRequestWrapper} returns this
 */
proto.Protocall.ClientStreamRequestWrapper.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.ClientStreamRequestWrapper.prototype.hasStart = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GrpcRequestOptions push = 2;
 * @return {?proto.Protocall.GrpcRequestOptions}
 */
proto.Protocall.ClientStreamRequestWrapper.prototype.getPush = function() {
  return /** @type{?proto.Protocall.GrpcRequestOptions} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.GrpcRequestOptions, 2));
};


/**
 * @param {?proto.Protocall.GrpcRequestOptions|undefined} value
 * @return {!proto.Protocall.ClientStreamRequestWrapper} returns this
*/
proto.Protocall.ClientStreamRequestWrapper.prototype.setPush = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.Protocall.ClientStreamRequestWrapper.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.ClientStreamRequestWrapper} returns this
 */
proto.Protocall.ClientStreamRequestWrapper.prototype.clearPush = function() {
  return this.setPush(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.ClientStreamRequestWrapper.prototype.hasPush = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CancelRequest cancel = 3;
 * @return {?proto.Protocall.CancelRequest}
 */
proto.Protocall.ClientStreamRequestWrapper.prototype.getCancel = function() {
  return /** @type{?proto.Protocall.CancelRequest} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.CancelRequest, 3));
};


/**
 * @param {?proto.Protocall.CancelRequest|undefined} value
 * @return {!proto.Protocall.ClientStreamRequestWrapper} returns this
*/
proto.Protocall.ClientStreamRequestWrapper.prototype.setCancel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.Protocall.ClientStreamRequestWrapper.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.ClientStreamRequestWrapper} returns this
 */
proto.Protocall.ClientStreamRequestWrapper.prototype.clearCancel = function() {
  return this.setCancel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.ClientStreamRequestWrapper.prototype.hasCancel = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CancelRequest end = 4;
 * @return {?proto.Protocall.CancelRequest}
 */
proto.Protocall.ClientStreamRequestWrapper.prototype.getEnd = function() {
  return /** @type{?proto.Protocall.CancelRequest} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.CancelRequest, 4));
};


/**
 * @param {?proto.Protocall.CancelRequest|undefined} value
 * @return {!proto.Protocall.ClientStreamRequestWrapper} returns this
*/
proto.Protocall.ClientStreamRequestWrapper.prototype.setEnd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.Protocall.ClientStreamRequestWrapper.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.ClientStreamRequestWrapper} returns this
 */
proto.Protocall.ClientStreamRequestWrapper.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.ClientStreamRequestWrapper.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Protocall.ServerStreamRequestWrapper.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.Protocall.ServerStreamRequestWrapper.ActionCase = {
  ACTION_NOT_SET: 0,
  START: 1,
  CANCEL: 2
};

/**
 * @return {proto.Protocall.ServerStreamRequestWrapper.ActionCase}
 */
proto.Protocall.ServerStreamRequestWrapper.prototype.getActionCase = function() {
  return /** @type {proto.Protocall.ServerStreamRequestWrapper.ActionCase} */(jspb.Message.computeOneofCase(this, proto.Protocall.ServerStreamRequestWrapper.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.ServerStreamRequestWrapper.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.ServerStreamRequestWrapper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.ServerStreamRequestWrapper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.ServerStreamRequestWrapper.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && proto.Protocall.GrpcRequestOptions.toObject(includeInstance, f),
    cancel: (f = msg.getCancel()) && proto.Protocall.CancelRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.ServerStreamRequestWrapper}
 */
proto.Protocall.ServerStreamRequestWrapper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.ServerStreamRequestWrapper;
  return proto.Protocall.ServerStreamRequestWrapper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.ServerStreamRequestWrapper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.ServerStreamRequestWrapper}
 */
proto.Protocall.ServerStreamRequestWrapper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Protocall.GrpcRequestOptions;
      reader.readMessage(value,proto.Protocall.GrpcRequestOptions.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 2:
      var value = new proto.Protocall.CancelRequest;
      reader.readMessage(value,proto.Protocall.CancelRequest.deserializeBinaryFromReader);
      msg.setCancel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.ServerStreamRequestWrapper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.ServerStreamRequestWrapper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.ServerStreamRequestWrapper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.ServerStreamRequestWrapper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Protocall.GrpcRequestOptions.serializeBinaryToWriter
    );
  }
  f = message.getCancel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Protocall.CancelRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional GrpcRequestOptions start = 1;
 * @return {?proto.Protocall.GrpcRequestOptions}
 */
proto.Protocall.ServerStreamRequestWrapper.prototype.getStart = function() {
  return /** @type{?proto.Protocall.GrpcRequestOptions} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.GrpcRequestOptions, 1));
};


/**
 * @param {?proto.Protocall.GrpcRequestOptions|undefined} value
 * @return {!proto.Protocall.ServerStreamRequestWrapper} returns this
*/
proto.Protocall.ServerStreamRequestWrapper.prototype.setStart = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.Protocall.ServerStreamRequestWrapper.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.ServerStreamRequestWrapper} returns this
 */
proto.Protocall.ServerStreamRequestWrapper.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.ServerStreamRequestWrapper.prototype.hasStart = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CancelRequest cancel = 2;
 * @return {?proto.Protocall.CancelRequest}
 */
proto.Protocall.ServerStreamRequestWrapper.prototype.getCancel = function() {
  return /** @type{?proto.Protocall.CancelRequest} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.CancelRequest, 2));
};


/**
 * @param {?proto.Protocall.CancelRequest|undefined} value
 * @return {!proto.Protocall.ServerStreamRequestWrapper} returns this
*/
proto.Protocall.ServerStreamRequestWrapper.prototype.setCancel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.Protocall.ServerStreamRequestWrapper.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.ServerStreamRequestWrapper} returns this
 */
proto.Protocall.ServerStreamRequestWrapper.prototype.clearCancel = function() {
  return this.setCancel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.ServerStreamRequestWrapper.prototype.hasCancel = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Protocall.BidiStreamRequestWrapper.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.Protocall.BidiStreamRequestWrapper.ActionCase = {
  ACTION_NOT_SET: 0,
  START: 1,
  PUSH: 2,
  CANCEL: 3,
  END: 4
};

/**
 * @return {proto.Protocall.BidiStreamRequestWrapper.ActionCase}
 */
proto.Protocall.BidiStreamRequestWrapper.prototype.getActionCase = function() {
  return /** @type {proto.Protocall.BidiStreamRequestWrapper.ActionCase} */(jspb.Message.computeOneofCase(this, proto.Protocall.BidiStreamRequestWrapper.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.BidiStreamRequestWrapper.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.BidiStreamRequestWrapper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.BidiStreamRequestWrapper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.BidiStreamRequestWrapper.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && proto.Protocall.GrpcRequestOptions.toObject(includeInstance, f),
    push: (f = msg.getPush()) && proto.Protocall.GrpcRequestOptions.toObject(includeInstance, f),
    cancel: (f = msg.getCancel()) && proto.Protocall.CancelRequest.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && proto.Protocall.CancelRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.BidiStreamRequestWrapper}
 */
proto.Protocall.BidiStreamRequestWrapper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.BidiStreamRequestWrapper;
  return proto.Protocall.BidiStreamRequestWrapper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.BidiStreamRequestWrapper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.BidiStreamRequestWrapper}
 */
proto.Protocall.BidiStreamRequestWrapper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Protocall.GrpcRequestOptions;
      reader.readMessage(value,proto.Protocall.GrpcRequestOptions.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 2:
      var value = new proto.Protocall.GrpcRequestOptions;
      reader.readMessage(value,proto.Protocall.GrpcRequestOptions.deserializeBinaryFromReader);
      msg.setPush(value);
      break;
    case 3:
      var value = new proto.Protocall.CancelRequest;
      reader.readMessage(value,proto.Protocall.CancelRequest.deserializeBinaryFromReader);
      msg.setCancel(value);
      break;
    case 4:
      var value = new proto.Protocall.CancelRequest;
      reader.readMessage(value,proto.Protocall.CancelRequest.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.BidiStreamRequestWrapper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.BidiStreamRequestWrapper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.BidiStreamRequestWrapper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.BidiStreamRequestWrapper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Protocall.GrpcRequestOptions.serializeBinaryToWriter
    );
  }
  f = message.getPush();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Protocall.GrpcRequestOptions.serializeBinaryToWriter
    );
  }
  f = message.getCancel();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Protocall.CancelRequest.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Protocall.CancelRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional GrpcRequestOptions start = 1;
 * @return {?proto.Protocall.GrpcRequestOptions}
 */
proto.Protocall.BidiStreamRequestWrapper.prototype.getStart = function() {
  return /** @type{?proto.Protocall.GrpcRequestOptions} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.GrpcRequestOptions, 1));
};


/**
 * @param {?proto.Protocall.GrpcRequestOptions|undefined} value
 * @return {!proto.Protocall.BidiStreamRequestWrapper} returns this
*/
proto.Protocall.BidiStreamRequestWrapper.prototype.setStart = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.Protocall.BidiStreamRequestWrapper.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.BidiStreamRequestWrapper} returns this
 */
proto.Protocall.BidiStreamRequestWrapper.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.BidiStreamRequestWrapper.prototype.hasStart = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GrpcRequestOptions push = 2;
 * @return {?proto.Protocall.GrpcRequestOptions}
 */
proto.Protocall.BidiStreamRequestWrapper.prototype.getPush = function() {
  return /** @type{?proto.Protocall.GrpcRequestOptions} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.GrpcRequestOptions, 2));
};


/**
 * @param {?proto.Protocall.GrpcRequestOptions|undefined} value
 * @return {!proto.Protocall.BidiStreamRequestWrapper} returns this
*/
proto.Protocall.BidiStreamRequestWrapper.prototype.setPush = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.Protocall.BidiStreamRequestWrapper.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.BidiStreamRequestWrapper} returns this
 */
proto.Protocall.BidiStreamRequestWrapper.prototype.clearPush = function() {
  return this.setPush(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.BidiStreamRequestWrapper.prototype.hasPush = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CancelRequest cancel = 3;
 * @return {?proto.Protocall.CancelRequest}
 */
proto.Protocall.BidiStreamRequestWrapper.prototype.getCancel = function() {
  return /** @type{?proto.Protocall.CancelRequest} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.CancelRequest, 3));
};


/**
 * @param {?proto.Protocall.CancelRequest|undefined} value
 * @return {!proto.Protocall.BidiStreamRequestWrapper} returns this
*/
proto.Protocall.BidiStreamRequestWrapper.prototype.setCancel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.Protocall.BidiStreamRequestWrapper.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.BidiStreamRequestWrapper} returns this
 */
proto.Protocall.BidiStreamRequestWrapper.prototype.clearCancel = function() {
  return this.setCancel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.BidiStreamRequestWrapper.prototype.hasCancel = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CancelRequest end = 4;
 * @return {?proto.Protocall.CancelRequest}
 */
proto.Protocall.BidiStreamRequestWrapper.prototype.getEnd = function() {
  return /** @type{?proto.Protocall.CancelRequest} */ (
    jspb.Message.getWrapperField(this, proto.Protocall.CancelRequest, 4));
};


/**
 * @param {?proto.Protocall.CancelRequest|undefined} value
 * @return {!proto.Protocall.BidiStreamRequestWrapper} returns this
*/
proto.Protocall.BidiStreamRequestWrapper.prototype.setEnd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.Protocall.BidiStreamRequestWrapper.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Protocall.BidiStreamRequestWrapper} returns this
 */
proto.Protocall.BidiStreamRequestWrapper.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Protocall.BidiStreamRequestWrapper.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Protocall.PollingRequestWrapper.prototype.toObject = function(opt_includeInstance) {
  return proto.Protocall.PollingRequestWrapper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Protocall.PollingRequestWrapper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.PollingRequestWrapper.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Protocall.PollingRequestWrapper}
 */
proto.Protocall.PollingRequestWrapper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Protocall.PollingRequestWrapper;
  return proto.Protocall.PollingRequestWrapper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Protocall.PollingRequestWrapper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Protocall.PollingRequestWrapper}
 */
proto.Protocall.PollingRequestWrapper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Protocall.PollingRequestWrapper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Protocall.PollingRequestWrapper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Protocall.PollingRequestWrapper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Protocall.PollingRequestWrapper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.Protocall);
