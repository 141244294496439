import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-unresolved
import type { WritableDraft } from 'immer/dist/internal';
import _ from 'lodash';
import { DeleteAndShiftKeysInSelectedMessagesItemPayload } from './selectedMessagesSlice.types';
import {
  DeleteIfSelectOneOfItemPayload,
  DeleteKeysInSelectOneOfItemPayload,
  DeleteSelectOneOfItemPayload,
  initialSelectedOneOfItem,
  ReplaceKeysInSelectOneOfItemPayload,
  SelectedOneOfState,
  SetSelectOneOfItemPayload,
} from './selectedOneOfSlice.types';
import type { CreateWorkspacesResponse } from './workspacesSlice.types';
import { createWorkspace, deleteWorkspace, getWorkspaces, upsertProtos } from '../thunks/workspacesThunk';
import type { ExtendedWorkspace } from '../thunks/types';
import { importGithubWorkspace } from '../thunks/githubThunk';

const initialState: SelectedOneOfState = {};

const hydratedSelectedOneOfWithWorkspaces = (
  state: WritableDraft<SelectedOneOfState>,
  workspaces: ExtendedWorkspace[],
) => {
  workspaces.forEach((w) => {
    const { workspace_id, user_data } = w;
    if (w.hydrated) {
      if (user_data?.selected_oneofs) {
        state[workspace_id] = user_data.selected_oneofs;
      } else {
        state[workspace_id] = w.mainObjects.selectedOneOfObj;
      }
    } else {
      state[workspace_id] = {};
    }
  });
};

const selectedOneOfSlice = createSlice({
  name: 'selectedOneOf',
  initialState,
  reducers: {
    setSelectedOneOf: (state, { payload }: PayloadAction<SelectedOneOfState>) => payload,
    setSelectedOneOfItem: (state, { payload }: PayloadAction<SetSelectOneOfItemPayload>) => {
      const { wsId, value } = payload;
      state[wsId] = { ...state[wsId], ...value };
    },
    deleteSelectedOneOfItem: (state, { payload }: PayloadAction<DeleteSelectOneOfItemPayload>) => {
      const { wsId, value } = payload;
      if (state[wsId][value] !== undefined) {
        delete state[wsId][value];
      }
    },
    deleteIfSelectedOneOfItem: (state, { payload }: PayloadAction<DeleteIfSelectOneOfItemPayload>) => {
      const { wsId, value, selectedId, deleteNested } = payload;
      const item = state[wsId];
      const currentlySelected = item[value];
      if (currentlySelected === selectedId) {
        delete state[wsId][value];
      }
      Object.keys(item).forEach((k) => {
        if (k.startsWith(deleteNested)) {
          delete item[k];
        }
      });
    },
    replaceKeysInSelectedOneOfItem: (state, { payload }: PayloadAction<ReplaceKeysInSelectOneOfItemPayload>) => {
      const { wsId, pathString, currentKey, key } = payload;
      const item = state[wsId];
      Object.entries(item).forEach(([k, v]) => {
        if (k.startsWith(key)) {
          const newKey = `${pathString}:${currentKey}:${k.substr(key.length)}`;
          delete item[k];
          item[newKey] = v;
        }
      });
    },
    deleteKeysInSelectedOneOfItem: (state, { payload }: PayloadAction<DeleteKeysInSelectOneOfItemPayload>) => {
      const { wsId, key } = payload;
      const item = state[wsId];
      Object.keys(item).forEach((k) => {
        if (k.startsWith(key)) {
          delete item[k];
        }
      });
    },
    deleteAndShiftKeysInSelectedOneOfItem: (
      state,
      { payload }: PayloadAction<DeleteAndShiftKeysInSelectedMessagesItemPayload>,
    ) => {
      const { wsId, pathString, index, lastIndex } = payload;
      const item = state[wsId];
      const key4delete = `${pathString}:${index}:`;
      const firstPart = `${pathString}:`;
      Object.entries(item).forEach(([k, v]) => {
        if (k.startsWith(firstPart)) {
          if (k.startsWith(key4delete)) {
            delete item[k];
          } else {
            const tmpStr = k.slice(pathString.length + 1);
            const separatorInd = tmpStr.indexOf(':');
            const tail = tmpStr.slice(separatorInd);
            const i = parseInt(tmpStr.slice(0, separatorInd), 10);
            if (i > index || i < lastIndex) {
              const newKey = `${firstPart}${i - 1}${tail}`;
              item[newKey] = v;
              delete item[k];
            }
          }
        }
      });
    },
    clearSelectedOneOf: () => initialState,
  },
  extraReducers: (builder) => {
    // upsertProtos ********************************************************************************
    builder.addCase(upsertProtos.fulfilled, (state, { payload }) => {
      const { workspace_id } = payload;
      state[workspace_id] = payload.hydrated ? payload.mainObjects.selectedOneOfObj : {};
    });
    // createWorkspace *****************************************************************************
    builder.addCase(createWorkspace.fulfilled, (state, { payload }: PayloadAction<CreateWorkspacesResponse>) => {
      state[payload.workspace_id] = _.cloneDeep(initialSelectedOneOfItem);
    });
    // getWorkspaces *******************************************************************************
    builder.addCase(getWorkspaces.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      hydratedSelectedOneOfWithWorkspaces(state, workspaces);
    });
    builder.addCase(importGithubWorkspace.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      hydratedSelectedOneOfWithWorkspaces(state, workspaces);
    });
    // deleteWorkspace *****************************************************************************
    builder.addCase(deleteWorkspace.fulfilled, (state, { meta }) => {
      delete state[meta.arg];
    });
  },
});

export const {
  setSelectedOneOf,
  setSelectedOneOfItem,
  deleteSelectedOneOfItem,
  deleteIfSelectedOneOfItem,
  replaceKeysInSelectedOneOfItem,
  deleteKeysInSelectedOneOfItem,
  deleteAndShiftKeysInSelectedOneOfItem,
  clearSelectedOneOf,
} = selectedOneOfSlice.actions;

export default selectedOneOfSlice.reducer;
