import { createAsyncThunk } from '@reduxjs/toolkit';
import { GithubRepoImport } from '../../components/Header/Github/types';
import axios from '../axiosConfig/axiosConfig';
import { PostResponse } from '../axiosConfig/axios.types';
import type { Store } from '../store';
import { getExtendedWorkspaces } from './util';
import { GetWorkspacesResponse } from './types';

export const authWithGithub = createAsyncThunk<
  PostResponse<'/github-auth'>,
  void,
  {
    rejectValue: string;
    state: Store;
  }
>('github/authWithGithub', async (_args, { rejectWithValue, getState }) => {
  try {
    const { sessionToken } = getState().auth;
    const response = await axios.post('/github-auth', {
      sessionToken,
    });
    window.location.href = response.data.redirect;
    return response.data;
  } catch (err) {
    if (err.response) {
      return rejectWithValue(err.response.data.message);
    }
    return rejectWithValue(err.message);
  }
});

export const getGithubState = createAsyncThunk<
  PostResponse<'/get-github-state'>,
  void,
  {
    rejectValue: string;
    state: Store;
  }
>('github/getGithubState', async (_args, { rejectWithValue, getState }) => {
  try {
    const { sessionToken } = getState().auth;
    const response = await axios.post('/get-github-state', {
      sessionToken,
    });
    return response.data;
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

export const importGithubWorkspace = createAsyncThunk<
  GetWorkspacesResponse,
  GithubRepoImport,
  {
    rejectValue: string;
    state: Store;
  }
>('github/importGithubWorkspace', async (importRepoOptions, { rejectWithValue, getState }) => {
  try {
    const { sessionToken } = getState().auth;
    const response = await axios.post('/import-github-workspace', {
      sessionToken,
      ...importRepoOptions,
    });
    const extendedWorkspaces = getExtendedWorkspaces(response.data.workspaces);
    return { workspaces: extendedWorkspaces };
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});
