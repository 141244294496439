import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getGithubState } from '../thunks/githubThunk';
import type { GithubState, GithubUser } from './githubSlice.types';

const initialState: GithubState = {
  repoSelectorVisible: false,
};

const githubSlice = createSlice({
  name: 'github',
  initialState,
  reducers: {
    setGithubRepoSelectorVisible: (_state, { payload }: PayloadAction<boolean>) => {
      _state.repoSelectorVisible = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGithubState.fulfilled, (state, { payload }: PayloadAction<GithubUser>) => {
      if (payload.user) {
        state.user = payload.user;
      }
    });

    // builder.addCase(
    //   getGithubUserState.fulfilled,
    //   (state, { payload }: PayloadAction<GithubUser | undefined>) => {
    //     if (payload) {
    //       state.user = payload;
    //     }
    //   },
    // );
  },
});

export const { setGithubRepoSelectorVisible } = githubSlice.actions;

export default githubSlice.reducer;
