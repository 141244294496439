import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-unresolved
import type { WritableDraft } from 'immer/dist/internal';
import _ from 'lodash';
import { importGithubWorkspace } from '../thunks/githubThunk';
import type { ExtendedWorkspace } from '../thunks/types';
import { createWorkspace, deleteWorkspace, getWorkspaces, upsertProtos } from '../thunks/workspacesThunk';
import type { SelectedRpcItemPayload, SelectedRpcItemType, SelectedRpcState } from './selectedRpcSlice.types';
import type { CreateWorkspacesResponse } from './workspacesSlice.types';

export const initialSelectedRpcItem: SelectedRpcItemType = {};

const initialState: SelectedRpcState = {};

const hydratedSelectedRpcWithWorkspace = (state: WritableDraft<SelectedRpcState>, workspace: ExtendedWorkspace) => {
  const { workspace_id } = workspace;
  if (workspace.hydrated) {
    if (Object.keys(workspace.messagesAndRpc.grpc).length) {
      const newSelectedRpc = _.cloneDeep(initialSelectedRpcItem);
      const [serviceName, serviceRpcs] = Object.entries(workspace.messagesAndRpc.grpc)[0];
      const firstMessageName = Object.keys(serviceRpcs)[0];
      const firstInstanceName = serviceRpcs[firstMessageName]?.instances[0]?.instanceMetadata.name;
      if ([serviceName, firstMessageName, firstInstanceName].every((pathSection) => typeof pathSection === 'string')) {
        newSelectedRpc.service = serviceName;
        newSelectedRpc.rpc = firstMessageName;
        newSelectedRpc.instance = firstInstanceName;
        state[workspace_id] = newSelectedRpc;
      } else {
        state[workspace_id] = {};
      }
    } else {
      state[workspace_id] = {};
    }
  } else {
    state[workspace_id] = {};
  }
};

const hydratedSelectedRpcWithWorkspaces = (state: WritableDraft<SelectedRpcState>, workspaces: ExtendedWorkspace[]) => {
  workspaces.forEach((w) => hydratedSelectedRpcWithWorkspace(state, w));
};

const selectedRpcSlice = createSlice({
  name: 'selectedRpc',
  initialState,
  reducers: {
    setSelectedRpc: (_state, { payload }: PayloadAction<SelectedRpcState>) => payload,
    setSelectedRpcItem: (state, { payload }: PayloadAction<SelectedRpcItemPayload>) => {
      state[payload.wsId] = payload.value;
    },
    clearSelectedRpc: () => initialState,
  },
  extraReducers: (builder) => {
    // upsertProtos ********************************************************************************
    builder.addCase(upsertProtos.fulfilled, (state, { payload }) => {
      hydratedSelectedRpcWithWorkspace(state, payload);
    });
    // createWorkspace *****************************************************************************
    builder.addCase(createWorkspace.fulfilled, (state, { payload }: PayloadAction<CreateWorkspacesResponse>) => {
      state[payload.workspace_id] = _.cloneDeep(initialSelectedRpcItem);
    });
    // getWorkspaces *******************************************************************************
    builder.addCase(getWorkspaces.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      hydratedSelectedRpcWithWorkspaces(state, workspaces);
    });
    builder.addCase(importGithubWorkspace.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      hydratedSelectedRpcWithWorkspaces(state, workspaces);
    });
    // deleteWorkspace *****************************************************************************
    builder.addCase(deleteWorkspace.fulfilled, (state, { meta }) => {
      delete state[meta.arg];
    });
  },
});

export const { setSelectedRpc, setSelectedRpcItem, clearSelectedRpc } = selectedRpcSlice.actions;

export default selectedRpcSlice.reducer;
