import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { CancelTokenSource, Method, ResponseType } from 'axios';
import * as pb from 'protobufjs';
import { HttpRequest, HttpResponse } from '../../pb-node/request_pb';

import type { ObjectToSerialize } from '../../utils/common.types';
import type { SelectedHttpMessageItemType } from '../slices/selectedHttpMessageSlice.types';
import type { AppThunk, Store } from '../store';

interface RequestData {
  wsId: string;
  method: Method;
  url: string;
  dataToSend: ObjectToSerialize;
  headersObject: Record<string, string>;
  responseType: ResponseType;
  timeout: number;
  httpMessage: SelectedHttpMessageItemType;
  root: pb.Root;
  decodingMessage: string;
}

const { CancelToken } = axios;
let source: CancelTokenSource;

export const httpRequest = createAsyncThunk<
  HttpResponse,
  RequestData,
  {
    rejectValue: Error;
    state: Store;
  }
>('httpRequest', async (reqData: RequestData, { rejectWithValue, getState }) => {
  const { method, url, dataToSend, headersObject, timeout, httpMessage, root } = reqData;
  const {
    agent,
    auth: { sessionToken },
  } = getState();

  const lookupPath = httpMessage.slice(0, 2).join('.');
  const sendType = root.lookupType(lookupPath);
  const message = sendType.create(dataToSend);
  const buffer = sendType.encode(message).finish();

  const wrapperMessage = new HttpRequest();
  wrapperMessage.setMethod(method);
  wrapperMessage.setPayload(buffer);
  wrapperMessage.setTimeout(timeout);
  wrapperMessage.setUri(url);
  wrapperMessage.setSessionToken(sessionToken);
  Object.entries(headersObject).forEach(([key, value]) => wrapperMessage.getHeadersMap().set(key, value));

  const serializedRequest = wrapperMessage.serializeBinary();

  try {
    source = CancelToken.source();
    const agentUrl = new URL(process.env.REACT_APP_AGENT_HTTP_URL || 'http://localhost:5000/api/proto');
    agentUrl.port = agent.port.toString();

    const response = await axios({
      method: 'POST',
      url: agentUrl.href,
      data: serializedRequest,
      responseType: 'arraybuffer',
      timeout: timeout + 100,
      cancelToken: source.token,
    });

    const deserializedResponse = HttpResponse.deserializeBinary(response.data);
    return deserializedResponse;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const cancelHttpRequest = (): AppThunk => () => {
  if (source) source.cancel('Operation canceled by the user.');
};
