import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routes from '../../constants/routes.json';
import type { RootState } from '../../store/store';

const PrivateRoute: FC<{
  exact: boolean;
  path: string;
  component: FC;
}> = ({ exact, path, component }) => {
  const { auth } = useSelector((store: RootState) => store);

  return auth.isAuthenticated === undefined ? null : auth.isAuthenticated ? (
    <Route exact={exact} path={path} component={component} />
  ) : (
    <Redirect to={routes.SIGNIN} />
  );
};

export default PrivateRoute;
