import * as pb from 'protobufjs';
import { FieldOrOneOf } from '../../../store/slices/packagesSlice.types';
import { AnyField } from '../MiddleColumn/FieldValueEditor/FieldAnyContainer/types';
import { TimestampField } from '../MiddleColumn/FieldValueEditor/FieldTimestampContainer/types';
import { RepeatedField, TypeField } from './util.types';

export const isMapField = (field: pb.FieldBase): field is pb.MapField => {
  return field.map;
};

export const isMessageField = (field: pb.FieldBase): field is TypeField<pb.Type> => {
  return field.resolvedType instanceof pb.Type;
};

export const isEnumField = (field: pb.FieldBase): field is TypeField<pb.Enum> => {
  return field.resolvedType instanceof pb.Enum;
};

export const isAnyField = (field: pb.FieldBase): field is AnyField => {
  return (
    isMessageField(field) &&
    field.resolvedType.fullName.endsWith('google.protobuf.Any') &&
    'type_url' in field.resolvedType.fields &&
    'value' in field.resolvedType.fields
  );
};

export const isTimestampField = (field: pb.FieldBase): field is TimestampField => {
  return (
    isMessageField(field) &&
    field.resolvedType.fullName.endsWith('google.protobuf.Timestamp') &&
    'seconds' in field.resolvedType.fields &&
    'nanos' in field.resolvedType.fields
  );
};

export const isBoolField = (field: pb.FieldBase): field is TypeField<pb.Type> => {
  return field.type === 'bool';
};

export function isRepeatedField(field: pb.FieldBase): field is RepeatedField {
  return field.repeated;
}

export const isBasicField = (field: pb.FieldBase): field is TypeField<null> => {
  return field.resolvedType === null;
};

export const isOneOfField = (field: FieldOrOneOf): field is pb.OneOf => {
  return field instanceof pb.OneOf;
};
