import {
  isAnyField,
  isBasicField,
  isEnumField,
  isMessageField,
  isOneOfField,
  isTimestampField,
} from '../../LeftColumn/FieldTypeHelpers';
import {
  ContainerField,
  FAny,
  FBool,
  FBytes,
  FCContainer,
  FEnum,
  FMap,
  FMessage,
  FNumber,
  FOneOf,
  FRepeated,
  FString,
  FTimestamp,
  OUF,
  RootField,
} from './fieldValueEditor.types';

export const isOneOfContainer = (container: ContainerField): container is FOneOf => {
  return isOneOfField(container.field);
};

export const isAnyContainer = (container: FCContainer<RootField>): container is FCContainer<FAny> => {
  return isAnyField(container.field);
};

export const isTimestampContainer = (container: FCContainer<RootField>): container is FCContainer<FTimestamp> => {
  return isTimestampField(container.field);
};

export const isMessageContainer = (container: FCContainer<RootField>): container is FCContainer<FMessage> => {
  return isMessageField(container.field);
};

export const isEnumContainer = (container: FCContainer<RootField>): container is FCContainer<FEnum> => {
  return isEnumField(container.field);
};

export const isBasicContainer = (
  container: FCContainer<RootField>,
): container is FCContainer<FBool | FString | FNumber | FBytes> => {
  return isBasicField(container.field);
};

export const isStringContainer = (container: FCContainer<RootField>): container is FCContainer<FString> => {
  return isBasicField(container.field) && container.field.type === 'string';
};

export const isBoolContainer = (container: FCContainer<RootField>): container is FCContainer<FBool> => {
  return isBasicField(container.field) && container.field.type === 'bool';
};

export const isBytesContainer = (container: FCContainer<RootField>): container is FCContainer<FBytes> => {
  return isBasicField(container.field) && container.field.type === 'bytes';
};

export const isRepeatedContainer = <T extends RootField>(container: FCContainer<T>): container is FRepeated<T> => {
  return container.field.repeated;
};

export const isMapContainer = <T extends RootField>(container: FCContainer<T>): container is FMap<T> => {
  return container.field.map;
};

export const isContainerField = (container: OUF<ContainerField>): boolean =>
  isOneOfContainer(container) || isAnyContainer(container) || isMessageContainer(container);
