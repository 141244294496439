import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { importGithubWorkspace } from '../thunks/githubThunk';
import { deleteWorkspace, getWorkspaces, updateWorkspaceUserData } from '../thunks/workspacesThunk';
import type { AutosaveState, SetWorkspaceAutosaveAction } from './autosaveSlice.types';

const initialState: AutosaveState = {};

const autosaveSlice = createSlice({
  name: 'autosave',
  initialState,
  reducers: {
    setAutosave: (_state, { payload }: PayloadAction<AutosaveState>) => payload,
    setWorkspaceAutosave: (state, { payload }: PayloadAction<SetWorkspaceAutosaveAction>) => {
      const { workspaceId } = payload;
      if (!state[workspaceId]) {
        state[workspaceId] = {
          needsUpdate: true,
          lastUpdatedAt: Date.now().toString(),
        };
      } else {
        state[workspaceId].needsUpdate = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWorkspaces.fulfilled, (_, { payload }) => {
      const newState: AutosaveState = Object.fromEntries(
        payload.workspaces.map((w) => [
          w.workspace_id,
          {
            lastUpdatedAt: w.last_updated_at,
            needsUpdate: false,
          },
        ]),
      );

      return newState;
    });

    builder.addCase(importGithubWorkspace.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      workspaces.forEach((w) => {
        state[w.workspace_id] = {
          lastUpdatedAt: w.last_updated_at,
          needsUpdate: false,
        };
      });
    });

    builder.addCase(deleteWorkspace.fulfilled, (state, { meta }) => {
      const { arg: deletedWorkspaceId } = meta;
      delete state[deletedWorkspaceId];
    });

    builder.addCase(updateWorkspaceUserData.fulfilled, (state, { payload }) => {
      const { updates } = payload;
      updates.forEach((update) => {
        const { workspace_id: workspaceId, last_updated_at: lastUpdatedAt } = update;
        state[workspaceId].lastUpdatedAt = lastUpdatedAt;
        state[workspaceId].needsUpdate = false;
      });
    });
  },
});

export const { setAutosave, setWorkspaceAutosave } = autosaveSlice.actions;
export default autosaveSlice.reducer;
