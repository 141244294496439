import type { SelectedHttpMessageItemType } from './selectedHttpMessageSlice.types';

export const HttpMethods = ['GET', 'POST', 'PUT', 'DELETE', 'PATCH', 'HEAD', 'OPTIONS', 'LINK', 'UNLINK'] as const;

export type HttpMethodItemType = typeof HttpMethods[number];

export type HttpHeaderType = {
  checked: boolean;
  key: string;
  value: string;
};

export type HttpHeadersItemType = Array<HttpHeaderType>;

export type DecodingItemType = { [key: string]: string };

type InstanceMetadata = {
  name: string;
};

export type HttpParamsType = {
  method: HttpMethodItemType;
  url: string;
  headers: HttpHeadersItemType;
  timeout: number;
  decodeObj: DecodingItemType;
  loading: boolean;
  instanceMetadata: InstanceMetadata;
};

export const DecodingSymbol = Symbol('DECODING_OBJ');
export type HttpItemType = {
  [packageName: string]: {
    [messageName: string]: {
      instances: HttpParamsType[];
    };
  };
  [DecodingSymbol]?: DecodingItemType;
};

export const initialHttpItem: HttpItemType = {};

// Payloads
export type HttpMethodPayload = {
  wsId: string;
  path: SelectedHttpMessageItemType;
  value: HttpMethodItemType;
};

export type HttpUrlPayload = {
  wsId: string;
  path: SelectedHttpMessageItemType;
  value: string;
};

export type AddHttpHeaderPayload = {
  wsId: string;
  path: SelectedHttpMessageItemType;
};

export type RemoveHttpHeaderPayload = {
  wsId: string;
  path: SelectedHttpMessageItemType;
  index: number;
};

export type HttpHeaderPayload = {
  wsId: string;
  path: SelectedHttpMessageItemType;
  index: number;
  value: string;
};

export type HttpTimeoutPayload = {
  wsId: string;
  path: SelectedHttpMessageItemType;
  value: number;
};

export type SetHttpDecodingPayload = {
  wsId: string;
  path: SelectedHttpMessageItemType;
  key: string;
  value: string;
};

export type HttpState = {
  [wsId: string]: HttpItemType;
};

type CreateHttpMessageInstancePayload = {
  wsId: string;
  packageName: string;
  messageName: string;
  instanceName: string;
};

type RenameHttpMessageInstancePayload = {
  wsId: string;
  packageName: string;
  messageName: string;
  instanceName: string;
  newInstanceName: string;
};

export type { CreateHttpMessageInstancePayload, RenameHttpMessageInstancePayload };
