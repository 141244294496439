import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { checkAuth, logout, signIn } from '../thunks/authThunk';
import type { AuthState } from './authSlice.types';

const initialState: AuthState = {
  isAuthenticated: undefined,
  loading: false,
  errorMessage: '',
  showAlert: false,
  sessionToken: '',
  user: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (_state, { payload }: PayloadAction<AuthState>) => payload,
    setShowSnackbar: (state, { payload }: PayloadAction<boolean>) => {
      state.showAlert = payload;
    },
    clearAuth: () => initialState,
  },
  extraReducers: (builder) => {
    // SIGNIN***************************************************************************************
    builder.addCase(signIn.pending, (state) => {
      state.loading = true;
      state.errorMessage = '';
      state.showAlert = false;
    });
    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      state.isAuthenticated = true;
      state.loading = false;
      state.errorMessage = '';
      state.sessionToken = payload.sessionToken;
      state.user = payload.user;
    });
    builder.addCase(signIn.rejected, (state, { payload }) => {
      state.isAuthenticated = false;
      state.loading = false;
      state.showAlert = true;
      state.errorMessage = payload as string;
    });
    // CHECK-AUTH **********************************************************************************
    builder.addCase(checkAuth.pending, (state) => {
      state.loading = true;
      state.errorMessage = '';
      state.showAlert = false;
    });
    builder.addCase(checkAuth.fulfilled, (state, { payload }) => {
      state.isAuthenticated = true;
      state.loading = false;
      state.errorMessage = '';
      state.sessionToken = payload.sessionToken;
      state.user = payload.user;
    });
    builder.addCase(checkAuth.rejected, (state, { payload }) => {
      state.isAuthenticated = false;
      state.loading = false;
      state.showAlert = true;
      state.errorMessage = payload as string;
    });
    // LOGOUT
    builder.addCase(logout.pending, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(logout.rejected, () => initialState);
  },
});

export const { setAuth, setShowSnackbar, clearAuth } = authSlice.actions;

export default authSlice.reducer;
