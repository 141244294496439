import { CircularProgress } from '@material-ui/core';
import { push } from 'connected-react-router';
import React, { lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import routes from './constants/routes.json';
import SignInPage from './containers/SignInPage';
import { RootState } from './store/store';
import { checkAuth } from './store/thunks/authThunk';

// Lazily load routes and code split with webpacck
const LazyHomePage = lazy(() => import(/* webpackChunkName: "HomePage" */ './containers/HomePage'));
const LazyNotFound404Page = lazy(() => import('./components/NotFound404/NotFound404'));

const HomePage = (props: Record<string, any>): JSX.Element => (
  <React.Suspense
    fallback={
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress size={120} />
      </div>
    }
  >
    <LazyHomePage {...props} />
  </React.Suspense>
);

const NotFound404Page = (props: Record<string, any>): JSX.Element => (
  <React.Suspense
    fallback={
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress size={120} />
      </div>
    }
  >
    <LazyNotFound404Page {...props} />
  </React.Suspense>
);

export default function Routes(): JSX.Element {
  const dispatch = useDispatch();
  const { auth } = useSelector((store: RootState) => store);
  const { search } = useLocation();

  useEffect(() => {
    if (auth.isAuthenticated === undefined) {
      dispatch(checkAuth());
    } else if (auth.isAuthenticated) {
      dispatch(push(`/home${search}`));
    } else {
      window.location.href = `${process.env.REACT_APP_CLIENT_URL}/login`;
    }
  }, [dispatch, auth.isAuthenticated, search]);

  return (
    <Switch>
      <Route exact path={routes.SIGNIN} component={() => <></>} />
      <PrivateRoute exact path={routes.HOME} component={HomePage} />
      <PrivateRoute exact path={routes.GITHUB_CALLBACK} component={HomePage} />
      <Route path="*" component={NotFound404Page} />
    </Switch>
  );
}
