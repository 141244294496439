import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { grpcCancel, grpcEnd, grpcPush, grpcSend, makePoll } from '../thunks/grpcThunk';
import { httpRequest } from '../thunks/httpThunk';
import type { AgentState } from './agentSlice.types';

const initialState: AgentState = {
  reachable: undefined,
  showTooltip: false,
  port: 5000,
};

const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    setChanged: (state, { payload }: PayloadAction<boolean>) => {
      state.showTooltip = payload;
    },
    setPort: (state, { payload }: PayloadAction<number>) => {
      state.port = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(grpcSend.fulfilled, (state) => {
      state.showTooltip = state.reachable !== true;
      state.reachable = true;
    });
    builder.addCase(grpcSend.rejected, (state) => {
      state.showTooltip = state.reachable !== false;
      state.reachable = false;
    });
    builder.addCase(grpcPush.fulfilled, (state) => {
      state.showTooltip = state.reachable !== true;
      state.reachable = true;
    });
    builder.addCase(grpcPush.rejected, (state) => {
      state.showTooltip = state.reachable !== false;
      state.reachable = false;
    });
    builder.addCase(grpcEnd.fulfilled, (state) => {
      state.showTooltip = state.reachable !== true;
      state.reachable = true;
    });
    builder.addCase(grpcEnd.rejected, (state) => {
      state.showTooltip = state.reachable !== false;
      state.reachable = false;
    });
    builder.addCase(grpcCancel.fulfilled, (state) => {
      state.showTooltip = state.reachable !== true;
      state.reachable = true;
    });
    builder.addCase(grpcCancel.rejected, (state) => {
      state.showTooltip = state.reachable !== false;
      state.reachable = false;
    });
    builder.addCase(httpRequest.fulfilled, (state) => {
      state.showTooltip = state.reachable !== true;
      state.reachable = true;
    });
    builder.addCase(httpRequest.rejected, (state) => {
      state.showTooltip = state.reachable !== false;
      state.reachable = false;
    });
    builder.addCase(makePoll.fulfilled, (state) => {
      state.showTooltip = state.reachable !== true;
      state.reachable = true;
    });
    builder.addCase(makePoll.rejected, (state) => {
      state.showTooltip = state.reachable !== false;
      state.reachable = false;
    });
  },
});

export const { setChanged, setPort } = agentSlice.actions;
export default agentSlice.reducer;
