import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { importGithubWorkspace } from '../thunks/githubThunk';
import { createWorkspace, deleteWorkspace, getWorkspaces } from '../thunks/workspacesThunk';
import type { CurrentWorkspaceState, SetCurrentWorkspacePayload } from './currentWorkspaceSlice.types';
import type { GetWorkspacesResponse } from './workspacesSlice.types';

const initialState: CurrentWorkspaceState = {
  workspaceId: '',
  workspaceName: '',
  loading: false,
  errorMessage: '',
  showAlert: false,
};

const currentWorkspaceSlice = createSlice({
  name: 'currentWorkspace',
  initialState,
  reducers: {
    setCurrentWorkspace: (state, { payload }: PayloadAction<SetCurrentWorkspacePayload>) => {
      state.workspaceId = payload.workspaceId;
      state.workspaceName = payload.workspaceName;
    },
    clearCurrentWorkspace: () => initialState,
  },
  extraReducers: (builder) => {
    // createWorkspace *****************************************************************************
    builder.addCase(createWorkspace.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.errorMessage = '';
      state.workspaceId = payload.workspace_id;
      state.workspaceName = payload.workspace_name;
    });
    // getWorkspace ********************************************************************************
    builder.addCase(getWorkspaces.fulfilled, (state, { payload }: PayloadAction<GetWorkspacesResponse>) => {
      const { workspaces }: GetWorkspacesResponse = payload;
      if (workspaces.length && !state.workspaceId.length) {
        state.workspaceId = workspaces[0].workspace_id;
        state.workspaceName = workspaces[0].workspace_name;
      }
    });
    // importGithubWorkspace ***********************************************************************
    builder.addCase(importGithubWorkspace.fulfilled, (state, { payload }: PayloadAction<GetWorkspacesResponse>) => {
      const { workspaces }: GetWorkspacesResponse = payload;
      if (workspaces.length) {
        state.workspaceId = workspaces[0].workspace_id;
        state.workspaceName = workspaces[0].workspace_name;
      }
    });
    // deleteWorkspace *****************************************************************************
    builder.addCase(deleteWorkspace.fulfilled, (state, { payload, meta }) => {
      const { ws } = payload;
      const wsLength = Object.keys(ws).length;
      if (wsLength > 1) {
        const newCurrentWorkspace: string | undefined = Object.keys(ws).find((k) => k !== meta.arg);
        if (newCurrentWorkspace) {
          state.workspaceId = newCurrentWorkspace;
          state.workspaceName = ws[newCurrentWorkspace].workspaceName;
        }
      }
      if (wsLength === 1) {
        state.workspaceId = '';
        state.workspaceName = '';
      }
    });
  },
});

export const { setCurrentWorkspace, clearCurrentWorkspace } = currentWorkspaceSlice.actions;

export default currentWorkspaceSlice.reducer;
