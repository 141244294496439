import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { grpcPush, grpcSend } from '../thunks/grpcThunk';
import { createWorkspace, deleteWorkspace } from '../thunks/workspacesThunk';
import type { ErrorStateType, ErrorTypePayload } from './errorSlice.types';

const initialState: ErrorStateType = {
  open: false,
  errorMessage: '',
  severity: 'error',
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setErrorMessage: (state, { payload }: PayloadAction<ErrorTypePayload>) => {
      state.errorMessage = payload.errorMessage;
      state.severity = payload.severity;
    },
    handleErrorOpen: (state) => {
      state.open = true;
    },
    handleErrorClose: (state) => {
      state.open = false;
    },
  },
  extraReducers: (builder) => {
    // grpcSend *****************************************************************************
    builder.addCase(grpcSend.rejected, (state, { payload }) => {
      if (payload?.length) {
        state.errorMessage = payload;
        state.severity = 'error';
        state.open = true;
      }
    });
    // grpcSend *****************************************************************************
    builder.addCase(grpcPush.rejected, (state, { payload }) => {
      if (payload?.length) {
        state.errorMessage = payload;
        state.severity = 'error';
        state.open = true;
      }
    });
    // createWorkspace *****************************************************************************
    builder.addCase(createWorkspace.rejected, (state, { payload }) => {
      if (payload?.length) {
        state.errorMessage = payload;
        state.severity = 'error';
        state.open = true;
      }
    });
    // deleteWorkspace *****************************************************************************
    builder.addCase(deleteWorkspace.rejected, (state, { payload }) => {
      if (payload?.length) {
        state.errorMessage = payload;
        state.severity = 'error';
        state.open = true;
      }
    });
  },
});

export const { setErrorMessage, handleErrorOpen, handleErrorClose } = errorSlice.actions;

export default errorSlice.reducer;
