export type SelectedOneOfItemType = { [key: string]: number };

export type SelectedOneOfState = {
  [id: string]: SelectedOneOfItemType;
};

export type SetSelectOneOfItemPayload = {
  wsId: string;
  value: SelectedOneOfItemType;
};

export type DeleteSelectOneOfItemPayload = {
  wsId: string;
  value: string;
};

export type DeleteIfSelectOneOfItemPayload = {
  wsId: string;
  value: string;
  selectedId: number;
  deleteNested: string;
};

export type ReplaceKeysInSelectOneOfItemPayload = {
  wsId: string;
  pathString: string;
  currentKey: string;
  key: string;
};

export type DeleteKeysInSelectOneOfItemPayload = {
  wsId: string;
  key: string;
};

export const initialSelectedOneOfItem: SelectedOneOfItemType = {};
