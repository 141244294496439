import { CombinedState, combineReducers, Reducer } from '@reduxjs/toolkit';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { enableMapSet } from 'immer';
import agentReducer from './slices/agentSlice';
import type { AgentState } from './slices/agentSlice.types';
import anySchemaReducer from './slices/anySchemaSlice';
import type { AnySchemaState } from './slices/anySchemaSlice.types';
import authReducer from './slices/authSlice';
import type { AuthState } from './slices/authSlice.types';
import autosaveReducer from './slices/autosaveSlice';
import type { AutosaveState } from './slices/autosaveSlice.types';
import currentWorkspaceReducer from './slices/currentWorkspaceSlice';
import type { CurrentWorkspaceState } from './slices/currentWorkspaceSlice.types';
import dataReducer from './slices/dataSlice';
import type { DataState } from './slices/dataSlice.types';
import errorReducer from './slices/errorSlice';
import type { ErrorStateType } from './slices/errorSlice.types';
import githubReducer from './slices/githubSlice';
import type { GithubState } from './slices/githubSlice.types';
import grpcReducer from './slices/grpcSlice';
import type { GrpcState } from './slices/grpcSlice.types';
import httpReducer from './slices/httpSlice';
import type { HttpState } from './slices/httpSlice.types';
import interactionReducer from './slices/interactionSlice';
import type { InteractionState } from './slices/interactionSlice.types';
import messageInputReducer from './slices/messageInputSlice';
import type { MessageInputState } from './slices/messageInputSlice.types';
import operationsReducer from './slices/operationsSlice';
import type { OperationsState } from './slices/operationsSlice.types';
import packagesReducer from './slices/packagesSlice';
import type { PackagesState } from './slices/packagesSlice.types';
import selectedGrpcMessageReducer from './slices/selectedGrpcMessageSlice';
import type { SelectedGrpcMessageState } from './slices/selectedGrpcMessageSlice.types';
import selectedHttpMessageReducer from './slices/selectedHttpMessageSlice';
import type { SelectedHttpMessageState } from './slices/selectedHttpMessageSlice.types';
import selectedMessagesReducer from './slices/selectedMessagesSlice';
import type { SelectedMessagesState } from './slices/selectedMessagesSlice.types';
import selectedOneOfReducer from './slices/selectedOneOfSlice';
import type { SelectedOneOfState } from './slices/selectedOneOfSlice.types';
import selectedRpcReducer from './slices/selectedRpcSlice';
import type { SelectedRpcState } from './slices/selectedRpcSlice.types';
import splitReducer from './slices/splitSlice';
import type { SplitState } from './slices/splitSlice.types';
import tempDataReducer from './slices/tempDataSlice';
import type { TempDataState } from './slices/tempDataSlice.types';
import workspacesReducer from './slices/workspacesSlice';
import type { IWorkspacesState } from './slices/workspacesSlice.types';

enableMapSet();

export default function createRootReducer(
  history: History,
): Reducer<
  CombinedState<{
    router: RouterState;
    agent: AgentState;
    auth: AuthState;
    anySchema: AnySchemaState;
    autosave: AutosaveState;
    currentWorkspace: CurrentWorkspaceState;
    data: DataState;
    error: ErrorStateType;
    github: GithubState;
    grpc: GrpcState;
    http: HttpState;
    interaction: InteractionState;
    messageInput: MessageInputState;
    operations: OperationsState;
    packages: PackagesState;
    selectedGrpcMessage: SelectedGrpcMessageState;
    selectedHttpMessage: SelectedHttpMessageState;
    selectedMessages: SelectedMessagesState;
    selectedOneOf: SelectedOneOfState;
    selectedRpc: SelectedRpcState;
    split: SplitState;
    tempData: TempDataState;
    workspaces: IWorkspacesState;
  }>
> {
  const rootReducer = combineReducers({
    router: connectRouter(history),
    agent: agentReducer,
    anySchema: anySchemaReducer,
    auth: authReducer,
    autosave: autosaveReducer,
    currentWorkspace: currentWorkspaceReducer,
    data: dataReducer,
    error: errorReducer,
    github: githubReducer,
    grpc: grpcReducer,
    http: httpReducer,
    interaction: interactionReducer,
    messageInput: messageInputReducer,
    operations: operationsReducer,
    packages: packagesReducer,
    selectedGrpcMessage: selectedGrpcMessageReducer,
    selectedHttpMessage: selectedHttpMessageReducer,
    selectedMessages: selectedMessagesReducer,
    selectedOneOf: selectedOneOfReducer,
    selectedRpc: selectedRpcReducer,
    split: splitReducer,
    tempData: tempDataReducer,
    workspaces: workspacesReducer,
  });

  return rootReducer;
}
