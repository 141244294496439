import { Action, configureStore, getDefaultMiddleware, ThunkAction } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createRootReducer from './rootReducer';

const history = createBrowserHistory();
const rootReducer = createRootReducer(history);

const persistRootConfig = {
  key: 'root',
  storage,
  whitelist: ['currentWorkspace', 'messageInput'],
  version: 1,
};

const persistedReducer = persistReducer(persistRootConfig, rootReducer);

const router = routerMiddleware(history);
const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }),
  router,
];

if (process.env.NODE_ENV === 'development') {
  const reduxLogger = createLogger({
    level: 'info',
    collapsed: true,
  });
  middleware.push(reduxLogger);
}

const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  reducer: persistedReducer,
  middleware,
});

const persistor = persistStore(store);

type Store = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;
type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
type RootState = ReturnType<typeof persistedReducer>;

export { history, persistor };
export type { Store, AppDispatch, AppThunk, RootState };
export default store;
