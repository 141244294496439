import type { AxiosResponse, AxiosStatic } from 'axios';
import type { GetResponse, GetRoutePaths, PostResponse, PostRoutePaths, PostRouteRequest, Tail } from './axios.types';

class ServerCaller {
  private axios: AxiosStatic;

  constructor(axios: AxiosStatic) {
    this.axios = axios;
  }

  async get<T extends GetRoutePaths>(
    path: T,
    ...args: Tail<Parameters<AxiosStatic['get']>>
  ): Promise<AxiosResponse<GetResponse<T>>> {
    const resp = await this.axios.get(path, ...args);
    return resp;
  }

  async post<T extends PostRoutePaths>(
    path: T,
    data: PostRouteRequest<T>,
    ...args: Tail<Tail<Parameters<AxiosStatic['post']>>>
  ): Promise<AxiosResponse<PostResponse<T>>> {
    const resp = await this.axios.post(path, data, ...args);
    return resp;
  }
}

export default ServerCaller;
