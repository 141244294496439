import type { MetadataOptions } from '@grpc/grpc-js/build/src/metadata';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as a from 'fp-ts/Array';
import { pipe } from 'fp-ts/lib/function';
import * as o from 'fp-ts/Option';
// eslint-disable-next-line import/no-unresolved
import type { WritableDraft } from 'immer/dist/internal';
import _ from 'lodash';
import { OpenChannel } from '../../pb-node/request_pb';
import { getGrpcInstance, getStringified } from '../../utils/common';
import type { ObjectToSerialize } from '../../utils/common.types';
import { importGithubWorkspace } from '../thunks/githubThunk';
import { grpcCancel, grpcEnd, grpcPush, grpcSend, makePoll } from '../thunks/grpcThunk';
import { ExtendedWorkspace } from '../thunks/types';
import { createWorkspace, deleteWorkspace, getWorkspaces, upsertProtos } from '../thunks/workspacesThunk';
import { getDefaultGrpcMessageInstanceParams, metadataOptions } from './grpcSlice.init';
import type {
  CreateGrpcMessageInstancePayload,
  GrpcState,
  MetadataItemType,
  RenameGrpcMessageInstancePayload,
  RpcParamsType,
  TlsKeysType,
} from './grpcSlice.types';
import { GrpcItemType } from './grpcSlice.types';
import type { AddInteractionPayloadType } from './interactionSlice.types';
import type { SelectedRpcItemType } from './selectedRpcSlice.types';
import type { CreateWorkspacesResponse } from './workspacesSlice.types';

export const getPayload = (
  rpcSelected: string,
  dataToSend: ObjectToSerialize,
  response: ObjectToSerialize | null,
): AddInteractionPayloadType => ({
  key: rpcSelected,
  value: {
    req: [getStringified(dataToSend)],
    res: response
      ? [
          {
            error: false,
            text: getStringified(response),
          },
        ]
      : [],
  },
});

export const initialGrpcItem = {};

const initialState: GrpcState = {};

export type GrpcPathObjectType = { service: string; rpc: string; instance: string };

export type CallPayloadType = {
  path: GrpcPathObjectType;
  call: boolean;
};

export type LoadingPayloadType = {
  path: GrpcPathObjectType;
  loading: boolean;
};

export type AddMetadataItemPayload = {
  wsId: string;
  path: GrpcPathObjectType;
};

export type GrpcUrlPayload = {
  wsId: string;
  path: GrpcPathObjectType;
  url: string;
};

export type SetMetadataItemCheckedPayload = {
  wsId: string;
  path: GrpcPathObjectType;
  index: number;
};

export type SetMetadataItemKeyPayload = {
  wsId: string;
  path: GrpcPathObjectType;
  index: number;
  itemKey: string;
};

export type SetMetadataItemValuePayload = {
  wsId: string;
  path: GrpcPathObjectType;
  index: number;
  itemValue: string;
};

export type RemoveMetadataItemPayload = {
  wsId: string;
  path: GrpcPathObjectType;
  index: number;
};

export type OptionPayload = {
  wsId: string;
  path: GrpcPathObjectType;
  option: keyof MetadataOptions;
};

export type DeadlinePayload = {
  wsId: string;
  path: GrpcPathObjectType;
  deadline: number;
};

export type TlsIdPayload = {
  wsId: string;
  selectedRpc: Required<SelectedRpcItemType>;
  tlsId: string;
};

export type TlsFilePayload = {
  wsId: string;
  selectedRpc: SelectedRpcItemType;
  key: TlsKeysType;
  fileName: string;
  content: string;
};

const setGrpcCallsToFalse = (state: WritableDraft<GrpcState>): void => {
  Object.keys(state).forEach((ws) => {
    Object.keys(state[ws]).forEach((service) => {
      Object.keys(state[ws][service]).forEach((rpc) => {
        Object.values(state[ws][service][rpc]).forEach((instances) => {
          instances.forEach((instance) => {
            if (instance.call) instance.call = false;
          });
        });
      });
    });
  });
};

const createInstance = (state: WritableDraft<GrpcState>, createInstanceParams: CreateGrpcMessageInstancePayload) => {
  const { wsId, service, rpc, instanceName } = createInstanceParams;
  const messageInstances = state[wsId]?.[service]?.[rpc]?.instances;
  if (messageInstances) {
    // Get the info we need to construct the default parameters from another message instance for this rpc
    const { grpcMethodType, requestMessage, responseMessage } = messageInstances[0];

    const newInstance = getDefaultGrpcMessageInstanceParams(
      grpcMethodType,
      requestMessage,
      responseMessage,
      instanceName,
    );

    messageInstances.push(newInstance);
  }
};

const renameTo = (instance: WritableDraft<RpcParamsType>, newInstanceName: string) => {
  instance.instanceMetadata.name = newInstanceName;
  return instance;
};

const renameInstance = (state: WritableDraft<GrpcState>, createInstanceParams: RenameGrpcMessageInstancePayload) => {
  const { wsId, service, rpc, instanceName, newInstanceName } = createInstanceParams;
  const messageInstances = state[wsId]?.[service]?.[rpc]?.instances;
  if (messageInstances) {
    state[wsId][service][rpc].instances = pipe(
      messageInstances,
      a.filterMap((instance) =>
        instance.instanceMetadata.name === instanceName ? o.some(renameTo(instance, newInstanceName)) : o.none,
      ),
    );
  }
};

const deleteInstance = (state: WritableDraft<GrpcState>, createInstanceParams: CreateGrpcMessageInstancePayload) => {
  const { wsId, service, rpc, instanceName } = createInstanceParams;
  const messageInstances = state[wsId]?.[service]?.[rpc]?.instances;
  if (messageInstances) {
    pipe(
      messageInstances,
      a.findIndex((instance) => instance.instanceMetadata.name === instanceName),
      o.map(a.deleteAt),
      o.chain((delFrom) => delFrom(messageInstances)),
      o.map((newInstances) => (state[wsId][service][rpc].instances = newInstances)),
    );
  }
};

const getChannelInstance = (state: WritableDraft<GrpcState>, channelInstance: OpenChannel.AsObject) => {
  const { serviceName: service, methodName: rpc, workspaceId: wsId, instanceName: instance } = channelInstance;
  return state[wsId]?.[service]?.[rpc]?.instances.find((i) => i.instanceMetadata.name === instance);
};

const hydratedRpcDisplayWithWorkspaces = (state: WritableDraft<GrpcState>, workspaces: ExtendedWorkspace[]) => {
  workspaces.forEach((w) => {
    const { workspace_id, user_data } = w;
    if (w.hydrated) {
      if (user_data.grpc) {
        const hydratedUserGrpc: GrpcItemType = Object.fromEntries(
          Object.entries(user_data.grpc).map(([packageName, packageGrpc]) => [
            packageName,
            Object.fromEntries(
              Object.entries(packageGrpc).map(([messageName, messageInstances]) => [
                messageName,
                {
                  instances: messageInstances.instances.map((instance) => ({
                    ...instance,
                    options: _.cloneDeep(metadataOptions),
                    sendLoading: false,
                    cancelLoading: false,
                    endLoading: false,
                    pushLoading: false,
                  })),
                },
              ]),
            ),
          ]),
        );

        _.merge(w.messagesAndRpc.grpc, hydratedUserGrpc);

        state[workspace_id] = w.messagesAndRpc.grpc;
      } else {
        state[workspace_id] = w.messagesAndRpc.grpc;
      }
    } else {
      state[workspace_id] = {};
    }
  });
};

const grpc = createSlice({
  name: 'grpc',
  initialState,
  reducers: {
    setGrpc: (_state, { payload }: PayloadAction<GrpcState>) => payload,
    setGrpcUrl: (state, { payload }: PayloadAction<GrpcUrlPayload>) => {
      const { wsId, path, url } = payload;
      const instance = getGrpcInstance(state, wsId, path); // state[wsId][service]?.[rpc]?.[instance];
      if (instance) instance.url = url;
    },
    setTlsId: (state, { payload }: PayloadAction<TlsIdPayload>) => {
      const { wsId, selectedRpc, tlsId } = payload;
      const instance = getGrpcInstance(state, wsId, selectedRpc);
      if (instance) instance.tlsId = tlsId;
    },
    addMetadataItem: (state, { payload }: PayloadAction<AddMetadataItemPayload>) => {
      const { wsId, path } = payload;
      const instance = getGrpcInstance(state, wsId, path);
      const newItem: MetadataItemType = {
        checked: true,
        key: '',
        value: '',
      };
      if (instance) instance.metadata.unshift(newItem);
    },
    setMetadataItemChecked: (state, { payload }: PayloadAction<SetMetadataItemCheckedPayload>) => {
      const { wsId, path, index } = payload;
      const instance = getGrpcInstance(state, wsId, path);
      if (instance) {
        const item = instance.metadata[index];
        item.checked = !item.checked;
      }
    },
    setMetadataItemKey: (state, { payload }: PayloadAction<SetMetadataItemKeyPayload>) => {
      const { wsId, path, index, itemKey } = payload;
      const instance = getGrpcInstance(state, wsId, path);
      if (instance) {
        const item = instance.metadata[index];
        item.key = itemKey;
      }
    },
    setMetadataItemValue: (state, { payload }: PayloadAction<SetMetadataItemValuePayload>) => {
      const { wsId, path, index, itemValue } = payload;
      const instance = getGrpcInstance(state, wsId, path);
      if (instance) {
        const item = instance.metadata[index];
        item.value = itemValue;
      }
    },
    removeMetadataItem: (state, { payload }: PayloadAction<RemoveMetadataItemPayload>) => {
      const { wsId, path, index } = payload;
      const instance = getGrpcInstance(state, wsId, path);
      if (instance) instance.metadata.splice(index, 1);
    },
    setMetadataOption: (state, { payload }: PayloadAction<OptionPayload>) => {
      const { wsId, path, option } = payload;
      const instance = getGrpcInstance(state, wsId, path);
      if (instance) instance.options[option] = !instance.options[option];
    },
    setDeadline: (state, { payload }: PayloadAction<DeadlinePayload>) => {
      const { wsId, path, deadline } = payload;
      const instance = getGrpcInstance(state, wsId, path);
      if (instance) instance.deadline = deadline;
    },
    createGrpcMessageInstance: (state, { payload }: PayloadAction<CreateGrpcMessageInstancePayload>) => {
      createInstance(state, payload);
    },
    renameGrpcMessageInstance: (state, { payload }: PayloadAction<RenameGrpcMessageInstancePayload>) => {
      renameInstance(state, payload);
    },
    deleteGrpcMessageInstance: (state, { payload }: PayloadAction<CreateGrpcMessageInstancePayload>) => {
      deleteInstance(state, payload);
    },
    clearGrpc: () => initialState,
  },
  extraReducers: (builder) => {
    // upsertProtos ********************************************************************************
    builder.addCase(upsertProtos.fulfilled, (state, { payload }) => {
      const { workspace_id } = payload;
      state[workspace_id] = payload.hydrated ? payload.messagesAndRpc.grpc : {};
    });
    // createWorkspace *****************************************************************************
    builder.addCase(createWorkspace.fulfilled, (state, { payload }: PayloadAction<CreateWorkspacesResponse>) => {
      state[payload.workspace_id] = _.cloneDeep(initialGrpcItem);
    });
    // getWorkspaces *******************************************************************************
    builder.addCase(getWorkspaces.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      hydratedRpcDisplayWithWorkspaces(state, workspaces);
    });
    builder.addCase(importGithubWorkspace.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      hydratedRpcDisplayWithWorkspaces(state, workspaces);
    });
    // deleteWorkspace *****************************************************************************
    builder.addCase(deleteWorkspace.fulfilled, (state, { meta }) => {
      delete state[meta.arg];
    });
    // grpcSend *****************************************************************************
    builder.addCase(grpcSend.pending, (state, { meta }) => {
      const { wsId, currentSelectedRpc } = meta.arg;
      const instance = getGrpcInstance(state, wsId, currentSelectedRpc);
      if (instance) {
        instance.sendLoading = true;
        instance.call = true;
      }
    });
    builder.addCase(grpcSend.fulfilled, (state, { meta, payload }) => {
      const { wsId, currentSelectedRpc } = meta.arg;
      const instance = getGrpcInstance(state, wsId, currentSelectedRpc);
      if (instance) instance.sendLoading = false;

      const openChannels = payload[1];
      setGrpcCallsToFalse(state);

      openChannels.forEach((channel) => {
        const channelInstance = getChannelInstance(state, channel);
        if (channelInstance) channelInstance.call = true;
      });
    });
    builder.addCase(grpcSend.rejected, (state, { payload, meta }) => {
      const { wsId, currentSelectedRpc } = meta.arg;
      const instance = getGrpcInstance(state, wsId, currentSelectedRpc);
      if (instance) {
        instance.sendLoading = false;

        if (payload === 'Network Error') {
          instance.call = false;
        }
      }
    });
    // grpcPush *****************************************************************************
    builder.addCase(grpcPush.pending, (state, { meta }) => {
      const { wsId, currentSelectedRpc } = meta.arg;
      const instance = getGrpcInstance(state, wsId, currentSelectedRpc);
      if (instance) instance.pushLoading = true;
    });
    builder.addCase(grpcPush.fulfilled, (state, { meta, payload }) => {
      const { wsId, currentSelectedRpc } = meta.arg;
      const instance = getGrpcInstance(state, wsId, currentSelectedRpc);
      if (instance) instance.pushLoading = false;

      setGrpcCallsToFalse(state);

      const openChannels = payload[1];
      openChannels.forEach((channel) => {
        const channelInstance = getChannelInstance(state, channel);
        if (channelInstance) channelInstance.call = true;
      });
    });
    builder.addCase(grpcPush.rejected, (state, { payload, meta }) => {
      const { wsId, currentSelectedRpc } = meta.arg;
      const instance = getGrpcInstance(state, wsId, currentSelectedRpc);
      if (instance) instance.pushLoading = false;
    });
    // grpcEnd *****************************************************************************
    builder.addCase(grpcEnd.pending, (state, { meta }) => {
      const { wsId, currentSelectedRpc } = meta.arg;
      const instance = getGrpcInstance(state, wsId, currentSelectedRpc);
      if (instance) instance.endLoading = true;
    });
    builder.addCase(grpcEnd.fulfilled, (state, { meta, payload }) => {
      const { wsId, currentSelectedRpc } = meta.arg;
      const instance = getGrpcInstance(state, wsId, currentSelectedRpc);
      if (instance) instance.endLoading = false;

      setGrpcCallsToFalse(state);

      const openChannels = payload[1];
      openChannels.forEach((channel) => {
        const channelInstance = getChannelInstance(state, channel);
        if (channelInstance) channelInstance.call = true;
      });
    });
    builder.addCase(grpcEnd.rejected, (state, { payload, meta }) => {
      const { wsId, currentSelectedRpc } = meta.arg;
      const instance = getGrpcInstance(state, wsId, currentSelectedRpc);
      if (instance) instance.endLoading = false;
    });
    // grpcCancel *****************************************************************************
    builder.addCase(grpcCancel.pending, (state, { meta }) => {
      const { wsId, currentSelectedRpc } = meta.arg;
      const instance = getGrpcInstance(state, wsId, currentSelectedRpc);
      if (instance) instance.cancelLoading = true;
    });
    builder.addCase(grpcCancel.fulfilled, (state, { meta, payload }) => {
      const { wsId, currentSelectedRpc } = meta.arg;
      const instance = getGrpcInstance(state, wsId, currentSelectedRpc);
      if (instance) instance.cancelLoading = false;

      setGrpcCallsToFalse(state);

      const openChannels = payload[1];
      openChannels.forEach((channel) => {
        const channelInstance = getChannelInstance(state, channel);
        if (channelInstance) channelInstance.call = true;
      });
    });
    builder.addCase(grpcCancel.rejected, (state, { payload, meta }) => {
      const { wsId, currentSelectedRpc } = meta.arg;
      const instance = getGrpcInstance(state, wsId, currentSelectedRpc);
      if (instance) instance.cancelLoading = false;
    });
    // makePoll *****************************************************************************
    builder.addCase(makePoll.fulfilled, (state, { meta, payload }) => {
      setGrpcCallsToFalse(state);

      const openChannels = payload[1];
      openChannels.forEach((channel) => {
        const channelInstance = getChannelInstance(state, channel);
        if (channelInstance) channelInstance.call = true;
      });
    });
    builder.addCase(makePoll.rejected, (state, { payload, meta }) => {
      // TODO: display error or something?
    });
  },
});

export const {
  setGrpc,
  setGrpcUrl,
  setTlsId,
  addMetadataItem,
  setMetadataItemChecked,
  setMetadataItemKey,
  setMetadataItemValue,
  removeMetadataItem,
  setMetadataOption,
  setDeadline,
  createGrpcMessageInstance,
  renameGrpcMessageInstance,
  deleteGrpcMessageInstance,
  clearGrpc,
} = grpc.actions;

export default grpc.reducer;
