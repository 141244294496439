import type { MetadataOptions } from '@grpc/grpc-js/build/src/metadata';
import type { GrpcMethodType, RpcParamsType, TlsItemType } from './grpcSlice.types';

export const metadataOptions: MetadataOptions = {
  /* Signal that the request is idempotent. Defaults to false */
  idempotentRequest: false,
  /* Signal that the call should not return UNAVAILABLE before it has
   * started. Defaults to true. */
  waitForReady: true,
  /* Signal that the call is cacheable. GRPC is free to use GET verb.
   * Defaults to false */
  cacheableRequest: false,
  /* Signal that the initial metadata should be corked. Defaults to false. */
  corked: false,
};

export const initialTlsItem: TlsItemType = {
  isUsed: false,
  caCert: {
    label: 'CA certificate',
    fileName: '',
    content: '',
    error: false,
  },
  clientKey: {
    label: 'Client key',
    fileName: '',
    content: '',
    error: false,
  },
  clientCert: {
    label: 'Client certificate',
    fileName: '',
    content: '',
    error: false,
  },
};

export const initialUrlItem = process.env.REACT_APP_GRPC_URL || '';

export const metadataOptionKeys = ['idempotentRequest', 'waitForReady', 'cacheableRequest', 'corked'] as const;

const defaultInstanceName = '0';
const defaultDeadline = 0;

const getDefaultGrpcMessageInstanceParams = (
  grpcMethodType: GrpcMethodType,
  requestMessage: [string, string],
  responseMessage: [string, string],
  instanceName?: string,
): RpcParamsType => ({
  call: false,
  grpcMethodType,
  requestMessage: [...requestMessage],
  responseMessage: [...responseMessage],
  metadata: [],
  deadline: defaultDeadline,
  options: { ...metadataOptions },
  tlsId: '',
  url: initialUrlItem,
  sendLoading: false,
  pushLoading: false,
  endLoading: false,
  cancelLoading: false,
  instanceMetadata: {
    name: instanceName ?? defaultInstanceName,
  },
});

export { getDefaultGrpcMessageInstanceParams };
