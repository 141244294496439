import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { importGithubWorkspace } from '../thunks/githubThunk';
import { createWorkspace, deleteWorkspace, getWorkspaces, upsertProtos } from '../thunks/workspacesThunk';
import type { AnySchemaItemType, AnySchemaState, LoadedAnySchema } from './anySchemaSlice.types';
import type { CreateWorkspacesResponse } from './workspacesSlice.types';

export const initialAnySchemaItem: AnySchemaItemType = new Map();

const initialState: AnySchemaState = {};

const anySchemaSlice = createSlice({
  name: 'anySchema',
  initialState,
  reducers: {
    setAnySchema: (_state, { payload }: PayloadAction<AnySchemaState>) => payload,
    assignWorkspaceSchema: (_state, { payload }: PayloadAction<LoadedAnySchema>) => {
      const { wsId, schema, outerPath } = payload;
      const workspaceSchemaMapping = _state[wsId];
      workspaceSchemaMapping.set(outerPath.join('.'), schema);
    },
    clearAnySchemas: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(upsertProtos.fulfilled, (state, { payload }) => {
      const { workspace_id } = payload;
      state[workspace_id] = _.cloneDeep(initialAnySchemaItem);
    });
    // createWorkspace *****************************************************************************
    builder.addCase(createWorkspace.fulfilled, (state, { payload }: PayloadAction<CreateWorkspacesResponse>) => {
      state[payload.workspace_id] = _.cloneDeep(initialAnySchemaItem);
    });
    // getWorkspaces *******************************************************************************
    builder.addCase(getWorkspaces.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      workspaces.forEach((w) => {
        state[w.workspace_id] = _.cloneDeep(initialAnySchemaItem);
      });
    });
    builder.addCase(importGithubWorkspace.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      workspaces.forEach((w) => {
        // Unlike with getWorkspaces, which is presumably only being called on app init,
        // We don't want to clobber data for existing workspaces
        // Since importGithubWorkspace still returns data for all of them
        state[w.workspace_id] = state[w.workspace_id] ?? _.cloneDeep(initialAnySchemaItem);
      });
    });
    // deleteWorkspace *****************************************************************************
    builder.addCase(deleteWorkspace.fulfilled, (state, { meta }) => {
      delete state[meta.arg];
    });
  },
});

export const { setAnySchema, assignWorkspaceSchema, clearAnySchemas } = anySchemaSlice.actions;

export default anySchemaSlice.reducer;
