import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as a from 'fp-ts/Array';
import { pipe } from 'fp-ts/lib/function';
import * as o from 'fp-ts/Option';
// eslint-disable-next-line import/no-unresolved
import type { WritableDraft } from 'immer/dist/internal';
import _ from 'lodash';
import { getHttpInstance } from '../../utils/common';
import { importGithubWorkspace } from '../thunks/githubThunk';
import { httpRequest } from '../thunks/httpThunk';
import type { ExtendedWorkspace } from '../thunks/types';
import { createWorkspace, deleteWorkspace, getWorkspaces, upsertProtos } from '../thunks/workspacesThunk';
import { initialHttpSendParams } from './httpSlice.init';
import {
  AddHttpHeaderPayload,
  CreateHttpMessageInstancePayload,
  DecodingSymbol,
  HttpHeaderPayload,
  HttpItemType,
  HttpMethodPayload,
  HttpParamsType,
  HttpState,
  HttpTimeoutPayload,
  HttpUrlPayload,
  initialHttpItem,
  RemoveHttpHeaderPayload,
  RenameHttpMessageInstancePayload,
  SetHttpDecodingPayload,
} from './httpSlice.types';
import type { CreateWorkspacesResponse } from './workspacesSlice.types';

const initialState: HttpState = {};

const hydratedHttpWithWorkspaces = (state: WritableDraft<HttpState>, workspaces: ExtendedWorkspace[]) => {
  workspaces.forEach((w) => {
    const { workspace_id, user_data } = w;

    if (w.hydrated) {
      if (user_data.http) {
        const hydratedUserHttp: HttpItemType = Object.fromEntries(
          Object.entries(user_data.http).map(([packageName, packageHttp]) => [
            packageName,
            Object.fromEntries(
              Object.entries(packageHttp).map(([messageName, messageInstances]) => [
                messageName,
                {
                  instances: messageInstances.instances.map((instance) => ({
                    ...instance,
                    loading: false,
                  })),
                },
              ]),
            ),
          ]),
        );

        _.merge(w.messagesAndRpc.http, hydratedUserHttp);

        w.messagesAndRpc.http[DecodingSymbol] = w.mainObjects.decodingObj;
        state[workspace_id] = w.messagesAndRpc.http;
      } else {
        state[workspace_id] = w.messagesAndRpc.http;
      }
    } else {
      state[workspace_id] = _.cloneDeep(initialHttpItem);
    }
  });
};

const createInstance = (state: WritableDraft<HttpState>, createInstanceParams: CreateHttpMessageInstancePayload) => {
  const { wsId, packageName, messageName, instanceName } = createInstanceParams;
  const messageInstances = state[wsId]?.[packageName]?.[messageName]?.instances;
  if (messageInstances) {
    const newInstance = _.cloneDeep(initialHttpSendParams);
    newInstance.instanceMetadata.name = instanceName;

    messageInstances.push(newInstance);
  }
};

const renameTo = (instance: WritableDraft<HttpParamsType>, newInstanceName: string) => {
  instance.instanceMetadata.name = newInstanceName;
  return instance;
};

const renameInstance = (state: WritableDraft<HttpState>, createInstanceParams: RenameHttpMessageInstancePayload) => {
  const { wsId, packageName, messageName, instanceName, newInstanceName } = createInstanceParams;
  const messageInstances = state[wsId]?.[packageName]?.[messageName]?.instances;
  if (messageInstances) {
    state[wsId][packageName][messageName].instances = messageInstances.map((instance) =>
      instance.instanceMetadata.name === instanceName ? renameTo(instance, newInstanceName) : instance,
    );
  }
};

const deleteInstance = (state: WritableDraft<HttpState>, createInstanceParams: CreateHttpMessageInstancePayload) => {
  const { wsId, packageName, messageName, instanceName } = createInstanceParams;
  const messageInstances = state[wsId]?.[packageName]?.[messageName]?.instances;
  if (messageInstances) {
    pipe(
      messageInstances,
      a.findIndex((instance) => instance.instanceMetadata.name === instanceName),
      o.map(a.deleteAt),
      o.chain((delFrom) => delFrom(messageInstances)),
      o.map((newInstances) => (state[wsId][packageName][messageName].instances = newInstances)),
    );
  }
};

const httpSlice = createSlice({
  name: 'http',
  initialState,
  reducers: {
    setHttp: (_state, { payload }: PayloadAction<HttpState>) => payload,
    setHttpMethod: (state, { payload }: PayloadAction<HttpMethodPayload>) => {
      const { wsId, path, value } = payload;
      const instance = getHttpInstance(state, wsId, path);
      if (instance) instance.method = value;
    },
    setHttpUrl: (state, { payload }: PayloadAction<HttpUrlPayload>) => {
      const { wsId, path, value } = payload;
      const instance = getHttpInstance(state, wsId, path);
      if (instance) instance.url = value;
    },
    addHttpHeader: (state, { payload }: PayloadAction<AddHttpHeaderPayload>) => {
      const { wsId, path } = payload;
      const instance = getHttpInstance(state, wsId, path);
      if (instance) instance.headers.push({ checked: true, key: '', value: '' });
    },
    removeHttpHeader: (state, { payload }: PayloadAction<RemoveHttpHeaderPayload>) => {
      const { wsId, path, index } = payload;
      const instance = getHttpInstance(state, wsId, path);
      if (instance) instance.headers.splice(index, 1);
    },
    setHttpHeaderChecked: (state, { payload }: PayloadAction<RemoveHttpHeaderPayload>) => {
      const { wsId, path, index } = payload;
      const instance = getHttpInstance(state, wsId, path);
      if (instance) {
        const checked = instance.headers[index];
        checked.checked = !checked.checked;
      }
    },
    setHttpHeaderKey: (state, { payload }: PayloadAction<HttpHeaderPayload>) => {
      const { wsId, path, index, value } = payload;
      const instance = getHttpInstance(state, wsId, path);
      if (instance) instance.headers[index].key = value;
    },
    setHttpHeaderValue: (state, { payload }: PayloadAction<HttpHeaderPayload>) => {
      const { wsId, path, index, value } = payload;
      const instance = getHttpInstance(state, wsId, path);
      if (instance) instance.headers[index].value = value;
    },
    setHttpTimeout: (state, { payload }: PayloadAction<HttpTimeoutPayload>) => {
      const { wsId, path, value } = payload;
      const instance = getHttpInstance(state, wsId, path);
      if (instance) instance.timeout = value;
    },
    setHttpDecoding: (state, { payload }: PayloadAction<SetHttpDecodingPayload>) => {
      const { wsId, path, key, value } = payload;
      const instance = getHttpInstance(state, wsId, path);
      if (instance) instance.decodeObj[key] = value;
    },
    createHttpMessageInstance: (state, { payload }: PayloadAction<CreateHttpMessageInstancePayload>) => {
      createInstance(state, payload);
    },
    renameHttpMessageInstance: (state, { payload }: PayloadAction<RenameHttpMessageInstancePayload>) => {
      renameInstance(state, payload);
    },
    deleteHttpMessageInstance: (state, { payload }: PayloadAction<CreateHttpMessageInstancePayload>) => {
      deleteInstance(state, payload);
    },
    clearHttp: () => initialState,
  },
  extraReducers: (builder) => {
    // upsertProtos ********************************************************************************
    builder.addCase(upsertProtos.fulfilled, (state, { payload }) => {
      const { workspace_id } = payload;
      state[workspace_id] = payload.hydrated ? payload.messagesAndRpc.http : _.cloneDeep(initialHttpItem);
    });
    // createWorkspace *****************************************************************************
    builder.addCase(createWorkspace.fulfilled, (state, { payload }: PayloadAction<CreateWorkspacesResponse>) => {
      state[payload.workspace_id] = {};
    });
    // getWorkspaces *******************************************************************************
    builder.addCase(getWorkspaces.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      hydratedHttpWithWorkspaces(state, workspaces);
    });
    builder.addCase(importGithubWorkspace.fulfilled, (state, { payload }) => {
      const { workspaces } = payload;
      hydratedHttpWithWorkspaces(state, workspaces);
    });
    // deleteWorkspace *****************************************************************************
    builder.addCase(deleteWorkspace.fulfilled, (state, { meta }) => {
      delete state[meta.arg];
    });
    // httpRequest *********************************************************************************
    builder.addCase(httpRequest.pending, (state, { meta }) => {
      const { wsId, httpMessage } = meta.arg;
      const instance = getHttpInstance(state, wsId, httpMessage);
      if (instance) instance.loading = true;
    });
    builder.addCase(httpRequest.fulfilled, (state, { meta }) => {
      const { wsId, httpMessage } = meta.arg;
      const instance = getHttpInstance(state, wsId, httpMessage);
      if (instance) instance.loading = false;
    });
    builder.addCase(httpRequest.rejected, (state, { meta }) => {
      const { wsId, httpMessage } = meta.arg;
      const instance = getHttpInstance(state, wsId, httpMessage);
      if (instance) instance.loading = false;
    });
  },
});

export const {
  setHttp,
  setHttpMethod,
  setHttpUrl,
  addHttpHeader,
  removeHttpHeader,
  setHttpHeaderChecked,
  setHttpHeaderKey,
  setHttpHeaderValue,
  setHttpTimeout,
  setHttpDecoding,
  createHttpMessageInstance,
  renameHttpMessageInstance,
  deleteHttpMessageInstance,
  clearHttp,
} = httpSlice.actions;

export default httpSlice.reducer;
